import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import Button from '../../../../../shared/components/Button';
import { useFetch, useModal } from '../../../../../shared/helpers/hooks';
import BusinessProfileModal from '../BusinessProfileModal';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import AdminLoader from '../../../components/AdminLoader';
import Placeholder from '../../../../../shared/assets/adminNoImage.svg';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { parseProfileStatus } from '../../../../SEO/components/DashboardLayout/utils';

const UserBusinessCard = ({ businessProfile, userId }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const [showModal, setShowModal, modalHandler] = useModal();
  const { showNotification } = useNotification();
  const [businessProfileImage, setBusinessProfileImage] = useState(null);
  const [businessImageLoading, setBusinessImageLoading] = useState(false);
  const [businessProfileData, setBusinessProfileData] = useState(null);
  const { id, name, address, photos, cardTotalNumber, subscription } = businessProfile;
  const statusInLocalStorage = localStorage.getItem(`status-${id}`);

  const {
    responseData,
    isLoading,
    executeRequest: fetchBusinessProfile,
  } = useFetch({
    url: '/acp/client/getBusinessProfile',
    payload: { userId, businessProfileId: id },
    config: { fetchOnMount: false },
  });

  const {
    responseData: mediaData,
    isLoading: isLoadingMedia,
    executeRequest: fetchBusinessMedia,
  } = useFetch({
    url: '/acp/client/mediaList',
    payload: { userId, businessProfileId: id, limit: 19 },
    config: { fetchOnMount: false },
  });

  const {
    executeRequest,
    isLoading: isLoadingVerificationStatus,
    responseData: verificationData,
  } = useFetch({
    url: '/acp/client/getVerificationStatus',
    payload: { userId, businessProfileId: id },
    config: { fetchOnMount: false },
  });

  const onShowCardHandler = async () => {
    if (!statusInLocalStorage) {
      await executeRequest();
    }
    await fetchBusinessProfile();
    await fetchBusinessMedia();
    modalHandler();
  };

  useEffect(() => {
    if (verificationData) {
      const parsedStatus = parseProfileStatus(verificationData, t);
      localStorage.setItem(`status-${id}`, parsedStatus.id);
    }
  }, [verificationData, t, id]);

  useEffect(() => {
    if (responseData && mediaData) {
      setBusinessProfileData({ ...responseData, media: mediaData });
    }
  }, [responseData, mediaData, isLoading, isLoadingMedia, userId]);

  useEffect(() => {
    const controller = new AbortController();
    const busienssPhotoExist = photos && photos.length;

    const fetchImage = async () => {
      setBusinessImageLoading(true);
      try {
        const { file, date } = photos[0];
        const response = await api.get(`/assets/place/${date}/${file}`, {
          signal: controller.signal,
          responseType: 'arraybuffer',
          responseEncoding: 'binary',
        });
        setBusinessProfileImage(window.URL.createObjectURL(new Blob([response.data], { type: 'image/jpg' })));
      } catch (err) {
        setBusinessProfileImage(Placeholder);
        handleApiError({ err, t, showNotification });
      } finally {
        setBusinessImageLoading(false);
      }
    };

    if (busienssPhotoExist) {
      fetchImage();
    } else {
      setBusinessProfileImage(Placeholder);
    }

    return () => {
      controller.abort();
    };
  }, [photos]);

  if (isLoading || isLoadingMedia || isLoadingVerificationStatus) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} />;
  }
  return (
    <div className={classes.wrapper}>
      {showModal && businessProfileData && (
        <BusinessProfileModal
          onClose={modalHandler}
          address={address}
          businessProfile={businessProfileData}
          profileImage={businessProfileImage}
          userId={userId}
          businessProfileId={id}
        />
      )}
      <div className={classes.businessCard}>
        {businessImageLoading ? (
          <AdminLoader />
        ) : (
          <img className={classes.businessImage} src={businessProfileImage} alt="User Business Card" />
        )}
        <div className={classes.businessCardText}>
          <div className={classes.businessCardName}>{name}</div>
          <div className={classes.businessCardAddress}>{address}</div>
        </div>
      </div>
      <div className={classes.rightSide}>
        <Pill label={t(`adminControlPanel.subscription.${subscription}Plan`)} className={classes.subPlanPill} />
        <Pill label={t('adminControlPanel.cardsSeo', { value: cardTotalNumber })} className={classes.seoCardPill} />
        <Button
          label={t('adminControlPanel.displayBusinessCard')}
          className={classes.showBusinessCardBtn}
          onClick={onShowCardHandler}
        />
      </div>
    </div>
  );
};

export default UserBusinessCard;

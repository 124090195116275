import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as SearchIcon } from '../../assets/magnifier.svg';

const SearchInput = ({
  label,
  list = [],
  onSelect,
  showOnList = (value) => value.name,
  className,
  showList,
  fetchingList,
  onSearch = () => {},
  value: searchInputValue = '',
}) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(searchInputValue);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const isListVisible = Boolean(searchInput.length && showList);
  const filteredList = list.filter((el) => el.name.toLowerCase().includes(searchInput.toLowerCase()));

  const searchInputChangeHandler = (event) => {
    setSearchInput(event.target.value);
    onSearch(event.target.value);
  };

  const selectValueHandler = (value) => {
    onSelect(value);
    setSearchInput('');
  };

  useEffect(() => {
    if (searchInput.length) {
      inputRef.current.focus();
    }
  }, [searchInput]);

  return (
    <div ref={wrapperRef} className={clsx(classes.searchWrapper, className)}>
      <SearchIcon className={classes.searchIcon} />
      <input
        ref={inputRef}
        onChange={searchInputChangeHandler}
        placeholder={label}
        value={searchInput}
        className={classes.input}
      />
      {isListVisible && (
        <div className={classes.listWrapper}>
          {filteredList.map((el) => (
            <div onClick={() => selectValueHandler(el)} className={classes.listItem} key={el.id}>
              {showOnList(el)}
            </div>
          ))}
          {!filteredList.length && !fetchingList && <div className={classes.listItem}>{t('dashboard.noResults')}</div>}
        </div>
      )}
    </div>
  );
};
export default SearchInput;

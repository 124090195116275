import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Textarea from '../../../../../shared/components/Textarea';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useDashboard } from '../../../helpers/dashboard';
import Button from '../../../../../shared/components/Button';
import AIModels from '../../../../../shared/components/AIModels';
import AiHelperKeywordsBox from '../AiHelperKeywordsBox';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { getLocalStorageProfileStatus } from '../../../../../shared/helpers/helpers';
import { AI_MODEL_ENUM, PROFILE_STATUS_ENUM } from '../../../helpers/enums';
import { aiModelConfig, generatePayload } from './utils';
import { ReactComponent as AIHelperIcon } from '../../../../../shared/assets/aiReply.svg';
import { ReactComponent as AIEngine } from '../../../../../shared/assets/cpu.svg';
import { parseProfileStatus } from '../../../components/DashboardLayout/utils';
import { useIsMobile } from '../../../../../shared/helpers/hooks';
import { MAX_MOBILE_WIDTH } from '../../../helpers/constants';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const { gpt3 } = AI_MODEL_ENUM;

const AIHelper = ({ onClick, isOpen, onApprove, formType }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { businessProfile } = useDashboard();
  const { id } = businessProfile;
  const [isLoading, setIsLoading] = useState(false);
  const [businessKeywords, setBusinessKeywords] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [userTypedKeyword, setUserTypedKeyword] = useState('');
  const [userContent, setUserContent] = useState('');
  const [aiEngineChange, setAiEngineChange] = useState(false);
  const [selectedModel, setSelectedModel] = useState(gpt3);

  const profileStatusInStorage = getLocalStorageProfileStatus(id);
  const keywordsInStorage = localStorage.getItem(`keywords-${id}`) || [];
  const isMobileScreen = useIsMobile(MAX_MOBILE_WIDTH);
  const aiHelperLabel = isMobileScreen ? t('posts.ai') : t('posts.needHelpAi');
  const { verified } = PROFILE_STATUS_ENUM;
  const onAiEngineChange = () => {
    setAiEngineChange((prev) => !prev);
  };

  const selectModelHandler = (model) => {
    setSelectedModel(model);
    onAiEngineChange();
  };

  const onKeywordClick = (keyword) => {
    if (selectedKeywords.includes(keyword)) {
      setSelectedKeywords(selectedKeywords.filter((el) => el !== keyword));
    } else {
      setSelectedKeywords([...selectedKeywords, keyword]);
    }
  };

  const moreAiDetailsHandler = (value) => {
    setUserContent(value);
  };

  const onUserKeywordAddHandler = () => {
    const isKeywordAlreadyAdded = selectedKeywords.includes(userTypedKeyword);
    const shouldAddKeyword = userTypedKeyword.trim().length && !isKeywordAlreadyAdded;
    if (shouldAddKeyword) {
      setBusinessKeywords((prev) => [...prev, { searchKeyword: userTypedKeyword }]);
      setSelectedKeywords([...selectedKeywords, userTypedKeyword]);
      setUserTypedKeyword('');
    }
  };

  const onUserKeywordChange = (v) => {
    setUserTypedKeyword(v);
  };

  const goBackWithReset = () => {
    setSelectedKeywords([]);
    setUserTypedKeyword('');
    setAiEngineChange(false);
    setUserContent('');
    onClick();
  };

  const generatePostContent = async () => {
    setIsLoading(true);
    const { apiPath, apiPayload } = generatePayload(id, selectedKeywords, selectedModel, userContent, formType);
    try {
      const {
        data: { content },
      } = await api.post(apiPath, apiPayload);
      onClick();
      onApprove(content);
      setSelectedKeywords([]);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProfileStatus = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post('/business_profile/getVerificationStatus', {
        businessProfileId: businessProfile.id,
      });
      const parsedStatus = parseProfileStatus(data, t);
      localStorage.setItem(`status-${businessProfile.id}`, parsedStatus.id);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (keywordsInStorage.length) {
      const parsedKeywords = JSON.parse(keywordsInStorage)?.map((el) => ({ searchKeyword: el })) || [];
      setBusinessKeywords(parsedKeywords);
    }
  }, [keywordsInStorage]);

  useEffect(() => {
    const isProfileStatusAbsentAndProfileIdExists = !profileStatusInStorage && businessProfile.id;
    const isProfileIdExistsAndVerifiedWithNoKeywords = businessProfile.id && profileStatusInStorage === verified && !keywordsInStorage.length;
    const controller = new AbortController();

    const getBusinessKeywords = async () => {
      try {
        const {
          data: { keywords },
        } = await api.post(
          '/performance/getKeywords',
          { businessProfileId: businessProfile.id },
          { signal: controller.signal },
        );
        if (keywords.length) {
          setBusinessKeywords(keywords);
          localStorage.setItem(
            `keywords-${businessProfile.id}`,
            JSON.stringify(keywords.map((el) => el.searchKeyword)),
          );
        }
      } catch (err) {
        handleApiError({ err, t, showNotification });
      }
    };

    if (isProfileStatusAbsentAndProfileIdExists) {
      fetchProfileStatus();
    }
    if (isProfileIdExistsAndVerifiedWithNoKeywords) {
      getBusinessKeywords();
    }

    return () => controller.abort();
  }, [businessProfile.id, profileStatusInStorage]);

  return (
    <div>
      {!isOpen ? (
        <div onClick={onClick} className={classes.body}>
          <div>{aiHelperLabel}</div>
          <AIHelperIcon />
        </div>
      ) : (
        <div className={classes.aiContentWrapper}>
          {isLoading && <LoaderOverlay customStyle={classes.loaderWrapper} />}
          <div className={classes.aiModelSelectWrapper}>
            {aiEngineChange ? (
              <AIModels
                selectModelHandler={selectModelHandler}
                selectedModel={selectedModel}
                className={classes.aiModelsCustomWrapper}
              />
            ) : (
              <div onClick={onAiEngineChange} className={classes.modelWrapper}>
                <AIEngine className={classes.aiEngineIcon} />
                {aiModelConfig[selectedModel]}
              </div>
            )}
          </div>
          <div className={classes.aiContentTip}>{t('posts.aiHelper')}</div>
          <Textarea
            onChange={moreAiDetailsHandler}
            className={classes.aiTextAreaHelper}
            label={t('posts.addMoreAIDetails')}
          />
          <AiHelperKeywordsBox
            businessKeywords={businessKeywords}
            onKeywordClick={onKeywordClick}
            onUserKeywordAddHandler={onUserKeywordAddHandler}
            onUserKeywordChange={onUserKeywordChange}
            selectedKeywords={selectedKeywords}
            userTypedKeyword={userTypedKeyword}
            profileStatus={profileStatusInStorage}
          />

          <div className={classes.actionsWrapper}>
            <Button className={classes.backBtn} onClick={goBackWithReset} label={t('global.back')} />
            <Button className={classes.btn} onClick={generatePostContent} label={t('posts.generateContent')} />
          </div>
        </div>
      )}
    </div>
  );
};
export default AIHelper;

import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import CircleIconText from '../../../../../shared/components/CircleIconText';
import Button from '../../../../../shared/components/Button';
import { useDashboard } from '../../../helpers/dashboard';
import { ReactComponent as Magnifier } from '../../../../../shared/assets/magnifier.svg';
import { ReactComponent as Alert } from '../../../../../shared/assets/alertCircle.svg';

const SubscriptionsEmptyState = ({ onPurchaseClick }) => {
  const { t } = useTranslation();
  const {
    businessProfile: { activePayment },
  } = useDashboard();

  const emptyStateTitle = activePayment ? t('subscription.activePaymentTitle') : t('subscription.subEmptyState');
  const supportingText = activePayment
    ? t('subscription.activePaymentSubTitle')
    : t('subscription.subEmptyStateSubInfo');

  const Icon = activePayment ? Alert : Magnifier;

  return (
    <div className={classes.content}>
      <CircleIconText
        headingStyle={classes.headingStyle}
        heading={emptyStateTitle}
        supportingText={supportingText}
        supportingTextStyle={classes.supportingTextStyle}
        icon={Icon}
      />
      {!activePayment && (
        <Button
          className={classes.button}
          onClick={onPurchaseClick}
          label={t('subscription.buySubscription')}
          readOnly={activePayment}
        />
      )}
    </div>
  );
};

export default SubscriptionsEmptyState;

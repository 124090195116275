import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import RelatedSubscriptions from '../../../../SEO/modules/Settings/RelatedSubscriptions';
import Button from '../../../../../shared/components/Button';
import Pill from '../../../../../shared/components/Pill';

const PaymentDataItem = ({ data }) => {
  const { t } = useTranslation();
  const {
    name,
    lastName,
    email,
    secondEmail,
    country,
    address,
    city,
    postalCode,
    defaultInvoice,
    nip,
    companyName,
    phone = '',
    id,
    relatedSubscriptions,
  } = data;
  const isPersonal = !nip && !companyName;
  const isRelatedDataWithReccurringSubscription = Boolean(relatedSubscriptions?.length);

  return (
    <div className={clsx(classes.row, defaultInvoice && classes.defaultInvoice)}>
      <div>
        <div className={classes.invoiceBasicData}>
          {isPersonal ? (
            <div className={classes.invoiceTitle}>{`${name} ${lastName}`}</div>
          ) : (
            <div className={classes.invoiceTitle}>
              <h2>{companyName}</h2>
              <p>{`NIP ${nip}`}</p>
            </div>
          )}
          {defaultInvoice && <Pill className={classes.deafultAdressPill} label={t('invoices.defaultAddress')} />}
        </div>

        <div className={classes.address}>
          <div>{country}</div>
          <div>{`${postalCode} ${city}`}</div>
          <div>{address}</div>
        </div>
        <div className={classes.contactData}>
          <div>{email}</div>
          <div>{secondEmail}</div>
          <div>{phone}</div>
        </div>
        {isRelatedDataWithReccurringSubscription && <RelatedSubscriptions data={relatedSubscriptions} admin />}
      </div>
      <div className={classes.actions}>
        <Button className={classes.editBtn} label={t('global.edit')} readOnly />
        <Button className={classes.deleteBtn} label={t('global.delete')} readOnly />
      </div>
    </div>
  );
};

export default PaymentDataItem;

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { useApi } from '../../../../../shared/helpers/api';
import { useDashboard } from '../../../helpers/dashboard';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import PurchaseFormBusinessProfileDetails from '../PurchaseFormBusinessProfileDetials';
import Textarea from '../../../../../shared/components/Textarea';

const CancelSubscriptionModalConfirmation = ({ onClose, title, expireDate }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const {
    businessProfile: { id },
  } = useDashboard();

  const [reason, setReason] = useState('');

  const handleChangeReason = (value) => {
    setReason(value);
  };

  const cancelSubscription = async () => {
    try {
      await api.post('/subscription/cancelSubscription', { businessProfileId: id, reason });
      showNotification({ message: t('subscription.cancelSubscriptionSuccess'), type: 'success' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      onClose();
    }
  };

  return (
    <Modal contentStyle={classes.modalContent} onCancel={onClose} modalTitle={t(title)}>
      <div className={classes.profileContentWrapper}>
        <PurchaseFormBusinessProfileDetails />
      </div>
      <div className={classes.cancelSubscriptionTextWrappper}>
        <div className={classes.cancelSubscriptionTitle}>{t('subscription.cancelSubscriptionTitle')}</div>
        <div className={classes.cancelSubscriptionSubtitle}>{t('subscription.cancelSubscriptionSubtitle')}</div>
        <div className={classes.cancelSubscriptionSubtitle}>
          <Trans
            i18nKey="subscription.cancelSubscriptionSinceDate"
            values={{ date: expireDate }}
            components={{ bold: <b className={classes.boldDate} /> }}
          />
        </div>
      </div>
      <Textarea
        label={t('subscription.provideCancelReason')}
        onChange={handleChangeReason}
        maxLength={500}
        value={reason}
        className={classes.textArea}
      />
      <div className={classes.actionWrapper}>
        <Button label={t('global.close')} onClick={onClose} className={classes.cancelButton} />
        <Button label={t('global.confirm')} onClick={cancelSubscription} className={classes.deleteButton} />
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModalConfirmation;

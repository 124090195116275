import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useAuth } from '../../../../shared/helpers/auth';
import classes from './styles.module.css';
import Checkbox from '../../../../shared/components/Checkbox';
import PillWithIcon from '../../../../shared/components/Pill/PillAlternative';
import { iconTypeEnum, NOTIFICATION_TYPE_ENUM, typeEnum } from '../../views/Notifications/utils';
import { ReactComponent as SeoLogo } from '../../../../shared/assets/logoIcon.svg';

const { opinieo, system } = NOTIFICATION_TYPE_ENUM;

const NotificationFeedItem = ({ notification, selectedItems, onItemSelect }) => {
  const { t } = useTranslation();
  const { businessProfiles } = useAuth();
  const isSelected = selectedItems.includes(notification.id);
  const isOpinieoCardNotReleatedWithBuisnessProfile = notification.type === opinieo && !notification.businessProfileId;

  const foundBusiness = businessProfiles.find((el) => el.id === notification.businessProfileId);

  return (
    <div key={notification.id} className={classes.notification}>
      <div className={classes.notificationContent}>
        <div className={classes.row}>
          {notification.type === system && (
            <div className={classes.logoWrapper}>
              <SeoLogo className={classes.seoLogoIcon} />
            </div>
          )}
          {Boolean(foundBusiness?.image) && (
            <img className={classes.image} src={foundBusiness.image} alt="business profile logo" />
          )}
          <p className={clsx(classes.bpName, !foundBusiness?.image && classes.bpNameWithNoImage)}>
            {!isOpinieoCardNotReleatedWithBuisnessProfile ? (
              <span>{notification.connectedBusinessProfile.name}</span>
            ) : (
              ''
            )}
            <span className={classes.bpAddress}>{notification.connectedBusinessProfile.address}</span>
          </p>
          <PillWithIcon
            className={clsx(classes.pill, classes[notification.type.toLowerCase()])}
            label={t(typeEnum[notification.type])}
          >
            {t(typeEnum[notification.type])}
            {iconTypeEnum[notification.type]()}
          </PillWithIcon>
        </div>
        <div className={classes.notificationTextWrapper}>
          <Checkbox
            value={isSelected}
            onClick={() => onItemSelect(notification.id)}
            checkboxStyle={classes.checkboxStyle}
            checkIconStyle={classes.checkIconStyle}
          />
          <p className={classes.notificationText}>
            {t(notification.content)}
            {' '}
            <p className={classes.notificationDate}>{notification.date}</p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotificationFeedItem;

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { useCloseOnOutsideClick } from '../../../../../shared/helpers/hooks';
import { getParsedDateString } from './utils';
import { ReactComponent as Close } from '../../../../../shared/assets/close.svg';
import DateInput from '../../../../../shared/components/DateInput';

const initFiliters = { seoStatus: null, cardStatus: null, dateFrom: null, dateTo: null };
const CardListFilterModal = ({ onClose, onApplyFilters, listFilters }) => {
  const { t } = useTranslation();
  const filterModalRef = useRef(null);
  const [filters, setFilters] = useState(initFiliters);

  const onOutSideClick = useCloseOnOutsideClick(filterModalRef, onClose);

  const dateInputValue = getParsedDateString(filters);

  const onDateChange = (date) => {
    const { dateFrom, dateTo } = date;
    if (!dateFrom && !dateTo) {
      setFilters((prevFilters) => ({ ...prevFilters, dateFrom: null, dateTo: null }));
      return;
    }
    setFilters((prevFilters) => ({ ...prevFilters, dateFrom, dateTo }));
  };

  const onFilterClickHandler = (name) => (value) => {
    if (value === filters[name]) {
      setFilters((prevFilters) => ({ ...prevFilters, [name]: null }));
      return;
    }
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const clearFiltersHandler = () => {
    setFilters({ seoStatus: null, cardStatus: null, dateFrom: null, dateTo: null });
    onApplyFilters({ seoStatus: null, cardStatus: null, dateFrom: null, dateTo: null });
  };

  useEffect(() => {
    setFilters(() => ({ ...initFiliters, ...listFilters }));
    document.addEventListener('mousedown', onOutSideClick);
    return () => {
      document.removeEventListener('mousedown', onOutSideClick);
    };
  }, []);

  return (
    <div className={classes.modalWrapper} ref={filterModalRef}>
      <div className={classes.heading}>
        {t('adminControlPanel.filters')}
        <Close className={classes.closeIcon} onClick={onClose} />
      </div>
      <div className={classes.body}>
        <div className={classes.filterField}>
          <div className={classes.label}>{t('adminControlPanel.clientSEOSignUp')}</div>
          <div className={classes.optionList}>
            <Button
              label={t('adminControlPanel.signedUp')}
              onClick={() => onFilterClickHandler('seoStatus')(true)}
              className={clsx({ [classes.active]: filters.seoStatus })}
            />
            <Button
              label={t('adminControlPanel.notSignedUp')}
              onClick={() => onFilterClickHandler('seoStatus')(false)}
              className={clsx({ [classes.active]: !filters.seoStatus && filters.seoStatus !== null })}
            />
          </div>
        </div>
        <div className={classes.filterField}>
          <div className={classes.label}>{t('adminControlPanel.cardStatus')}</div>
          <div className={classes.optionList}>
            <Button
              label={t('adminControlPanel.cardActive')}
              onClick={() => onFilterClickHandler('cardStatus')(true)}
              className={clsx({ [classes.active]: filters.cardStatus })}
            />
            <Button
              label={t('adminControlPanel.cardNotActive')}
              onClick={() => onFilterClickHandler('cardStatus')(false)}
              className={clsx({ [classes.active]: !filters.cardStatus && filters.cardStatus !== null })}
            />
          </div>
        </div>
        <div className={classes.filterField}>
          <div className={classes.label}>{t('adminControlPanel.date')}</div>
          <DateInput
            admin
            twoMonths
            value={dateInputValue}
            label={t('adminControlPanel.selectDate')}
            placeholder={t('adminControlPanel.selectDate')}
            onChange={onDateChange}
            selectPeriodAllowed
            dateFrom={filters.dateFrom}
            dateTo={filters.dateTo}
          />
        </div>
        <div className={classes.actionsWrapper}>
          <Button label={t('global.clear')} className={classes.clearFiltersBtn} onClick={clearFiltersHandler} />
          <Button
            label={t('adminControlPanel.applyFilters')}
            className={classes.applyFiltersBtn}
            onClick={() => onApplyFilters(filters)}
          />
        </div>
      </div>
    </div>
  );
};

export default CardListFilterModal;

export const disableButton = ({
  userPhoneNumber,
  passwordFormData,
  isPhoneNumberValid,
  passwordFormDataIsValid,
  phone,
}) => {
  const noFormDataProvided = !userPhoneNumber && !Object.values(passwordFormData).every((el) => el);
  const noPasswordDataProvided = !Object.values(passwordFormData).every((el) => el);
  const passwordProvidedNoValid = Object.values(passwordFormData).some((el) => el) && !passwordFormDataIsValid;
  const phoneProvidedNoValid = userPhoneNumber && !isPhoneNumberValid;
  const phoneAreSame = phone === userPhoneNumber;

  return (
    noFormDataProvided
    || phoneProvidedNoValid
    || (passwordProvidedNoValid && phoneAreSame)
    || (phoneAreSame && noPasswordDataProvided)
    || (!phoneAreSame && passwordProvidedNoValid)
  );
};

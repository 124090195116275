export const parseStringsWithWhiteSpaces = (string) => {
  if (string) {
    const cleanedString = string.replace(/\s\s+/g, ' ');
    const trimmedText = cleanedString.replace(/^\s+/, '');
    return trimmedText;
  }
  return '';
};
export const getDateFromObject = (date) => Object.values(date || {}).join('-');
export const getTimeFromObject = (time) => Object.values(time || {}).join(':');

export const toCapitalize = (string) => {
  if (!string) return '';
  return string[0].toUpperCase() + string.slice(1);
};

export const trimSpaceFromObject = (object) => Object.entries(object).reduce((acc, [key, value]) => {
  acc[key] = value.trim();
  return acc;
}, {});

export const removeWhitespaceFromString = (string) => (string ? string.replace(/\s/g, '') : '');

import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';
import Checkbox from '../../../../../shared/components/Checkbox';
import Select from '../../../../../shared/components/Select';
import Button from '../../../../../shared/components/Button';
import { useAuth } from '../../../../../shared/helpers/auth';
import { statusNotificationFilterConifg, typeNotificationFilterConifg } from './utils';
import { FILTERS_ENUM } from '../../../views/Notifications/utils';

const { type, status } = FILTERS_ENUM;

const NotificationFilters = ({ onClose, onFiltersChange, filters, onSubmit }) => {
  const { t } = useTranslation();
  const { businessProfiles } = useAuth();

  const { title, options } = typeNotificationFilterConifg(t);
  const { title: statusTitle, options: statusOptions } = statusNotificationFilterConifg(t);

  const isSelectedFilter = (key) => (value) => filters[key]?.includes(value);

  const handleResetFiltersBtn = async () => {
    onFiltersChange('reset')();
    onClose();
  };

  const submitHandler = async () => {
    await onSubmit();
    onClose();
  };

  const renderOption = (optionValue, label, value) => (
    <PillWithIcon
      key={label}
      className={clsx(classes.option, isSelectedFilter(optionValue)(value) && classes.selectedOption)}
      onClick={() => onFiltersChange(optionValue)(value)}
    >
      <Checkbox
        labelPosition="right"
        label={label}
        value={isSelectedFilter(optionValue)(value)}
        checkboxStyle={classes.checkboxStyle}
      />
    </PillWithIcon>
  );

  return (
    <Modal
      onCancel={onClose}
      modalTitle={t('notifications.filters.title')}
      contentStyle={classes.filtersWrapper}
      backdropLocked
    >
      <div className={classes.filter}>
        <div className={classes.filterTitle}>{title}</div>
        <div className={classes.filterOptions}>
          {options.map(({ label, value }) => renderOption(type, label, value))}
        </div>
      </div>
      <div className={classes.filter}>
        <div className={classes.filterTitle}>{statusTitle}</div>
        <div className={classes.filterOptions}>
          {statusOptions.map(({ label, value }) => renderOption(status, label, value))}
        </div>
      </div>
      <Select
        className={classes.selectProfile}
        list={businessProfiles}
        label={t('notifications.filters.selectBusinessCard')}
        listCustomClass={classes.list}
        value={filters.businessProfileId}
        onSelect={(value) => onFiltersChange('businessProfileId')(value)}
      />
      <div className={classes.actions}>
        <Button
          className={classes.resetActionBtn}
          label={t('notifications.filters.reset')}
          onClick={handleResetFiltersBtn}
        />
        <Button className={classes.actionBtn} label={t('notifications.filters.filter')} onClick={submitHandler} />
      </div>
    </Modal>
  );
};

export default NotificationFilters;

import React from 'react';
import { ROLE_ENUM } from '../AdministratorDetails/utils';
import { ReactComponent as CheckIcon } from '../../../../shared/assets/check.svg';
import { ReactComponent as MinusIcon } from '../../../../shared/assets/minus.svg';
import classes from './styles.module.css';
import { SORT_ADMIN_COLUMNS, TABLE_HEADERS_ENUM } from '../../helpers/constants';
import { formatDate } from '../../../../shared/helpers/helpers';

export const USER_LIST_NAVIGATION_KEY_ENUM = {
  managers: 'managers',
  clients: 'clients',
};

const { lastName, lastLogin, name, nip, phoneNumber, seo, login, subscriptionDate, firstName } = TABLE_HEADERS_ENUM;
const { lastLogin: sortLastLogin, lastName: sortLastName, login: sortLogin } = SORT_ADMIN_COLUMNS;

export const USER_LIST_TABS = (t, role) => {
  if (role === ROLE_ENUM.superAdmin) {
    return [
      { label: t('adminControlPanel.clients'), key: USER_LIST_NAVIGATION_KEY_ENUM.clients },
      { label: t('adminControlPanel.managers'), key: USER_LIST_NAVIGATION_KEY_ENUM.managers },
    ];
  }
  return [{ label: t('adminControlPanel.clients'), key: USER_LIST_NAVIGATION_KEY_ENUM.clients }];
};

export const columnsConfig = (t) => [
  { header: t('adminControlPanel.email'), property: login, sort: sortLogin, width: '50%' },
  { header: t('adminControlPanel.lastLogin'), property: lastLogin, width: '50%', sort: sortLastLogin },
  { header: t('adminControlPanel.seo'), property: seo, width: '15%' },
];

export const columnsConfigManager = (t) => [
  { header: t('adminControlPanel.lastName'), property: lastName, sort: sortLastName, width: '20%' },
  { header: t('adminControlPanel.name'), property: firstName, width: '20%' },
  { header: t('adminControlPanel.email'), property: login, sort: sortLogin, width: '30%' },
  { header: t('adminControlPanel.lastLogin'), property: lastLogin, width: '30%', sort: sortLastLogin },
  { header: t('adminControlPanel.subscriptionExpiration'), property: subscriptionDate, width: '20%' },
];

export const userDataParser = (data, t) => data.map((item) => ({
  ...item,
  key: item.id,
  id: item.id,
  lastName: item.lastName || '-',
  name: item.name || '-',
  login: item.login,
  nip: item.nip ? item.nip : '-',
  phoneNumber: item.phoneNumber || '-',
  lastLogin:
      formatDate(item.lastLogin) === 'Invalid Date' ? t('adminControlPanel.noData') : formatDate(item.lastLogin),
  seo: item.seo ? <CheckIcon className={classes.seoStatusIcon} /> : <MinusIcon className={classes.seoStatusIcon} />,
  subscriptionStatus: item.subscriptionStatus || null,
  subscriptionDate: formatDate(item.subscriptionDate),
}));

export const getEmptyStateMessage = (t, filters, activeTab) => {
  if (filters.search) {
    return t('adminControlPanel.userNotFound');
  }
  return activeTab.key === USER_LIST_NAVIGATION_KEY_ENUM.clients
    ? t('adminControlPanel.noClients')
    : t('adminControlPanel.noManagers');
};

import clsx from 'clsx';
import React, { useEffect, useId, useState } from 'react';
import classes from './styles.module.css';

const Range = ({
  className,
  labelClassName,
  step = 1,
  min,
  max,
  value,
  onBlur = () => {},
  onChange = () => {},
  label,
  renderLabel,
}) => {
  const id = useId();
  const [innerState, setInnerState] = useState(null);

  useEffect(() => {
    setInnerState(value);
  }, [value]);

  const handleChange = (e) => {
    setInnerState(e.target.value);
    onChange(e.target.value);
  };

  const handleBlur = (e) => {
    onBlur(e.target.value);
  };
  const valuePosition = `${((innerState - min) / (max - min)) * 100}`;

  const valueStyle = {
    position: 'relative',
    left: `calc(${valuePosition > 75 ? 75 : valuePosition}%)`,
  };
  return (
    <div className={clsx(classes.wrapper, className)}>
      <label className={classes.inputWrapper} htmlFor={id}>
        <span>{label}</span>
        <input
          className={classes.input}
          id={id}
          type="range"
          step={step}
          min={min}
          max={max}
          value={innerState}
          onChange={handleChange}
          onBlur={handleBlur}
          onTouchEnd={handleBlur}
        />
        <div
          style={{ width: `calc(${((innerState - min) / (max - min)) * 100}% - 4px)` }}
          className={classes.progress}
        />
      </label>
      <div style={valueStyle} className={clsx(classes.value, labelClassName)}>
        {renderLabel ? renderLabel(innerState) : label}
      </div>
    </div>
  );
};

export default Range;

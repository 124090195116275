import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import AsyncSelect from '../../../../../shared/components/AsyncSelect';
import { debounce } from '../../../../../shared/helpers/debounce';
import Button from '../../../../../shared/components/Button';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import ManagerDetailsForm from '../ManagerDetailsForm';
import { removeNullValues } from '../../../../../shared/helpers/parsers';
import { removeWhitespaceFromString } from '../../../../../shared/helpers/strings';

const AddManagerModal = ({ onClose, onListFetch, email }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [emailValue, setEmailValue] = useState(email);
  const [formError, setFormError] = useState({});

  const [clientData, setClientData] = useState(null);
  const [form, setForm] = useState({
    firstName: clientData?.firstName || '',
    lastName: clientData?.lastName || '',
    phone: clientData?.phone || '',
  });
  const isReadOnlyButton = !clientData || Object.values(formError).some((error) => error) || clientData.activeSubscription;

  const saveUserAsManager = async () => {
    const parsedForm = removeNullValues({ email: clientData.login, manager: true });
    await api.post('/acp/client/updateUserManager', parsedForm);
    await onListFetch();
    onClose();
  };

  const onSubmit = async () => {
    setLoading(true);
    const updateUserDetailsData = removeNullValues({
      ...form,
      userId: clientData.userId,
      phone: removeWhitespaceFromString(form.phone),
    });
    try {
      if (Object.keys(updateUserDetailsData).length) {
        await api.post('/acp/client/updateClientDetails', updateUserDetailsData);
      }
      await saveUserAsManager();
      navigate('/admin/users');
      showNotification({ type: 'success', message: t('adminControlPanel.managerAdded') });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeFormInput = (name) => (value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const onSelectEmailHander = async (value) => {
    setLoading(true);
    try {
      const { data } = await api.post('/acp/client/getClientDetails', { email: value ?? emailValue });
      setClientData(data);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setEmailValue('');
  };

  const handleGetEmailList = useCallback(
    debounce(async (payload) => {
      if (!payload.value || payload.value.length <= 3) {
        resetForm();
        return [];
      }
      try {
        setLoading(true);
        const {
          data: { emails },
        } = await api.post('/acp/client/autocompleteNotManager', {
          email: payload.value,
        });
        return emails;
      } catch (err) {
        handleApiError({ err, showNotification, t });
      } finally {
        setLoading(false);
      }
      return [];
    }, 500),
    [],
  );
  const handleInputError = (name) => (error) => {
    setFormError((prev) => ({ ...prev, [name]: error }));
  };

  useEffect(() => {
    if (email) {
      setEmailValue(email);
      handleGetEmailList({ value: email });
    }
  }, [email]);

  useEffect(() => {
    if (clientData) {
      setForm({
        firstName: clientData.firstName || '',
        lastName: clientData.lastName || '',
        phone: clientData.phone || '',
      });
    }
  }, [clientData]);

  return (
    <Modal
      contentStyle={classes.modalContent}
      titleStyle={classes.titleStyle}
      onCancel={onClose}
      modalTitle={t('adminControlPanel.addManager')}
      customIconStyle={classes.customCloseStyle}
    >
      {loading && <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />}

      <div className={classes.label}>
        {t('adminControlPanel.email')}
        <AsyncSelect
          admin
          labelRised
          withChevron
          value={emailValue}
          onElementGet={(v) => onSelectEmailHander(v.value)}
          placeholder={t('adminControlPanel.searchEmail')}
          showOnList={(v) => v?.email || v}
          onListRead={handleGetEmailList}
          onSelect={onSelectEmailHander}
        />
      </div>
      {clientData && (
        <ManagerDetailsForm data={clientData} onError={handleInputError} onChange={handleChangeFormInput} form={form} />
      )}
      <Button
        className={classes.saveBtn}
        label={t('adminControlPanel.addManager')}
        onClick={onSubmit}
        readOnly={isReadOnlyButton}
      />
    </Modal>
  );
};

export default AddManagerModal;

import React from 'react';
import pl from 'dayjs/locale/pl';
import classes from './styles.module.css';
import { capitalizeFirstLetter } from '../Input/transformers';
import ArrowButton from '../ArrowButton';

const PeriodName = ({ periodName, period }) => {
  if (!periodName) return null;
  const isMonth = period === 'month';
  return isMonth ? (
    <div className={classes.monthName}>{capitalizeFirstLetter(periodName.locale(pl).format('MMMM'))}</div>
  ) : (
    <div className={classes.monthName}>{period}</div>
  );
};

const CalendarNavigation = ({ isSingleMonth, isFirstMonth, periodName, period, handleMonthChange }) => {
  const isLeftArrowVisible = (!isSingleMonth && isFirstMonth) || isSingleMonth;
  const isRightArrowVisible = (!isSingleMonth && !isFirstMonth) || isSingleMonth;

  return (
    <div className={classes.monthNameWrapper}>
      {isLeftArrowVisible ? <ArrowButton direction="left" onClick={handleMonthChange} /> : <div />}
      {periodName && <PeriodName periodName={periodName} period={period} />}
      {isRightArrowVisible ? <ArrowButton direction="right" onClick={handleMonthChange} /> : <div />}
    </div>
  );
};

export default CalendarNavigation;

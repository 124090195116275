import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getBusinessProfileBasicData } from '../../views/Notifications/utils';

dayjs.extend(relativeTime);
dayjs.locale('pl');

export const notificationDataFeedParser = ({ list, notifications }) => {
  const n = null;

  return notifications.map((notification) => ({
    ...notification,
    content: notification.content,
    date: dayjs(notification.date).fromNow(),
    status: notification.status,
    connectedBusinessProfile:
      getBusinessProfileBasicData(list, notification.businessProfileId, true) || 'nie znaleziono',
  }));
};

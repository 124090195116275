import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import DragAndDrop from '../../../../../shared/components/DragAndDrop';
import { useApi } from '../../../../../shared/helpers/api';
import {
  adjustAspectRatioTo16x9,
  checkAspectRatioForCoverImage,
  getUniqueArray,
  handleCoverImageSelection,
  MEDIA_ENUM,
  parseBackDate,
  updateMedia,
  updateMediaTypes,
} from './utils';
import Button from '../../../../../shared/components/Button';
import ProgressCircle from '../../../../../shared/components/ProgressCircle';
import { useNotification } from '../../../../../shared/helpers/notification';
import SubInformation from '../../../../../shared/components/SubInformation';
import PlannedPost from '../../AddPost/PlannedPost';
import { useDashboard } from '../../../helpers/dashboard';
import { getFileExtension } from '../../../../../shared/components/DragAndDrop/utils';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import AddMediaDetails from '../AddMediaDetails';
import { TODAY } from '../../../../../shared/helpers/const';

const AddMediaModal = ({ handleModal, busienssId, onSuccess, editItem, categoryMediaItemToChange }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { businessProfile } = useDashboard();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [publishDate, setPublishDate] = useState('');
  const [selectedMediaTypes, setSelectedMediaTypes] = useState({});
  const [media, setMedia] = useState([]);
  const [isReadOnlyBtn, setIsReadOnlyBtn] = useState(false);

  const isPlannedBeforeToday = dayjs(publishDate).isBefore(TODAY);

  const modalTitle = categoryMediaItemToChange
    ? t(`media.${categoryMediaItemToChange.toLowerCase()}Edit`)
    : t('businessProfileEdit.addMedia');

  const onPublicationDateChange = (fullDate) => {
    setPublishDate(fullDate);
  };

  const onSelectHandler = async (value, index) => {
    if (value === MEDIA_ENUM.COVER && !(await checkAspectRatioForCoverImage(media[index]))) {
      const adjustedMediaItem = await adjustAspectRatioTo16x9(media[index]);
      setMedia((prev) => prev.map((el, i) => (i === index ? { ...el, ...adjustedMediaItem } : el)));
      showNotification({ type: 'warning', message: t('media.coverImageAspectRatioAdjusted') });
    }
    if (value === MEDIA_ENUM.COVER) {
      handleCoverImageSelection(value, index, selectedMediaTypes, setSelectedMediaTypes, setMedia);
    } else {
      setSelectedMediaTypes((prev) => updateMediaTypes(prev, index, value));
      setMedia((prev) => updateMedia(prev, index, value));
    }
  };

  const uploadMedia = async (item, index) => {
    const progressArray = new Array(media.length).fill(0);
    const controller = new AbortController();
    let apiPath = '/media/createMedia';
    const category = selectedMediaTypes[index] === 'VIDEO' ? 'ADDITIONAL' : selectedMediaTypes[index];
    const payload = {
      businessProfileId: busienssId || businessProfile.id,
      media: item.mediaSource,
      category,
    };
    if (dayjs(publishDate).isValid()) {
      payload.publishDate = publishDate;
      apiPath = '/media/createPostponedMedia';
    }
    await api.post(apiPath, payload, {
      signal: controller.signal,
      onUploadProgress: (progressEvent) => {
        const progress = Number((progressEvent.loaded / progressEvent.total) * 100).toFixed(1);
        progressArray[index] = parseFloat(progress);
        const totalProgress = progressArray.reduce((a, b) => a + b, 0) / media.length;
        setUploadProgress(totalProgress.toFixed(1));
      },
    });
  };

  const editMedia = async () => {
    setIsLoading(true);
    try {
      await api.post('/media/update', {
        mediaId: media[0].id,
        businessProfileId: businessProfile.id,
        publishDate,
      });
      onSuccess();
      showNotification({ type: 'success', message: t('businessProfileEdit.postponedMediaDataChange') });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      handleModal();
      setIsLoading(false);
    }
  };
  const addMediaHandler = async () => {
    setIsLoading(true);
    try {
      await Promise.allSettled(media.map(uploadMedia));
      onSuccess();
      setPublishDate(null);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      handleModal();
      setIsLoading(false);
    }
  };

  const onMediaChangeHandler = (file) => {
    setMedia((prev) => {
      const unique = getUniqueArray(prev, file);
      return unique;
    });
  };

  const onLocaleImageRemover = (file) => {
    const foundMediaIndex = media.findIndex((el) => el.id === file.id);
    setMedia((prev) => prev.filter((el) => el.id !== file.id));
    setSelectedMediaTypes((prev) => {
      const newSelectedMediaTypes = { ...prev };
      delete newSelectedMediaTypes[foundMediaIndex];
      return newSelectedMediaTypes;
    });
  };

  useEffect(() => {
    const isVideo = media.some((el) => el.extension === 'mp4');
    if (isVideo) {
      setSelectedMediaTypes((prev) => {
        const newSelectedMediaTypes = media.map((el, index) => (el.extension === 'mp4' ? MEDIA_ENUM.VIDEO : prev[index]),);
        return newSelectedMediaTypes;
      });
    }
  }, [media]);

  useEffect(() => {
    if (editItem) {
      const parsedEditItem = [editItem].map((el) => ({
        ...el,
        mediaSource: el.link,
        filename: el.name,
        extension: getFileExtension(el.name),
      }));
      setMedia(parsedEditItem);
      setPublishDate(parseBackDate(editItem.publishDate));
      setSelectedMediaTypes({ 0: editItem.category });
    }
  }, [editItem]);

  useEffect(() => {
    if (categoryMediaItemToChange) {
      setSelectedMediaTypes({ 0: categoryMediaItemToChange });
    }
  }, [categoryMediaItemToChange]);

  useEffect(() => {
    const readOnlyAddBtn = !media.length || Object.values(selectedMediaTypes).length !== media.length || isPlannedBeforeToday;
    setIsReadOnlyBtn(readOnlyAddBtn);
  }, [media, selectedMediaTypes, isPlannedBeforeToday]);

  if (isLoading && !editItem) {
    return (
      <div className={classes.progressWrapper}>
        <ProgressCircle progress={uploadProgress} />
      </div>
    );
  }
  return (
    <Modal
      rwdBackTitle={t('global.goBack')}
      contentStyle={classes.modalContent}
      onCancel={handleModal}
      modalTitle={modalTitle}
    >
      <div className={classes.modalContentWrapper}>
        <div className={classes.mediaTypes}>
          {media.map((mediaItem, index) => (
            <AddMediaDetails
              key={mediaItem.filename}
              selectedMediaTypes={selectedMediaTypes}
              mediaItem={mediaItem}
              index={index}
              categoryMediaItemToChange={categoryMediaItemToChange}
              editItem={editItem}
              onSelectHandler={onSelectHandler}
            />
          ))}
        </div>
        <DragAndDrop
          onFileRemove={onLocaleImageRemover}
          maxFilesNumber={categoryMediaItemToChange ? 1 : 5}
          files={media}
          onChange={onMediaChangeHandler}
          className={classes.dragAndDrop}
          btnLabel={t('businessProfileEdit.addMedia')}
          withVideo
          isEdit={Boolean(editItem)}
          multipleAllowed
          disableDelete={Boolean(categoryMediaItemToChange)}
        />
        {Boolean(media.length && !categoryMediaItemToChange) && (
          <SubInformation className={classes.helperText} label={t('businessProfileEdit.mediaHelperText')} />
        )}
      </div>
      <PlannedPost
        plannedPublishDate={publishDate}
        onPublicationDateChange={onPublicationDateChange}
        media
        onError={setIsReadOnlyBtn}
      />
      {isPlannedBeforeToday && (
        <div className={classes.error}>{t('businessProfileEdit.postoponedMediaMustBeAfterToday')}</div>
      )}
      <div className={classes.actions}>
        <Button className={classes.cancelBtn} label={t('global.cancel')} onClick={handleModal} />
        <Button
          readOnly={isReadOnlyBtn}
          className={classes.addBtn}
          label={editItem ? t('global.edit') : t('global.add')}
          onClick={editItem ? editMedia : addMediaHandler}
        />
      </div>
    </Modal>
  );
};

export default AddMediaModal;

import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as LogoIcon } from '../../../../shared/assets/logoWhite.svg';
import { navLinks } from '../NavigationBar/utils';
import { EXTERNAL_ADDRESSES } from '../../../../shared/helpers/const';

const Footer = () => {
  const { t } = useTranslation();
  const footerSectionLinkListToRender = () => navLinks(t)
    .filter(({ url }) => url !== '#contact')
    .map(({ title, url }) => (
      <li key={url}>
        <a href={url}>{title}</a>
      </li>
    ));

  return (
    <footer id="contact" className={classes.footer}>
      <div className={classes.wrapper}>
        <div className={classes.leftSide}>
          <LogoIcon className={classes.logoIcon} />
          <ul>{footerSectionLinkListToRender()}</ul>
        </div>
        <div className={classes.rightSide}>
          <h3 className={classes.contactUs}>{t('landingPage.contactUs')}</h3>
          <div className={classes.contactWrapper}>
            <a href={`mailto:${EXTERNAL_ADDRESSES.EMAIL}`}>{t('landingPage.clientMail')}</a>
            <a href={`tel:${EXTERNAL_ADDRESSES.PHONE_NUMBER}`}>{t('landingPage.clientPhoneNumber')}</a>
          </div>
        </div>
      </div>
      <div className={classes.copyRightsWrapper}>
        <p className={classes.copyRightsText}>{t('landingPage.copyRights')}</p>
        <div className={classes.externalSubpagesWrapper}>
          <a href="politykaprywatnosci" className={classes.copyRightsText}>
            {t('landingPage.privacyPolicy')}
          </a>
          <a href="regulamin" className={classes.copyRightsText}>
            {t('landingPage.termsOfService')}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

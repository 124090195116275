import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../shared/components/Input';
import classes from './styles.module.css';
import {
  capitalizeWordsAndPreserveDashesTransformer,
  nameTransformer,
  phoneNumberTransformer,
} from '../../../../../shared/components/Input/transformers';
import { readOnlyFields } from './utils';
import { phoneNumberValidator, required } from '../../../../../shared/components/Input/validators';
import { useNotification } from '../../../../../shared/helpers/notification';

const MIN_LENGTH_3 = 3;
const INPUT_MAX_LENGTH = 50;

const ManagerDetailsForm = ({
  data: { active, activeChange, createdAt, lastLogin, activeSubscription },
  form,
  onChange,
  onError,
}) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const readOnlyData = { activeChange, createdAt, lastLogin, active, activeSubscription };
  const readOnlyDataFields = useMemo(() => readOnlyFields({ t, data: readOnlyData }), [t, readOnlyData]);

  useEffect(() => {
    if (activeSubscription) {
      showNotification({ type: 'error', message: t('adminControlPanel.subscriptionActiveError') });
    }
  }, [activeSubscription]);

  return (
    <div className={classes.dataWrapper}>
      <div className={classes.userDetailsTitle}>{t('adminControlPanel.clientDetails')}</div>
      <form className={classes.inputWrapper}>
        <div className={classes.singleRow}>
          <Input
            admin
            showErrorAfterBlur
            value={form.firstName}
            label={t('adminControlPanel.name')}
            onChange={onChange('firstName')}
            transformer={nameTransformer}
            validator={required(MIN_LENGTH_3)}
            maxLength={INPUT_MAX_LENGTH}
            onError={onError('firstName')}
          />
          <Input
            admin
            showErrorAfterBlur
            value={form.lastName}
            label={t('adminControlPanel.lastName')}
            onChange={onChange('lastName')}
            transformer={capitalizeWordsAndPreserveDashesTransformer}
            validator={required(MIN_LENGTH_3)}
            maxLength={INPUT_MAX_LENGTH}
            onError={onError('lastName')}
          />
        </div>
        <Input
          admin
          showErrorAfterBlur
          value={form.phone}
          className={classes.phoneInput}
          label={t('adminControlPanel.fullPhoneNumber')}
          onChange={onChange('phone')}
          transformer={phoneNumberTransformer}
          validator={phoneNumberValidator}
          onError={onError('phone')}
        />
      </form>
      <div className={classes.readOnlyData}>
        {readOnlyDataFields.map(({ id, label, value, additional }) => (
          <div key={id} className={classes.record}>
            <span className={classes.label}>{label}</span>
            <span className={classes.value}>{value}</span>
            {additional && <span className={classes.value}>{additional}</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManagerDetailsForm;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import classes from './styles.module.css';
import { generateAdminNavigationSections, isCurrentPath } from './utils';
import { useAdmin } from '../../helpers/admin';

const AdminNavigationSections = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { role } = useAdmin();

  const sectionsToRender = generateAdminNavigationSections(t, role);

  const onSectionNavigateHandler = (path) => {
    navigate(path);
  };

  return (
    <div className={classes.navItemsWrapper}>
      {sectionsToRender.map(({ title, path, Icon }) => (
        <div
          key={title}
          className={clsx(classes.navItem, isCurrentPath(path, pathname) && classes.selectedSection)}
          onClick={() => onSectionNavigateHandler(path, title)}
        >
          <Icon className={classes.icon} />
          {title}
        </div>
      ))}
    </div>
  );
};
export default AdminNavigationSections;

import React from 'react';
import classes from './styles.module.css';
import { paymentMethodConfig } from '../PaymentMethodItem/utils';

export const termsOfServiceLabel = (t) => (
  <div className={classes.termsOfService}>
    {t('settings.accept')}
    {' '}
    <a href="/regulamin" target="_blank" rel="noopener noreferrer">
      {t('settings.termsOfService')}
    </a>
  </div>
);

export const getOfferDetails = ({ isTrial, selectedOffer }) => {
  if (!isTrial) {
    const { title, type } = selectedOffer;
    if (!title || !type) {
      return { price: 0, priceTagShort: 'subscription.perMonthShort' };
    }
    const offerDetails = paymentMethodConfig[title.toLowerCase()]?.[type];
    if (!offerDetails) {
      return { price: 0, priceTagShort: 'subscription.perMonthShort' };
    }
    return { ...offerDetails, type };
  }

  return { price: 0, priceTagShort: 'subscription.perMonthShort' };
};

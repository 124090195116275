import dayjs from 'dayjs';
import { getDateFromObject } from '../../../../../shared/helpers/strings';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_WITH_TIME_FORMAT } from '../../../helpers/constants';
import { ONE_YEAR_LATER, TODAY } from '../../../../../shared/helpers/const';

export const TEXT_AREA_MAX_LENGTH = 1500;

export const FormTypeEnum = {
  update: 'update',
  create: 'create',
};

const getDayJSDateFromObject = (date) => (date && dayjs(getDateFromObject(date)).isValid() ? dayjs(getDateFromObject(date)).format(DEFAULT_DATE_FORMAT) : '');

const getTimeFromObject = (time) => {
  if (!time) return null;
  const { hours, minutes } = time;
  return `${hours || '00'}:${minutes || '00'}`;
};

export const timesFromAllDayEvent = ['00:00', '23:59', '0:0'];

export const getValuesForInput = (schedule) => {
  if (!schedule) return {};
  const { startDate, endDate, startTime, endTime } = schedule;

  const getTime = (time) => time && getTimeFromObject(time);

  const inputStartDate = getDayJSDateFromObject(startDate);
  const inputEndDate = getDayJSDateFromObject(endDate);
  const today = TODAY.format(DEFAULT_DATE_FORMAT);

  const startEqualEnd = dayjs(inputStartDate).isSame(inputEndDate);
  const endDateIsToday = dayjs(inputEndDate).isSame(today);
  const isOneDayEvent = startEqualEnd || endDateIsToday;

  const isAllDayEvent = (time) => timesFromAllDayEvent.includes(time);
  const startTimeFromObject = getTime(startTime);
  const endTimeFromObject = getTime(endTime);
  const inputStartTime = isAllDayEvent(startTimeFromObject) ? null : getTime(startTime);
  const inputEndTime = isAllDayEvent(endTimeFromObject) ? null : getTime(endTime);
  return { inputStartDate, inputEndDate, inputStartTime, inputEndTime, isOneDayEvent };
};

export const ErrorCodes = {
  INPUT_MISSING: 'posts.inputMissing',
  END_DATE_BEFORE_START_DATE: 'posts.endDateBeforeStartDate',
  START_DATE_AFTER_TODAY: 'posts.startDateAfterToday',
  PASS_END_TIME: 'posts.passEndTime',
  PASS_START_TIME: 'posts.passStartTime',
  END_DATE_AFTER_YEAR: 'posts.endDateAfterYear',
  END_DATE_BEFORE_TODAY: 'posts.endDateBeforeToday',
};
export const validateEventFormSchedule = (schedule) => {
  const { inputStartDate, inputEndDate, inputStartTime, inputEndTime } = getValuesForInput(schedule);
  const fullStartDate = dayjs(`${inputStartDate} ${inputStartTime}`).format(DEFAULT_DATE_WITH_TIME_FORMAT);
  const fullEndDate = dayjs(`${inputEndDate} ${inputEndTime}`).format(DEFAULT_DATE_WITH_TIME_FORMAT);
  if (!inputStartDate || !inputEndDate) {
    return false;
  }
  if (dayjs(inputEndDate).isBefore(TODAY) && !dayjs(inputEndDate).isSame(TODAY, 'day')) {
    return ErrorCodes.END_DATE_BEFORE_TODAY;
  }
  if (dayjs(inputEndDate).isBefore(inputStartDate)) {
    return ErrorCodes.END_DATE_BEFORE_START_DATE;
  }

  if (dayjs(inputEndDate).isAfter(ONE_YEAR_LATER)) {
    return ErrorCodes.END_DATE_AFTER_YEAR;
  }
  if (
    (dayjs(fullStartDate).isSame(fullEndDate) || dayjs(fullStartDate).isAfter(fullEndDate))
    && inputStartTime
    && inputEndTime
  ) {
    return ErrorCodes.END_DATE_BEFORE_START_DATE;
  }
  if (inputStartTime && !inputEndTime) {
    return ErrorCodes.PASS_END_TIME;
  }
  if (!inputStartTime && inputEndTime) {
    return ErrorCodes.PASS_START_TIME;
  }
  return false;
};

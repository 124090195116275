import clsx from 'clsx';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './style.module.css';
import { ReactComponent as ChevronDown } from '../../assets/chevronDown.svg';
import IconWrapper from '../IconWrapper/index';
import { SelectEnum, selectList } from './utils';

const FiltersDropDown = ({ filters }) => {
  const { t } = useTranslation();
  const list = useRef();
  const { area, net, distance } = SelectEnum;

  return (
    <div ref={list} className={classes.fields}>
      {selectList.map(({ id, name }) => (
        <div aria-label={id} tabIndex={0} role="button" key={id} className={clsx(classes.field, classes.disabled)}>
          <div className={classes.label}>
            {t(name)}
            <IconWrapper
              className={classes.icon}
              Icon={ChevronDown}
              height={20}
              width={20}
              hoverColor="var(--grey600)"
            />
          </div>
          {id === net && (
            <div className={clsx(classes.value, classes.readOnly)}>
              {filters[net] ? `${filters[net]} x ${filters[net]}` : ''}
            </div>
          )}
          {id === area && <div className={classes.value}>{filters[area] ? `${filters[area]} km` : ''}</div>}
          {id === distance && <div className={classes.value}>{filters[distance] ? `${filters[distance]} km` : ''}</div>}
        </div>
      ))}
    </div>
  );
};

export default FiltersDropDown;

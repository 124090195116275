import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import CircleIconText from '../../../../../shared/components/CircleIconText';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as Magnifier } from '../../../../../shared/assets/magnifier.svg';
import { ReactComponent as AddCircle } from '../../../../../shared/assets/addCircle.svg';
import { TAB_ENUM } from '../../../views/AddPost/utils';
import { useGetSubscriptionData } from '../../../../../shared/helpers/hooks';

const MediaEmptyState = ({ adminPreview, onAddMedia, selectedTab }) => {
  const { t } = useTranslation();

  const handleAddMedia = () => onAddMedia();
  const isPlannedTab = selectedTab === TAB_ENUM.PLANNED;
  const { isReadOnlySubPlan } = useGetSubscriptionData();
  const isButtonDisplayed = !adminPreview && !isReadOnlySubPlan;

  return (
    <div className={classes.wrapper}>
      <CircleIconText
        icon={Magnifier}
        heading={isPlannedTab ? t('media.noPlannedMedia') : t('media.noMedia')}
        supportingText={isPlannedTab ? t('media.noPlannedMediaSubInfo') : t('media.addFirstMedia')}
        headingStyle={classes.headingStyle}
      />
      {isButtonDisplayed && (
        <Button
          onClick={handleAddMedia}
          className={classes.button}
          Icon={AddCircle}
          iconStyle={classes.iconStyle}
          label={t('media.addMedia')}
        />
      )}
    </div>
  );
};

export default MediaEmptyState;

import React from 'react';
import clsx from 'clsx';
import Pill from '../../../../shared/components/Pill';
import classes from './styles.module.css';
import { DATE_FORMAT_FULL_MONTH } from '../../helpers/constants';
import { formatDate } from '../../../../shared/helpers/helpers';

export const seoCardStatusLabel = (active, t) => {
  const activePillLabel = active ? t('seoCard.cardActive') : t('seoCard.cardInactive');
  return activePillLabel;
};
export const clientSeoCardListParser = (data, t) => data.map((item) => ({
  ...item,
  key: item.id,
  cardName: item.cardName,
  interaction: item.interaction || '-',
  lastUsed: formatDate(item.lastUsed),
  createdAt: formatDate(item.createdAt),
  createdAtCardDetails: formatDate(item.createdAt, DATE_FORMAT_FULL_MONTH),
  bpName: item.bpName || '-',
  activePill: (
    <Pill
      className={clsx(classes.pill, item.active && classes.activeStatusPill)}
      label={seoCardStatusLabel(item.active, t)}
    />
  ),
}));

export const paymentMethodConfig = {
  mini: {
    one_time_1: {
      price: 29,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
    one_time_12: {
      price: 29 * 10,
      priceTag: 'subscription.perYear',
      subInfo: 'subscription.twoMonthsGratis',
      priceTagShort: 'subscription.perYearShort',
    },
    recurring: {
      price: 19,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
  },
  basic: {
    one_time_1: {
      price: 99,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
    one_time_12: {
      price: 99 * 10,
      priceTag: 'subscription.perYear',
      subInfo: 'subscription.twoMonthsGratis',
      priceTagShort: 'subscription.perYearShort',
    },
    recurring: {
      price: 79,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
  },
  pro: {
    one_time_1: {
      price: 149,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
    one_time_12: {
      price: 149 * 10,
      priceTag: 'subscription.perYear',
      subInfo: 'subscription.twoMonthsGratis',
      priceTagShort: 'subscription.perYearShort',
    },
    recurring: {
      price: 99,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
  },
};

export const paymentMethodEnum = {
  recurring: 'recurring',
  oneTimeForYear: 'one_time_12',
  oneTimeForMonth: 'one_time_1',
};

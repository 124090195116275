import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { useNotification } from '../../../../shared/helpers/notification';
import { useApi } from '../../../../shared/helpers/api';
import { useAuth } from '../../../../shared/helpers/auth';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { SORT_DIRECTION_ENUM } from '../../../../shared/helpers/const';
import {
  FILTERS_ENUM,
  getParsedNotificationData,
  NOTIFCATION_STATUS_ENUM,
  NOTIFICATION_ORDER_COLUMN_ENUM,
  notificationListConfig,
} from './utils';
import List from '../../../../shared/components/List';
import { useModal } from '../../../../shared/helpers/hooks';
import Button from '../../../../shared/components/Button';
import Pagination from '../../../../shared/components/Pagination';
import NotificationFilters from '../../modules/Notifications/Filters';
import { ReactComponent as Filters } from '../../../../shared/assets/filter.svg';
import { removeNullValues } from '../../../../shared/helpers/parsers';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import { PAYLOAD_KEY_ENUM } from '../../../../shared/helpers/enums';

const { ASC, DESC } = SORT_DIRECTION_ENUM;
const { businessProfileId } = FILTERS_ENUM;
const { OFFSET, LIMIT } = PAYLOAD_KEY_ENUM;
const { notViewed, viewed } = NOTIFCATION_STATUS_ENUM;
const initFilters = {
  filters: {
    limit: 10,
    offset: 0,
  },
  orderBy: {
    column: NOTIFICATION_ORDER_COLUMN_ENUM.date,
    direction: DESC,
  },
};
const Notifications = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { businessProfiles } = useAuth();

  const [isFiltersModalOpen, setFiltersModalOpen, toggleFiltersModal] = useModal();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [notifications, setNotifications] = useState({ results: [], total: 0 });
  const [payload, setPayload] = useState(initFilters);

  const notificationNotSeen = notifications.results?.filter((notification) => notification.status === notViewed);

  const fetchNotificationList = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post('/notification/notificationList', {
        filters: removeNullValues(payload.filters),
        orderBy: payload.orderBy,
      });
      setNotifications({ results: getParsedNotificationData(data.results, businessProfiles), total: data.total });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const handleListAction = (value) => {
    if (typeof value === 'number') {
      setSelectedNotifications((prev) => {
        if (prev.includes(value)) {
          return prev.filter((el) => el !== value);
        }
        return [...prev, value];
      });
      return;
    }

    setSelectedNotifications((prev) => (prev.length === notifications.results.length ? [] : notifications.results.map((el) => el.id)),);
  };

  const memoizedConfig = useMemo(() => notificationListConfig(t, handleListAction, selectedNotifications));

  const handlePaginationChange = (key) => (value) => {
    if (key === OFFSET) {
      setPayload((prev) => ({
        ...prev,
        filters: {
          ...prev.filters,
          offset: value === 1 ? 0 : (value - 1) * prev.filters.limit,
        },
      }));
      return;
    }
    setPayload((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        [key]: value,
      },
    }));
  };

  const handleDeleteSelectedItems = async () => {
    setIsLoading(true);
    try {
      await api.post('/notification/deleteNotifications', { ids: selectedNotifications });
      await fetchNotificationList();
      showNotification({ type: 'success', message: t('adminControlPanel.selectedItemsDeletionSuccess') });
      setSelectedNotifications([]);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersChange = (key) => (value) => {
    if (key === businessProfileId) {
      setPayload((prev) => ({
        ...prev,
        filters: {
          ...prev.filters,
          businessProfileId: value,
        },
      }));
      return;
    }
    if (key === 'reset') {
      setPayload(initFilters);
      return;
    }
    setPayload((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        [key]:
          Array.isArray(prev.filters[key]) && prev.filters[key].includes(value)
            ? prev.filters[key].filter((item) => item !== value)
            : [...(Array.isArray(prev.filters[key]) ? prev.filters[key] : []), value],
      },
    }));
  };

  const page = (() => {
    const { offset, limit } = payload.filters;
    return offset === 0 ? 1 : Math.round(offset / limit) + 1;
  })();

  useEffect(() => {
    if (!isFiltersModalOpen) {
      fetchNotificationList();
    }
  }, [payload.orderBy, payload.filters.limit, JSON.stringify(payload.filters), isFiltersModalOpen]);

  useEffect(() => {
    if (!notificationNotSeen.length) return;
    const controller = new AbortController();
    const markNotificationsAsViewed = async () => {
      try {
        await api.post(
          '/notification/updateIsViewed',
          {
            status: viewed,
            ids: notifications.results
              .filter((notification) => notification.status === notViewed)
              .map((notification) => notification.id),
          },
          { signal: controller.signal },
        );
      } catch (err) {
        handleApiError({ err, t, showNotification });
      }
    };

    markNotificationsAsViewed();
  }, [notificationNotSeen]);

  if (isLoading) {
    return <LoaderOverlay />;
  }

  return (
    <div>
      {isFiltersModalOpen && (
        <NotificationFilters
          onClose={toggleFiltersModal}
          filters={payload.filters}
          onFiltersChange={handleFiltersChange}
          onSubmit={fetchNotificationList}
        />
      )}
      <div className={classes.header}>
        <h2 className={classes.title}>{t('notifications.title')}</h2>
      </div>
      <div className={classes.body}>
        <div className={classes.row}>
          <Button
            className={classes.filterButton}
            label={t('global.filters')}
            Icon={Filters}
            onClick={toggleFiltersModal}
          />
          {Boolean(selectedNotifications.length) && (
            <Button className={classes.deleteBtn} label={t('global.delete')} onClick={handleDeleteSelectedItems} />
          )}
        </div>
        <List className={classes.notificationList} data={notifications.results} config={memoizedConfig} />
        <Pagination
          className={classes.pagination}
          page={page}
          total={notifications.total}
          limit={payload.filters.limit}
          onLimitChange={handlePaginationChange(LIMIT)}
          onPageChange={handlePaginationChange(OFFSET)}
        />
      </div>
    </div>
  );
};

export default Notifications;

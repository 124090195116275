import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import FormSuccessIndicator from '../../../../../shared/components/FormSuccessIndicator';
import Button from '../../../../../shared/components/Button';
import ExpandableSection from '../../../../../shared/components/ExpandableSection';
import { useGetSubscriptionData } from '../../../../../shared/helpers/hooks';

const FormWrapper = ({ className, children, label, status, suggestions, editHandler, adminPreview }) => {
  const { t } = useTranslation();
  const { isReadOnlySubPlan } = useGetSubscriptionData();

  const sectionWithNoEdit = label !== t('businessProfileEdit.services') && !adminPreview;
  const isEditBtnRendered = !isReadOnlySubPlan && sectionWithNoEdit;
  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.form}>
        {label && (
          <div className={classes.header}>
            <FormSuccessIndicator className={classes.indicator} status={status} radius={22} />
            {label}
            {isEditBtnRendered && (
              <Button onClick={() => editHandler(label)} className={classes.editBtn} label={t('global.edit')} />
            )}
          </div>
        )}
        {children}
      </div>
      {suggestions && (
        <ExpandableSection
          wrapperStyle={classes.wrapperStyle}
          sectionNameStyle={classes.sectionName}
          sectionName={t('businessProfileEdit.suggestion')}
        >
          <div className={classes.suggestionRWD}>
            <div className={classes.box}>
              <ul className={classes.text}>
                {suggestions.map((suggestion) => (
                  <li key={suggestion.id}>{suggestion.message}</li>
                ))}
              </ul>
            </div>
          </div>
        </ExpandableSection>
      )}
      {suggestions && (
        <div className={classes.suggestion}>
          {Boolean(suggestions.length) && (
            <div className={classes.box}>
              <div className={classes.suggestionHeader}>{t('businessProfileEdit.suggestion')}</div>
              <ul className={classes.text}>
                {suggestions.map((suggestion) => (
                  <li key={suggestion.id}>{suggestion.message}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FormWrapper;

import { tokenEnum } from '../../../app/SEO/helpers/constants';
import { handleApiError } from '../../../app/SEO/modules/Settings/ChangePassword/utils';
import { businessTypeParser } from '../../../app/SEO/views/Login/utils';

export const fetchAdminSession = async ({ api, controller, setAuth, setAdmin, t, showNotification }) => {
  const adminToken = localStorage.getItem('adminToken');
  if (!adminToken) {
    setAuth({ isAdmin: false });
    return;
  }
  try {
    const { data } = await api.post('/acp/auth/getSession', {}, { signal: controller.signal });
    const { id, login, lastName, name, roleType } = data.session;
    setAuth({ id, login, businessProfiles: [], isAdmin: true });
    setAdmin({ id, login, lastName, name, role: roleType });
  } catch (err) {
    if (![401, 400].includes(err.response?.status)) {
      handleApiError({ err, showNotification, t });
    }
  }
};

export const fetchSeoSession = async ({ api, controller, setAuth, t, showNotification }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setAuth({ isAuth: false });
    return;
  }
  try {
    const {
      data: { session },
    } = await api.post('/auth/getSession', {}, { signal: controller.signal });
    const { id, login, businessProfiles, subscriptions, isManager } = session;
    const profileWithBusinessType = businessTypeParser(businessProfiles, subscriptions);
    setAuth({
      isAuth: true,
      id,
      login,
      isManager,
      businessProfiles: profileWithBusinessType,
    });
  } catch (err) {
    handleApiError({ err, showNotification, t });
  }
};

export const storeTokens = ({ token, refreshToken, adminToken, refreshAdminToken }) => {
  localStorage.setItem(tokenEnum.token, token);
  localStorage.setItem(tokenEnum.refreshToken, refreshToken);
  localStorage.setItem(tokenEnum.adminToken, adminToken);
  localStorage.setItem(tokenEnum.refreshAdminToken, refreshAdminToken);
};

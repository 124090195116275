import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../Input';
import Loader from '../../../app/SEO/components/Loader';
import { ReactComponent as ChevronDown } from '../../assets/chevronDown.svg';
import { useKeyDown } from '../../helpers/hooks';

const AsyncSelect = ({
  label,
  showErrorAfterBlur,
  value,
  className,
  validator,
  inputClassName,
  infiniteScroll,
  limit,
  readOnly,
  placeholder,
  withChevron,
  resetValueOnSelect,
  onListRead = async () => {},
  onElementGet = async () => {},
  onSelect = () => {},
  showOnList = (el) => (el ? el.name : ''),
  admin,
  labelRised,
  transformer,
}) => {
  const { t } = useTranslation();
  const observer = useRef();
  const options = useRef();
  const offset = useRef(0);
  const input = useRef();
  const inputValue = useRef('');
  const [choice, setChoice] = useState(value);
  const [showLoader, setShowLoader] = useState(true);
  const [showList, setShowList] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState(validator ? validator(value) : false);
  const isNoDataMessageDisplayed = showList && inputValue.current.length > 3 && !list.length;

  const firstElementRef = useRef(null);

  useEffect(() => {
    if (firstElementRef.current && list.length === 1) {
      firstElementRef.current.focus();
    }
  }, [list]);

  const hideList = useCallback((event) => {
    if (!input.current.contains(event.target)) {
      setShowList(false);
      setChoice(null);
      inputValue.current = '';
      document.removeEventListener('click', hideList);
    }
  }, []);

  const handleElementGet = async (val) => {
    if (!val) {
      setChoice(null);
      return;
    }
    const found = list.find((el) => el.id === val);
    if (found) {
      setChoice(found);
      return;
    }
    const element = await onElementGet({ value: val });
    if (element) {
      setChoice(element);
      inputValue.current = showOnList(element);
    }
  };

  const handleChange = async (val) => {
    setChoice(null);
    if (choice) {
      onSelect(null);
      inputValue.current = '';
    } else {
      inputValue.current = val;
    }

    offset.current = 0;
    const currentList = await onListRead({ value: inputValue.current, offset: offset.current });
    setShowLoader(currentList?.length >= limit);
    setList(currentList);
  };

  const handleFocus = async () => {
    setShowList(true);
    inputValue.current = '';
    document.addEventListener('click', hideList);
    if (!list?.length) {
      setChoice(null);
      const currentList = await onListRead({ value, offset: offset.current });
      setShowLoader(currentList?.length >= limit);
      setList(currentList);
      inputValue.current = '';
    }
  };

  const handleSelect = (val) => (e) => {
    setList([]);
    setChoice(val);
    onSelect(val);
    handleElementGet(val);
    if (resetValueOnSelect) {
      inputValue.current = '';
      setChoice('');
      setShowList(false);
    }
    setShowList(false);
    inputValue.current = val;
    document.removeEventListener('click', hideList);
    e.stopPropagation();
  };

  const handleKeyDown = (val) => (event) => {
    if (event.keyCode === 13) {
      onSelect(val);
      setShowList(false);
      document.removeEventListener('click', hideList);
    }
  };

  const handleElementRefChange = useCallback((ref) => {
    if (observer.current && ref) {
      observer.current.observe(ref);
    }
  }, []);

  useEffect(() => {
    handleElementGet(value);
    if (validator) {
      const isValid = validator(value);
      setError(isValid);
    }
  }, [value]);

  useEffect(() => {
    if (options.current.children.length > limit) {
      options.current.scroll(0, (options.current.children.length - limit - 1) * 44);
    }
  }, [list?.length]);

  useEffect(() => {
    const onIntersect = async (entries, instance) => {
      if (entries[0].isIntersecting) {
        offset.current += limit;
        const currentList = await onListRead({ value: inputValue.current, offset: offset.current });
        setList((prev) => [...prev, ...currentList]);
      }
    };
    observer.current = new IntersectionObserver(onIntersect, { root: options.current, threshold: 0.1 });

    return () => {
      document.removeEventListener('click', hideList);
    };
  }, []);

  return (
    <div ref={input} className={clsx(classes.wrapper, className, readOnly && classes.disabled)}>
      <div>
        <Input
          readOnly={readOnly}
          className={inputClassName}
          label={label}
          validator={validator}
          error={error}
          onError={setError}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          value={resetValueOnSelect ? choice : showOnList(choice)}
          showErrorAfterBlur={showErrorAfterBlur}
          admin={admin}
          labelRised={labelRised}
          transformer={transformer}
          autoComplete="off"
        />
        {withChevron && (
          <ChevronDown
            className={clsx(
              classes.icon,
              showList && classes.invertedIcon,
              readOnly && classes.disabled,
              admin && classes.adminIcon,
              error && classes.errorIcon,
            )}
            height={20}
            width={20}
          />
        )}
      </div>
      <div className={classes.list} ref={options}>
        {isNoDataMessageDisplayed && (
          <div className={classes.noDataElement} onClick={hideList}>
            {t('global.noData')}
          </div>
        )}
        {showList && (
          <>
            {list?.map((el, idx) => (
              <div
                aria-label={el.name}
                key={el.id}
                role="link"
                onKeyDown={handleKeyDown(el)}
                tabIndex={0}
                className={clsx(classes.element, idx === 0 && classes.hoveredElement)}
                onClick={handleSelect(el)}
                ref={idx === 0 ? firstElementRef : null}
              >
                {showOnList(el)}
              </div>
            ))}
            {infiniteScroll && showLoader && (
              <div ref={handleElementRefChange}>
                <Loader />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AsyncSelect;

import React from 'react';
import classes from './styles.module.css';

export const showOnListHandler = ({ name, address }) => (
  <p className={classes.element}>
    <span className={classes.name}>{name}</span>
    {address && (
      <>
        <br />
        <span className={classes.address}>{address}</span>
      </>
    )}
  </p>
);

import dayjs from 'dayjs';
import { TODAY } from '../../../../../shared/helpers/const';

export const singleLinkStatisticTableConfig = (t, admin = true) => [
  {
    header: admin ? t('adminControlPanel.lastInteractionDate') : t('adminControlPanel.interactionDate'),
    property: 'countedHour',
    sort: 'counted_hour',
  },
  { header: t('adminControlPanel.redirects'), property: 'count', sort: 'count' },
];

export const rangeValueConfig = {
  hours: '1 hours',
  days: '1 day',
  weeks: '7 day',
  months: '1 month',
};

const { days, hours, months, weeks } = rangeValueConfig;

export const rangeValues = (t) => [
  { id: 'hour', value: hours, label: t('adminControlPanel.hourly'), format: 'DD/MM/YY, HH:mm' },
  { id: 'day', value: days, label: t('adminControlPanel.daily'), format: 'DD/MM/YY' },
  { id: 'week', value: weeks, label: t('adminControlPanel.weekly'), format: 'DD/MM/YY' },
  { id: 'month', value: months, label: t('adminControlPanel.monthly'), format: 'MMMM YY' },
];

const formatDateForHours = (date) => date.format('DD/MM/YY, HH:mm');

const formatDateForDays = (date) => date.format('DD/MM/YY');

const formatDateForWeeks = (date) => {
  let endDate = date.add(6, 'day');
  if (endDate.isAfter(TODAY)) {
    endDate = TODAY.subtract(1, 'day');
  }
  if (endDate.isBefore(date)) {
    // eslint-disable-next-line no-param-reassign
    [date, endDate] = [endDate, date];
  }
  const isSameYear = date.isSame(endDate, 'year');
  return isSameYear
    ? `${date.format('DD/MM')} - ${endDate.format('DD/MM')} ${date.format('YY')}`
    : `${date.format('DD/MM/YY')} - ${endDate.format('DD/MM/YY')}`;
};

const formatDateForMonths = (date) => date.locale('pl').format('MMMM YY');

export const parseLinkData = ({ results, admin, groupRange }) => results.map((item) => {
  const { countedHour } = item;
  let formattedDate;
  const date = dayjs(countedHour);

  switch (groupRange) {
    case hours:
      formattedDate = formatDateForHours(date);
      break;
    case days:
      formattedDate = formatDateForDays(date);
      break;
    case weeks:
      formattedDate = formatDateForWeeks(date);
      break;
    case months:
      formattedDate = formatDateForMonths(date);
      break;
    default:
      formattedDate = date.format('DD/MM/YY, HH:mm');
  }

  const clientFormattedDate = date.locale('pl').format('MMMM YY');
  return {
    ...item,
    countedHour: admin ? formattedDate : clientFormattedDate,
  };
});

export const clientLimitList = [12, 24, 48];
export const limitListDaily = [24, 48, 72];

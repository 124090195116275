export const basicDataConfig = ({ t, isManager }) => {
  const config = [
    {
      title: t('adminControlPanel.email'),
      key: 'login',
    },
    {
      title: t('adminControlPanel.phoneNumber'),
      key: 'phone',
    },
  ];

  if (isManager) {
    config.unshift(
      {
        title: t('adminControlPanel.name'),
        key: 'firstName',
      },
      {
        title: t('adminControlPanel.lastName'),
        key: 'lastName',
      },
    );
  }

  return config;
};

import { formatDate } from '../../../../../shared/helpers/helpers';
import { DATE_FORMAT_SHORT_YEAR } from '../../../helpers/constants';

export const generateDateRangeLabel = (dateFrom, dateTo, t) => {
  if (dateFrom && dateTo) {
    if (dateFrom === dateTo) {
      return `${formatDate(dateFrom, DATE_FORMAT_SHORT_YEAR)}`;
    }
    return `${formatDate(dateFrom, DATE_FORMAT_SHORT_YEAR)} - ${formatDate(dateTo, DATE_FORMAT_SHORT_YEAR)}`;
  }
  return t('reportList.selectDate');
};

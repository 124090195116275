import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { basicDataConfig } from './utils';
import RecordRow from '../RecordRow';
import Button from '../../../../shared/components/Button';
import { useModal } from '../../../../shared/helpers/hooks';
import AddManagerModal from '../../modules/Manager/AddManagerModal';
import DesactivateUserConfirmationModal from '../../modules/AdministratorDetails/DesactivateUserConfirmationModal';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import { ReactComponent as ArrowIcon } from '../../../../shared/assets/arrowAlternative.svg';
import PaymentDataModal from '../../modules/UserDetails/PaymentDataModal';
import { useAdmin } from '../../helpers/admin';
import { ROLE_ENUM } from '../../views/AdministratorDetails/utils';

const RecordBasicData = ({ data, isManager, fetchData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { role } = useAdmin();

  const isSuperAdmin = role === ROLE_ENUM.superAdmin;
  const dataTitle = isManager ? t('adminControlPanel.managerData') : t('adminControlPanel.clientData');
  const config = useMemo(() => basicDataConfig({ t, isManager }), [t, isManager]);
  const [isVisibleAddManagerModalForm, setIsVisibleAddManagerModalForm, toggleAddManagerModalForm] = useModal();
  const [isVisibleRemoveManagerModalForm, setIsVisibleRemoveManagerModalForm, toggleRemoveManagerModalForm] = useModal();
  const [isPaymentDataVisible, setIsPaymentDataVisible, togglePaymentDataVisible] = useModal();
  const [loading, setLoading] = useState(false);

  const renderRow = ({ key, title }) => <RecordRow key={key} data={data} title={title} recordKey={key} />;

  const renderButton = useMemo(
    () => (isManager ? (
      <Button
        className={classes.actionBtn}
        label={t('adminControlPanel.removeManagerRole')}
        onClick={toggleRemoveManagerModalForm}
      />
    ) : (
      <Button
        className={classes.actionBtn}
        label={t('adminControlPanel.admitManagerRole')}
        onClick={toggleAddManagerModalForm}
      />
    )),
    [isManager],
  );

  const handleDeleteManager = async () => {
    const payload = {
      email: data.login,
      manager: false,
    };
    try {
      setLoading(true);
      await api.post('/acp/client/updateUserManager', payload);
      navigate(-1);
      showNotification({ type: 'success', message: t('adminControlPanel.managerRemoved') });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      toggleRemoveManagerModalForm();
      setLoading(false);
    }
  };

  const handleFetchData = async () => {
    await fetchData();
    setIsVisibleAddManagerModalForm(false);
  };

  if (loading) {
    return <LoaderOverlay customStyle={classes.loader} />;
  }

  return (
    <>
      <div className={classes.sectionTitle}>
        {dataTitle}
        {isSuperAdmin && renderButton}
      </div>
      {isVisibleAddManagerModalForm && (
        <AddManagerModal onListFetch={handleFetchData} onClose={toggleAddManagerModalForm} email={data.login} />
      )}
      {isVisibleRemoveManagerModalForm && (
        <DesactivateUserConfirmationModal
          onClose={toggleRemoveManagerModalForm}
          modalTitle="adminControlPanel.desactivateManagerModalTitle"
          modalMessage="adminControlPanel.desactivateManagerModalDescription"
          desactivateAccountHandler={handleDeleteManager}
        />
      )}
      {isPaymentDataVisible && <PaymentDataModal userId={data.id} onClose={togglePaymentDataVisible} />}
      <div className={classes.sectionDataWrapper}>
        {config.map(renderRow)}
        {!isManager && (
          <Button
            label={t('adminControlPanel.showPaymentData')}
            iconStyle={classes.arrowIcon}
            onClick={togglePaymentDataVisible}
            Icon={ArrowIcon}
            className={classes.showPaymentDataBtn}
          />
        )}
      </div>
    </>
  );
};

export default RecordBasicData;

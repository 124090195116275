export const SelectEnum = {
  keyword: 'keyword',
  area: 'area',
  distance: 'distance',
  businessProfileId: 'businessProfileId',
  net: 'net',
  address: 'address',
};

export const selectList = [
  { id: SelectEnum.businessProfileId, name: 'generateReport.yourCompany' },
  { id: SelectEnum.keyword, name: 'generateReport.keywords' },
  { id: SelectEnum.distance, name: 'generateReport.distance' },
  { id: SelectEnum.area, name: 'generateReport.searchArea' },
  { id: SelectEnum.net, name: 'generateReport.net' },
];

export const parseAccountDetails = ({ accountDetails, values }) => {
  if (!accountDetails || !accountDetails.businessProfiles) {
    return {
      businessProfiles: [],
      keywords: [],
      selectedKeyword: '',
      selectedBusinessProfile: '',
    };
  }
  const currentBusinessProfile = accountDetails.businessProfiles.find(
    (el) => el.id === values[SelectEnum.businessProfileId],
  );
  const businessProfiles = accountDetails.businessProfiles.map((el) => ({ id: el.id, value: el.name }));
  if (!values[SelectEnum.businessProfileId] || !currentBusinessProfile) {
    return {
      businessProfiles,
      keywords: [],
      selectedKeyword: '',
      selectedBusinessProfile: '',
    };
  }
  if (!values[SelectEnum.keyword]) {
    return {
      businessProfiles,
      keywords: currentBusinessProfile.keywords,
      selectedKeyword: '',
      selectedBusinessProfile: currentBusinessProfile.name,
    };
  }

  return {
    businessProfiles,
    keywords: currentBusinessProfile.keywords,
    selectedKeyword: '',
    selectedBusinessProfile: currentBusinessProfile.name,
  };
};

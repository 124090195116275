import React from 'react';
import Dashboard from '../../../../SEO/views/Dashboard';
import ReportList from '../../../../SEO/views/ReportList';
import GenerateReport from '../../../../SEO/views/GenerateReport';
import BusinessProfileEdit from '../../../../SEO/views/BusinessProfileEdit';
import AddPost from '../../../../SEO/views/AddPost';
import Opinions from '../../../../SEO/views/Opinions';
import BusinessProfileChangeHistory from '../../../../SEO/views/BusinessProfileChangeHistory';
import AnswerTemplates from '../../../../SEO/views/AnswerTemplates';
import MediaView from '../../../../SEO/views/Media/index';

export const getTabComponent = (tabKey, props) => {
  const {
    businessProfileId,
    userId,
    reportParams,
    businessProfile,
    handleClickReport,
    profileImage,
    businessVerificationStatus,
  } = props;

  const tabComponents = {
    dashboard: (
      <Dashboard
        businessProfile={businessProfile}
        adminPreview
        businessProfileId={businessProfileId}
        userId={userId}
        businessVerificationStatus={businessVerificationStatus}
      />
    ),
    reports: (
      <ReportList onClick={handleClickReport} adminPreview businessProfileId={businessProfileId} userId={userId} />
    ),
    report: <GenerateReport reportParams={reportParams} adminPreview userId={userId} />,
    businessProfileEdit: (
      <BusinessProfileEdit
        businessProfileData={businessProfile}
        adminPreview
        userId={userId}
        businessProfileId={businessProfileId}
      />
    ),
    localPosts: <AddPost adminPreview userId={userId} businessProfileId={businessProfileId} />,
    reviews: (
      <Opinions adminPreview userId={userId} businessProfileId={businessProfileId} profileImage={profileImage} />
    ),
    businessProfileChangeHistory: (
      <BusinessProfileChangeHistory adminPreview userId={userId} businessProfileId={businessProfileId} />
    ),
    templates: <AnswerTemplates adminPreview userId={userId} businessProfileId={businessProfileId} />,
    media: <MediaView adminPreview userId={userId} businessProfileId={businessProfileId} />,
  };

  return tabComponents[tabKey];
};

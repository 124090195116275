import CategoriesForm from '../../modules/BusinessProfileEdit/CategoriesForm';
import DescriptionForm from '../../modules/BusinessProfileEdit/DescriptionForm';
import NameForm from '../../modules/BusinessProfileEdit/NameForm';
import WebsiteForm from '../../modules/BusinessProfileEdit/WebsiteForm';
import PhoneNumberForm from '../../modules/BusinessProfileEdit/PhoneNumberForm';
import LocationForm from '../../modules/BusinessProfileEdit/LocationForm';
import OpenHoursForm from '../../modules/BusinessProfileEdit/OpenHoursForm';
import OpenDateForm from '../../modules/BusinessProfileEdit/OpenDateForm';
import ServiceAreaForm from '../../modules/BusinessProfileEdit/ServiceAreaForm';
import ServicesForm from '../../modules/BusinessProfileEdit/ServicesForm';
import AdditionalHours from '../../modules/BusinessProfileEdit/AdditionalHours';
import { parseRegularHours } from '../../modules/BusinessProfileEdit/OpenHoursForm/utils';
import Labels from '../../modules/BusinessProfileEdit/Labels';
import { FormStatusEnum } from '../../../../shared/components/FormSuccessIndicator/utils';

export const SectionEnum = {
  generalInformation: 'generalInformation',
  contactDetails: 'contactDetails',
  localization: 'localization',
  openingHours: 'openingHours',
  services: 'services',
  more: 'more',
  media: 'media',
};

export const initialFormState = {
  categories: [],
  location: {},
  areas: [],
  regularHours: [],
  services: [],
  media: {
    actualMedia: {
      mediaItems: [],
      totalMediaItemCount: 0,
    },
    postponedMedia: {
      media: [],
    },
  },
};

export const sections = [
  {
    id: SectionEnum.generalInformation,
    name: 'businessProfileEdit.basicInfo',
    components: [
      {
        key: 'title',
        Form: NameForm,
        label: 'businessProfileEdit.nameCompany',
        suggestions: [
          {
            id: 1,
            message:
              'To istotny element wizytówki, więc zadbaj, aby nazwa odzwierciedlała to, co faktycznie przedstawiasz na szyldzie czy stronie internetowej',
          },
          { id: 2, message: 'Nie używaj słów pisanych tylko wielkimi literami i znaków specjalnych' },
        ],
      },
      {
        key: 'description',
        Form: DescriptionForm,
        label: 'businessProfileEdit.companyDescirption',
        suggestions: [
          {
            id: 3,
            message:
              'Pozwoli Ci wyróżnić się na tle konkurencji. To idealne miejsce do przedstawienia charakteru Twojej działalności czy historii firmy',
          },
          { id: 4, message: 'Możesz zawrzeć informacje na temat oferowanych usług czy produktach' },
          { id: 5, message: 'Pamiętaj, że opis może zawierać maksymalnie 750 znaków i nie może zawierać linków' },
        ],
      },
      {
        key: 'labels',
        Form: Labels,
        label: 'businessProfileEdit.labels',
        suggestions: [],
      },
      {
        key: 'openInfo',
        Form: OpenDateForm,
        label: 'businessProfileEdit.openingDate',
        suggestions: [
          {
            id: 8,
            message:
              'Jeżeli posiadasz zweryfikowany profil firmy, ale jeszcze nie oferujesz swoich usług lub produktów - poinformuj klientów, kiedy zostanie otwarta',
          },
          {
            id: 9,
            message:
              'Natomiast, gdy Twoja firma działa na rynku od dawna, również możesz ją wskazać, tym samym określając swoje wieloletnie doświadczenie.',
          },
        ],
      },
    ],
    success: false,
  },
  {
    id: SectionEnum.contactDetails,
    name: 'businessProfileEdit.contactDetails',
    components: [
      {
        key: 'phoneNumbers',
        Form: PhoneNumberForm,
        label: 'businessProfileEdit.phoneNumbers',
        suggestions: [
          { id: 10, message: 'Wybierz ten, który przypisany jest do Twojej działalności' },
          {
            id: 11,
            message:
              'Jeżeli posiadasz wiele oddziałów - wskaż ten numer, który pozwoli na bezpośredni kontakt z wskazaną lokalizacją',
          },
        ],
      },
      {
        key: 'websiteUri',
        Form: WebsiteForm,
        label: 'businessProfileEdit.website',
        suggestions: [
          { id: 12, message: 'Podaj link do strony lub social media, które opisują Twoją firmę' },
          {
            id: 13,
            message: 'Zadbaj, aby informacje zawarte na stronie były takie same jak na profilu reklamowym Google',
          },
        ],
      },
    ],
    success: true,
  },
  {
    id: SectionEnum.localization,
    name: 'businessProfileEdit.localizationAndArea',
    components: [
      {
        key: 'storefrontAddress',
        Form: LocationForm,
        label: 'businessProfileEdit.localizationAndArea',
        suggestions: [
          { id: 14, message: 'Podaj adres, pod którym fizycznie znajduje się firma' },
          {
            id: 15,
            message:
              'Jeżeli nie przyjmujesz klientów w siedzibie - możesz go ukryć, wskazując tylko obsługiwane obszary',
          },
        ],
      },
      {
        key: 'serviceArea',
        Form: ServiceAreaForm,
        label: 'businessProfileEdit.areaServed',
        suggestions: [
          { id: 16, message: 'Jeżeli oferujesz swoje usługi poza siedzibą firmy - określ obsługiwane obszary' },
          {
            id: 17,
            message:
              'Obszar powinien wskazywać miejsca położone w zasięgu maksymalnie 2 godzin jazdy od fizycznej lokalizacji',
          },
          { id: 18, message: 'W uzasadnionych przypadkach, możesz podać większy obszar' },
        ],
      },
    ],
    success: true,
  },
  {
    id: SectionEnum.openingHours,
    name: 'businessProfileEdit.openingHours',
    components: [
      {
        key: 'regularHours',
        Form: OpenHoursForm,
        label: 'businessProfileEdit.openingHours',
        suggestions: [
          { id: 19, message: 'Podaj stałe godziny, w których obsługujesz klientów' },
          {
            id: 20,
            message:
              'Jeśli przyjmujesz klientów w nieregularnych godzinach - użyj sezonowych godzin otwarcia lub zastosuj je tylko w poszczególne dni',
          },
        ],
      },
      {
        key: 'moreHours',
        Form: AdditionalHours,
        label: 'businessProfileEdit.additionalHours',
        suggestions: [
          {
            id: 21,
            message:
              'Jeżeli w określonych okolicznościach Twoja firma ma inne godziny otwarcia - możesz je tutaj wskazać',
          },
        ],
      },
    ],
    success: true,
  },
  {
    id: SectionEnum.services,
    name: 'businessProfileEdit.services',
    components: [
      {
        key: 'categories',
        Form: CategoriesForm,
        label: 'businessProfileEdit.categories',
        suggestions: [
          {
            id: 6,
            message:
              'Trafnie dobrana główna kategoria pozwoli dotrzeć do potencjalnego klienta, który szuka produktów lub usług, które oferujesz',
          },
          { id: 7, message: 'Możesz dodać kilka dodatkowych kategorii, które również określają Twoją firmę' },
        ],
      },
      { key: 'serviceItems', Form: ServicesForm, label: 'businessProfileEdit.services' },
    ],
    success: true,
  },
];

export const businessProfileDataParser = (businessProfileData, mediaData = []) => {
  const parsedServiceArea = businessProfileData.serviceArea && businessProfileData.serviceArea.length
    ? businessProfileData.serviceArea.map((el) => ({ ...el }))
    : [];
  const parsedAdditionalCategories = businessProfileData.categories.additionalCategories?.length
    ? businessProfileData.categories.additionalCategories
    : [];

  const parsedAdditionalPhoneNumbers = businessProfileData.phoneNumbers.additionalPhones?.length
    ? businessProfileData.phoneNumbers.additionalPhones.map((el, index) => ({ key: `${index}-${el}`, value: el }))
    : [];
  const parsedRegularHours = parseRegularHours(businessProfileData.regularHours || []);
  const openingDate = businessProfileData.openInfo.openingDate || {};
  return {
    ...businessProfileData,
    serviceArea: parsedServiceArea,
    openInfo: { ...businessProfileData.openInfo, openingDate },
    categories: { ...businessProfileData.categories, additionalCategories: parsedAdditionalCategories },
    phoneNumbers: { ...businessProfileData.phoneNumbers, additionalPhones: parsedAdditionalPhoneNumbers },
    regularHours: parsedRegularHours,
    media: businessProfileData.media || mediaData,
  };
};
const { error, success, warning } = FormStatusEnum;

const checkKey = (form, key, checkFunction, status = error) => {
  const isFilled = checkFunction(form[key]);
  return {
    key,
    status: isFilled ? success : status,
  };
};

export const checkOpeningDate = (form, key) => {
  const result = checkKey(form, key, (openInfo) => {
    if (!openInfo) {
      return false;
    }
    const { openingDate } = openInfo;
    return Boolean(openingDate?.day && openingDate?.month && openingDate?.year);
  });
  return result;
};

export const checkMedia = (form, key) => {
  const result = checkKey(form, key, (media) => Boolean(media?.actualMedia?.totalMediaItemCount));
  return result;
};

export const checkFormKey = (form, key) => {
  const result = checkKey(form, key, (formKey) => (typeof formKey === 'object' ? Object.values(formKey).length : formKey),);
  return result;
};

export const checkAdditionalHours = (form, key) => {
  const result = checkKey(form, key, (additionalHours) => Boolean(additionalHours?.moreHours), warning);
  return result;
};

export const checkLabels = (form, key) => {
  const result = checkKey(form, key, (labels) => Boolean(labels?.length), warning);
  return result;
};

export const checkFormStatus = (collection, form) => collection.map(({ key }) => {
  switch (key) {
    case 'openInfo':
      return checkOpeningDate(form, key);
    case 'media':
      return checkMedia(form, key);
    case 'moreHours':
      return checkAdditionalHours(form, key);
    case 'labels':
      return checkLabels(form, key);
    default:
      return checkFormKey(form, key);
  }
});

export const createFormSectionList = () => sections.map(({ id, components }) => ({
  id,
  componentKeyList: components.map(({ key }) => key),
}));

export const createFormSectionListWithFilledStatus = (formSectionList, formFilledStatus) => formSectionList.map(({ id, componentKeyList }) => ({
  id,
  status: !componentKeyList.some((key) => formFilledStatus?.find((item) => item.key === key)?.status === error),
}));

export const checkStatus = (key, collection) => {
  const item = collection?.find((el) => el.key === key || el.id === key);
  if (typeof item?.status === 'boolean') {
    return item.status ? success : error;
  }
  return item?.status;
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import CircleIconText from '../../../../../shared/components/CircleIconText';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as Magnifier } from '../../../../../shared/assets/magnifier.svg';
import { ReactComponent as AddCircle } from '../../../../../shared/assets/addCircle.svg';
import { useGetSubscriptionData } from '../../../../../shared/helpers/hooks';

const PostsEmptyState = ({ createPost, tab, adminPreview }) => {
  const { t } = useTranslation();

  const { isReadOnlySubPlan } = useGetSubscriptionData();
  const userEmptyTitle = isReadOnlySubPlan ? t('posts.noPostsToDisplay') : t('posts.addFirstPost');
  const headerText = tab === 'published' ? userEmptyTitle : t('posts.addFirstPlannedPost');
  const handleCreatePost = () => createPost();
  const subInfomationText = adminPreview && !isReadOnlySubPlan
    ? t('adminControlPanel.noLocalPostsToDisplay')
    : t('posts.additionalInformation');

  return (
    <div className={classes.wrapper}>
      <CircleIconText
        icon={Magnifier}
        heading={headerText}
        supportingText={isReadOnlySubPlan ? t('posts.purchaseSubscriptionForThisFeature') : subInfomationText}
        headingStyle={classes.headingStyle}
      />
      {!adminPreview && !isReadOnlySubPlan && (
        <Button
          onClick={handleCreatePost}
          className={classes.button}
          Icon={AddCircle}
          iconStyle={classes.iconStyle}
          label={t('posts.addPost')}
        />
      )}
    </div>
  );
};

export default PostsEmptyState;

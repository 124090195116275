import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useApi } from '../../../../shared/helpers/api';
import { useAuth } from '../../../../shared/helpers/auth';
import classes from './styles.module.css';
import { externalSections, sections } from '../DashboardSidebar/utils';
import { ReactComponent as ChevronDown } from '../../../../shared/assets/chevronDown.svg';
import { useDashboard } from '../../helpers/dashboard';
import { apiRoutes } from '../../../../shared/helpers/apiRoutes';
import { seoRoutes } from '../../../../shared/helpers/seoRoutes';

const DashboardSidebarSections = ({ onMobileMenuClick, mobileNavVisible }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { clearAuth, isManager } = useAuth();
  const { businessProfile } = useDashboard();
  const { api } = useApi();

  const [activeSubNav, setActiveSubNav] = useState(null);
  const [openSection, setOpenSection] = useState(null);

  const { externalBusinessProfile } = businessProfile;
  const translatedExternalSections = externalSections(t);

  const translatedSections = sections(t).filter(({ route }) => {
    if (isManager) {
      return route !== seoRoutes.subscription;
    }
    return route;
  });

  const sectionsToMap = externalBusinessProfile ? translatedExternalSections : translatedSections;

  const isSelected = (route) => pathname === route;

  const handleLogout = async () => {
    await api.post(apiRoutes.logout, {});
    Object.keys(localStorage)
      .filter((key) => !key.includes('admin'))
      .forEach((key) => localStorage.removeItem(key));
    sessionStorage.clear();
    clearAuth();
  };

  const handleSection = (route, subSectionName) => () => {
    const sectionFound = sectionsToMap.find((el) => el.route === route);

    if (route === '/') {
      handleLogout();
    }
    if (!sectionFound?.expandable && !subSectionName) {
      setOpenSection(null);
    }
    if (sectionFound?.expandable && !subSectionName) {
      setOpenSection((prev) => (prev === route ? null : route));
    }

    setActiveSubNav(subSectionName);
    navigate(route);
    onMobileMenuClick();
  };

  return (
    <div className={clsx(classes.list, mobileNavVisible && classes.listVisible)}>
      {sectionsToMap.map((section) => (
        <div key={section.name}>
          <div
            className={clsx(
              classes.element,
              isSelected(section.route) && classes.selected,
              section.shouldShow === false && classes.hidden,
            )}
            onClick={handleSection(section.route)}
          >
            <section.Icon className={clsx(classes.icon, isSelected(section.route) && classes.selected)} />
            <div className={clsx(classes.sectionName, section.expandable && classes.expandableSectionName)}>
              {section.name}
              {section.expandable && (
                <ChevronDown
                  className={clsx(
                    classes.chevronIcon,
                    openSection === section.route && classes.chevronInverted,
                    isSelected(section.route) && classes.iconColor,
                  )}
                />
              )}
            </div>
          </div>
          {section.expandable
            && openSection === section.route
            && section.childrenName.map((el) => (
              <div
                key={el.name}
                className={clsx(classes.hiddenSection, activeSubNav === el.name && classes.activeSection)}
                onClick={handleSection(el.route, el.name)}
              >
                {el.name}
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default DashboardSidebarSections;

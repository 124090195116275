import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as Arrow } from '../../../../../shared/assets/arrowAlternative.svg';
import { ReactComponent as CircleInfo } from '../../../../../shared/assets/circleInfo.svg';
import { paymentMethodEnum } from '../PaymentMethodItem/utils';

const { recurring } = paymentMethodEnum;

const SubscriptionActions = ({ paymentType, toggleConfirmationModal, subscriptionData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isReccuring = paymentType === recurring;

  const onSinglePaymentSubscriptionDetailsClick = () => {
    const { idForUser } = subscriptionData;
    navigate('/dashboard/settings/payment-history', { state: { idForUser } });
  };

  return (
    <div className={classes.actions}>
      {!isReccuring ? (
        <Button
          label={t('subscription.goToPaymentDetails')}
          className={classes.showPaymentDetailsBtn}
          Icon={Arrow}
          iconStyle={classes.arrowIcon}
          onClick={onSinglePaymentSubscriptionDetailsClick}
        />
      ) : (
        <div className={classes.recurringActionWrapper}>
          <Button
            label={t('subscription.cancelSubscription')}
            className={classes.cancelSubBtn}
            onClick={toggleConfirmationModal}
          />
          <div className={classes.aboutRecurringChange}>
            <CircleInfo className={classes.infoIcon} />
            <p>{t('subscription.aboutChangeRecurringInfo')}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionActions;

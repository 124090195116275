import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../../../../shared/components/List';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { fetchInvoice, paymentListConfig } from './utils';
import classes from './styles.module.css';
import { actionEnum } from '../PaymentHistory/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';

const { getInvoice } = actionEnum;

const PaymentHistoryList = ({ data, onSort, sortKey, sortOrder }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const handleListAction = useCallback((props) => {
    const [_, key, row] = props;
    const { redirectUrl, id } = row;
    const apiPath = '/invoice/getInvoiceUrl';
    if (key.value === getInvoice) {
      fetchInvoice({ id, api, apiPath, showNotification });
    } else {
      window.location.href = redirectUrl;
    }
  }, []);

  if (isLoading) {
    return <LoaderOverlay />;
  }
  return (
    <div>
      <List
        className={classes.list}
        data={data}
        config={paymentListConfig(t)}
        onAction={(...props) => handleListAction(props)}
        sortKey={sortKey}
        sortOrder={sortOrder}
        onSort={onSort}
      />
    </div>
  );
};

export default PaymentHistoryList;

import dayjs from 'dayjs';
import { getDateFromObject } from '../../../../../shared/helpers/strings';
import { ErrorCodes } from '../EventForm/utils';
import { DEFAULT_DATE_FORMAT } from '../../../helpers/constants';
import { TODAY } from '../../../../../shared/helpers/const';

export const parseDateForInput = (post) => {
  if (!post || !post.event || !post.event.schedule || !post.event.schedule.startDate || !post.event.schedule.endDate) {
    return '';
  }
  const { startDate, endDate } = post.event.schedule;
  const formatedStartDate = dayjs(getDateFromObject(startDate)).isValid()
    ? dayjs(getDateFromObject(startDate)).format(DEFAULT_DATE_FORMAT)
    : null;
  const formatedEndDate = dayjs(getDateFromObject(endDate)).isValid()
    ? dayjs(getDateFromObject(endDate)).format(DEFAULT_DATE_FORMAT)
    : null;

  const startEqualEnd = dayjs(formatedStartDate).isSame(formatedEndDate);
  const endDateIsToday = dayjs(formatedEndDate).isSame(TODAY.format(DEFAULT_DATE_FORMAT));
  const isOneDayOffer = startEqualEnd || endDateIsToday;
  return { startDate: formatedStartDate, endDate: formatedEndDate, isOneDayOffer };
};

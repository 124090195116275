import Ajv from 'ajv';

export const urlParamsConfig = {
  type: 'object',
  additionalProperties: true,
  required: ['area', 'net', 'distance', 'businessProfileId', 'keyword'],
  properties: {
    businessProfileId: { type: 'number' },
    keyword: { type: 'string' },
    area: { type: 'number', minimum: 1, maximum: 50, multipleOf: 1 },
    net: { type: 'number', enum: [3, 5, 7, 9] },
    distance: { type: 'number', minimum: 1, maximum: 10, multipleOf: 1 },
    reportId: { type: 'number' },
  },
};

export const dataConfig = {
  required: ['businessProfileId', 'keyword', 'area', 'net', 'distance'],
  additionalProperties: true,
  properties: {
    businessProfileId: { type: 'number' },
    keyword: { type: 'string' },
    area: { type: 'number' },
    net: { type: 'number' },
    distance: { type: 'number' },
    reportId: { type: 'number' },
  },
};

const ajvData = new Ajv({ removeAdditional: false, coerceTypes: true });

const ajvParams = new Ajv({ removeAdditional: true, coerceTypes: false });

const validateParams = ajvParams.compile(urlParamsConfig);

export const validateData = ajvData.compile(dataConfig);

export const parseUrlPrams = (params) => {
  const parsedParams = new URLSearchParams(params).toString();
  const data = Object.fromEntries(Array.from(new URLSearchParams(parsedParams).entries()));
  return validateParams(data) ? data : false;
};

export const USERS_WITH_PERMISSION = ['marcinmarc49@gmail.com'];

export const getBusinessIdFromUrl = (search) => {
  const params = new URLSearchParams(search);
  const businessId = params.get('businessProfileId');
  return Number(businessId);
};

export const reportAvailableInSubPlan = {
  pro: 4,
  trial: 2,
  basic: 2,
};

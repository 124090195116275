import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';
import { pillsArray } from '../../../views/Opinions/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useDashboard } from '../../../helpers/dashboard';
import { useFetch } from '../../../../../shared/helpers/hooks';
import { ReactComponent as Copy } from '../../../../../shared/assets/copy.svg';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const { getReviewsLink: url } = apiRoutes;

const GetMoreOpinionsModal = ({ modalHandler, rwdModalBackTitle }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const {
    businessProfile: { id: businessProfileId },
  } = useDashboard();

  const { responseData } = useFetch({
    url,
    payload: { businessProfileId },
  });

  const link = responseData?.newReviewUri;

  const copyHandler = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      showNotification({ label: t('global.success'), message: t('opinions.linkCopied'), type: 'success' });
      navigator.clipboard.writeText(link);
    } else {
      showNotification({ label: t('global.error'), message: t('opinions.copyError'), type: 'error' });
    }
  };

  return (
    <Modal
      contentStyle={classes.content}
      onCancel={modalHandler}
      modalTitle={t('opinions.getMoreOpinions')}
      rwdBackTitle={rwdModalBackTitle}
    >
      <div className={classes.modalContent}>
        <div className={classes.howItWorks}>{t('opinions.howItWorks')}</div>
        {t('opinions.shareLinkModal')}
        <div className={classes.subInfo}>{t('opinions.shareLinkModalSubInfo')}</div>
      </div>
      <div className={classes.askForOpinion}>
        {t('opinions.askForOpinionVia')}
        <div className={classes.askForOpinionIcons}>
          {pillsArray.map(({ Icon, ...pill }) => (
            <PillWithIcon onClick={() => pill.onClick(link)} className={classes.pill} key={pill.id}>
              {Icon && <Icon stroke={pill.strokeColor} width={pill.iconWidth} height={pill.iconHeight} />}
              {pill.label}
            </PillWithIcon>
          ))}
        </div>
        <div className={classes.row}>
          <Input
            className={classes.webInput}
            readOnly
            labelRised
            label={t('opinions.linkToOpinion')}
            value={responseData?.newReviewUri}
          />
          <Copy onClick={copyHandler} className={classes.copyIcon} />
        </div>
      </div>
    </Modal>
  );
};
export default GetMoreOpinionsModal;

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Filters from '../../modules/ReportList/Filters';
import List from '../../../../shared/components/List';
import { config, getReportFileAndDownload, initialPayload } from './utils';
import Pagination from '../../../../shared/components/Pagination';
import { useApi } from '../../../../shared/helpers/api';
import Button from '../../../../shared/components/Button';
import RWDTable from '../../modules/ReportList/RWDTable';
import { parseAddressToStorefront } from '../../../../shared/helpers/parsers';
import HeaderWithPill from '../../../../shared/components/HeaderWithPill';
import { useDashboard } from '../../helpers/dashboard';
import AvailableReportsSubHeader from '../../modules/ReportList/AvailableReportsSubHeader';
import { useFetch, useGetSubscriptionData } from '../../../../shared/helpers/hooks';
import { adminApiRoutes, apiRoutes } from '../../../../shared/helpers/apiRoutes';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';

const ReportList = ({ className, adminPreview, businessProfileId, userId, onClick }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { api } = useApi();

  const {
    businessProfile: { id },
  } = useDashboard();
  const { isReadOnlySubPlan } = useGetSubscriptionData();

  const [requestPayload, setRequestPayload] = useState(initialPayload(businessProfileId || id));

  const isGenerateReportDisplayed = !isReadOnlySubPlan && !adminPreview;
  const isAvailableReportsVisible = !adminPreview && !isReadOnlySubPlan;

  const { executeRequest: generateReportHandler, responseData: generatedReportData } = useFetch({
    url: apiRoutes.generateReportPdf,
    payload: { businessProfileId: id },
    config: { useCache: false, fetchOnMount: false },
  });

  const { responseData: subscriptionDetails } = useFetch({
    url: apiRoutes.subscriptionByBpId,
    payload: { businessProfileId: id },
    config: { fetchOnMount: !businessProfileId, useCache: false },
  });

  const adminPayload = { ...requestPayload, filters: { businessProfileId }, userId };
  const bodyPayload = adminPreview
    ? adminPayload
    : { ...requestPayload, filters: { ...requestPayload.filters, businessProfileId: id } };

  const {
    responseData: reportList = { total: 0, list: [] },
    isLoading: reportListLoading,
    executeRequest: fetchReportList,
  } = useFetch({
    url: adminPreview ? adminApiRoutes.acpGetReportList : apiRoutes.getReportList,
    payload: bodyPayload,
    config: { useCache: false, fetchOnMount: false },
  });

  const { availableReports } = subscriptionDetails || {};

  const handleSort = (key) => {
    setRequestPayload((prev) => {
      const order = prev.orderBy === key ? prev.order : 'DESC';
      return { ...prev, orderBy: key, order: order === 'ASC' ? 'DESC' : 'ASC' };
    });
  };

  const handlePage = (value) => {
    setRequestPayload((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
  };
  const handleDownload = useCallback(
    async (reportId) => {
      if (adminPreview) return;
      await generateReportHandler({ reportId });
      if (generatedReportData) {
        const { fileName, date } = generatedReportData;
        getReportFileAndDownload({ api, id: reportId, fileName, date });
      }
    },
    [adminPreview, generateReportHandler, generatedReportData, api],
  );

  const handleAction = (key, value, row) => {
    if (key === 'download') {
      handleDownload(row.id);
    }
  };

  const handleClick = useCallback(
    (row) => {
      const { area, distance, net, keyword, address } = row;

      const params = {
        area: area / 1000,
        distance: distance / 1000,
        net,
        reportId: row.id,
        businessProfileId: id || businessProfileId,
        keyword,
      };
      if (address) {
        params.storefrontAddress = parseAddressToStorefront(address);
      }

      const search = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (key === 'storefrontAddress' && value && typeof value === 'object' && !Array.isArray(value)) {
          Object.entries(value).forEach(([subKey, subValue]) => {
            search.set(subKey, subValue);
          });
        } else {
          search.set(key, value);
        }
      });
      if (adminPreview) {
        onClick(search.toString());
      } else {
        navigate({
          pathname: '/report',
          search: search.toString(),
        });
      }
    },
    [navigate, id, businessProfileId, adminPreview, onClick],
  );

  const handleFilters = (filters) => {
    setRequestPayload((prev) => ({ ...prev, offset: 0, filters }));
  };
  const onLimitChange = (value) => {
    setRequestPayload((prev) => ({ ...prev, limit: value, offset: 0 }));
  };
  const navigateToGenerateReport = () => {
    navigate({
      pathname: '/set_filters',
      search: `?businessProfileId=${id}`,
    });
  };

  useEffect(() => {
    fetchReportList(requestPayload, false);
  }, [requestPayload]);

  const page = (() => {
    const { offset, limit } = requestPayload;
    return offset === 0 ? 1 : Math.round(offset / limit) + 1;
  })();

  if (!reportList || reportListLoading) {
    return <LoaderOverlay isAdmin={adminPreview} customStyle={classes.overlay} />;
  }

  return (
    <div className={clsx(classes.wrapper, className, adminPreview && classes.adminPreview)}>
      <div className={classes.header}>
        <HeaderWithPill
          className={classes.header}
          pillLabel={t('reportList.headerPill', { count: reportList?.total })}
          title={t('reportList.header')}
        />
        {isGenerateReportDisplayed && (
          <Button
            onClick={navigateToGenerateReport}
            className={classes.newReportBtn}
            label={t('reportList.generateReport')}
            readOnly={availableReports === 0}
          />
        )}
      </div>

      {isAvailableReportsVisible && (
        <AvailableReportsSubHeader isReadOnlySubPlan={isReadOnlySubPlan} subscriptionDetails={subscriptionDetails} />
      )}
      <div className={classes.row}>
        <Filters adminPreview={adminPreview} onChange={handleFilters} filters={requestPayload.filters} />
      </div>

      <div className={classes.listWrapper}>
        <List
          className={classes.list}
          config={config}
          data={reportList?.list}
          onClick={handleClick}
          onSort={handleSort}
          sortKey={requestPayload.orderBy}
          sortOrder={requestPayload.order}
          onAction={handleAction}
        />
      </div>
      <div className={classes.RWDTable}>
        <RWDTable data={reportList?.list} config={config} onClick={handleClick} />
      </div>
      <Pagination
        className={classes.pagination}
        total={reportList?.total}
        limit={requestPayload.limit}
        page={page}
        onPageChange={handlePage}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

export default ReportList;

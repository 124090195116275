import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import classes from './style.module.css';
import IconWrapper from '../IconWrapper';

const CircleIconText = ({
  icon,
  heading,
  supportingText,
  headingStyle,
  supportingTextStyle,
  supportingTextClickHandler,
}) => {
  const { pathname } = useLocation();
  const isAdmin = pathname.includes('admin');

  return (
    <div className={classes.body}>
      <IconWrapper className={clsx(classes.icon, isAdmin && classes.adminIcon)} Icon={icon} />
      <p className={`${classes.bodyHeading}, ${headingStyle}`}>{heading}</p>
      <div onClick={supportingTextClickHandler} className={clsx(classes.supportingText, supportingTextStyle)}>
        {supportingText}
      </div>
    </div>
  );
};
export default CircleIconText;

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import classes from './styles.module.css';
import CalendarNavigation from '../MonthCalendarNavigation';
import { isSelectedMonth } from './utils';

const generateMonthsName = Array.from({ length: 12 }, (_, i) => ({
  name: dayjs().locale('pl').month(i).format('MMM'),
  id: i,
}));

const MonthList = ({ futureDaysNotAvailable, startDate, endDate, handleClick, client, minDate }) => {
  const [selectedPeriod, setSelectedPeriod] = useState({
    start: { month: null, year: null },
    end: { month: null, year: null },
    year: dayjs().year(),
  });

  const isLaterThanCurrentMonth = (month) => {
    const today = dayjs();
    const currentMonth = today.month();
    const currentYear = today.year();
    const displayedYear = selectedPeriod.year;

    return displayedYear > currentYear || (displayedYear === currentYear && month > currentMonth);
  };

  const isBeforeThanPossibleStartDate = (month) => {
    const today = dayjs();
    const currentMonth = today.month();
    const currentYear = today.year();
    const displayedYear = selectedPeriod.year;

    const availableStartMonth = dayjs(minDate).month();
    const availableStartYear = dayjs(minDate).year();

    return (
      displayedYear < currentYear
      || (displayedYear === currentYear && month + 1 < currentMonth)
      || (displayedYear === availableStartYear && month < availableStartMonth)
    );
  };

  const handleMonthClick = (monthId) => {
    setSelectedPeriod((prev) => {
      if (!prev.start) {
        handleClick(dayjs().month(monthId).year(prev.year).startOf('month'));
        return { ...prev, start: { month: monthId, year: prev.year } };
      }
      if (!prev.end) {
        handleClick(dayjs().month(monthId).year(prev.year).endOf('month'));
        return { ...prev, end: { month: monthId, year: prev.year } };
      }
      handleClick(dayjs().month(monthId).year(prev.year).startOf('month'));
      return { start: { month: monthId, year: prev.year }, end: null, year: prev.year };
    });
  };

  const handleMonthChange = (direction) => {
    if (direction === 'right' && isLaterThanCurrentMonth(dayjs().add(1, 'month').month())) {
      return;
    }
    setSelectedPeriod((prev) => ({
      ...prev,
      year: direction === 'left' ? prev.year - 1 : prev.year + 1,
    }));
  };

  useEffect(() => {
    if (startDate && endDate) {
      const startMonth = { month: dayjs(startDate).month(), year: dayjs(startDate).year() };
      const endMonth = { month: dayjs(endDate).month(), year: dayjs(endDate).year() };
      setSelectedPeriod({ start: startMonth, end: endMonth, year: dayjs(startDate).year() });
    }
  }, [startDate, endDate]);

  return (
    <div>
      <CalendarNavigation
        singleMonth
        period={selectedPeriod.year}
        periodName="year"
        handleMonthChange={handleMonthChange}
      />
      <div className={classes.listWrapper}>
        {generateMonthsName.map(({ id, name }) => (
          <div
            className={clsx(
              classes.month,
              isSelectedMonth(id, selectedPeriod) && classes.selectedMonth,
              isLaterThanCurrentMonth(id) && futureDaysNotAvailable && classes.disabled,
              isBeforeThanPossibleStartDate(id) && futureDaysNotAvailable && classes.disabled,
              client && classes.clientMonth,
            )}
            key={id}
            onClick={() => handleMonthClick(id)}
          >
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthList;

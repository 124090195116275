import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ViewTitle from '../../components/ViewTitle';
import TabNavigator from '../../components/TabNavigator';
import { USER_LIST_TABS, calculateFullName, componentMap } from './utils';
import { useFetchData } from '../../../../shared/helpers/hooks';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';

const UserDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: userData, fetchData, isLoading } = useFetchData();
  const [tabs, setTabs] = useState(USER_LIST_TABS(t, 0));
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const fullName = userData ? calculateFullName(userData) : '';

  const tabClickHandler = (tab) => {
    setActiveTab(tab);
  };
  const handleFetchData = async () => {
    await fetchData('/acp/client/clientDetails', { id: Number(id) });
  };

  useEffect(() => {
    if (id) {
      handleFetchData();
    }
  }, [id]);

  useEffect(() => {
    if (userData) {
      const { businessProfiles, cardTotalNumber } = userData;
      setTabs(USER_LIST_TABS(t, businessProfiles, cardTotalNumber));
    }
  }, [userData]);

  if (isLoading || !userData) {
    return <LoaderOverlay isAdmin />;
  }

  return (
    <div>
      <ViewTitle withArrowBack title={fullName} />
      <TabNavigator tabs={tabs} activeTab={activeTab.label} onTabClick={tabClickHandler} />
      {componentMap(userData, handleFetchData)[activeTab.key]}
    </div>
  );
};

export default UserDetails;

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Textarea from '../../../../../shared/components/Textarea';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { StateEnum } from '../../../../../shared/helpers/state';
import ActionBox from '../ActionBox';
import { urlInDescriptionValidator } from '../../../../../shared/components/Input/validators';
import AIHelper from '../../AddPost/AIHelper';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { useDashboard } from '../../../helpers/dashboard';

const DESCRIPTION_MAX_LENGTH = 750;

const DescriptionForm = ({ className, form, setForm, onError, setState, readOnlyAction, isEdit, editHandler }) => {
  const [description, setDescription] = useState(form.description || '');
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [aiOpen, setAiOpen] = useState(false);

  const {
    businessProfile: { id: businessProfileId },
  } = useDashboard();

  const toggleAiHelper = () => {
    setAiOpen((prev) => !prev);
  };

  const onAiApprove = (content) => {
    setDescription(content);
    setAiOpen(false);
  };

  const handleSave = async () => {
    try {
      setState(StateEnum.loading);
      await api.post('/business_profile/updateBusinessProfileDescription', {
        businessProfileId,
        description,
      });
      showNotification({ label: t('global.success'), message: t('global.saveWithSuccess'), type: 'success' });
      setState(StateEnum.success);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    setForm((prev) => ({ ...prev, description }));
    editHandler();
  };

  const handleCancel = () => {
    setDescription(form.description || '');
    editHandler(null);
  };

  useEffect(() => {
    if (!isEdit) {
      setDescription(form.description);
    }
  }, [isEdit, form.description]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      {isEdit && (
        <div className={clsx(classes.aiWrapper, aiOpen && classes.aiWrapperOpen)}>
          <AIHelper onApprove={onAiApprove} isOpen={aiOpen} onClick={toggleAiHelper} />
        </div>
      )}
      {!aiOpen && (
        <Textarea
          maxLength={DESCRIPTION_MAX_LENGTH}
          className={classes.textarea}
          label={t('businessProfileEdit.companyDescription')}
          onChange={setDescription}
          onError={onError}
          readOnly={!isEdit}
          value={description}
          validator={urlInDescriptionValidator}
          textAreaStyle={clsx(isEdit && classes.textAreaStyle)}
        />
      )}
      {isEdit && !aiOpen && (
        <ActionBox handleCancel={handleCancel} handleSave={handleSave} readOnlyAction={readOnlyAction} />
      )}
    </div>
  );
};

export default DescriptionForm;

import { NOTIFCATION_STATUS_ENUM, NOTIFICATION_TYPE_ENUM } from '../../../views/Notifications/utils';

const { business, opinieo, system } = NOTIFICATION_TYPE_ENUM;
const { notViewed, viewed } = NOTIFCATION_STATUS_ENUM;

export const typeNotificationFilterConifg = (t) => ({
  title: t('notifications.type'),
  options: [
    { label: t('notifications.filters.businessCards'), value: business },
    { label: t('notifications.filters.system'), value: system },
    { label: t('notifications.filters.opinieoCards'), value: opinieo },
  ],
});

export const statusNotificationFilterConifg = (t) => ({
  title: t('notifications.filters.status'),
  options: [
    { label: t('notifications.filters.readed'), value: viewed },
    { label: t('notifications.filters.unreaded'), value: notViewed },
  ],
});

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useDashboard } from '../../../helpers/dashboard';
import Button from '../../../../../shared/components/Button';
import DisconnectAccountModal from '../DisconnectAccountModal';
import { useKeyDown, useModal } from '../../../../../shared/helpers/hooks';
import { handleApiError } from '../ChangePassword/utils';
import { ReactComponent as GoogleIcon } from '../../../../../shared/assets/googleIconColor.svg';
import { useAuth } from '../../../../../shared/helpers/auth';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import ChangePassword from '../ChangePassword';
import Input from '../../../../../shared/components/Input';
import { phoneNumberValidator } from '../../../../../shared/components/Input/validators';
import { phoneNumberTransformer } from '../../../../../shared/components/Input/transformers';
import { disableButton } from './utils';

const MyAccount = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { login, isManager } = useAuth();
  const {
    businessProfile: { id },
  } = useDashboard();
  const { showNotification } = useNotification();
  const [showModal, setShowModal, modalHandler] = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({ accountName: '', pictureUrl: '', email: login });
  const [passwordFormData, setPasswordpasswordformData] = useState({
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  });
  const { accountName, email, pictureUrl, phone = '' } = userDetails;
  const [passwordFormDataIsValid, setPasswordFormDataIsValid] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState(phone.trim());
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const phoneAreSame = phone === userPhoneNumber;

  const isReadOnly = disableButton({
    isPhoneNumberValid,
    passwordFormData,
    passwordFormDataIsValid,
    phone,
    userPhoneNumber,
  });

  const fetchUserDetails = async ({ signal }) => {
    setIsLoading(true);
    try {
      const { data } = await api.post('/auth/accountInfo', { businessProfileId: id }, { signal });
      setUserDetails(data);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  const changePasswordHandler = async () => {
    const controller = new AbortController();
    try {
      const { currentPassword: oldPassword, newPassword: password } = passwordFormData;
      setIsLoading(true);
      await api.post('/auth/changeUserPassword', { newPassword: password, oldPassword }, { signal: controller.signal });
      showNotification({ type: 'success', message: t('settings.passwordChanged') });
      setPasswordpasswordformData({ currentPassword: '', newPassword: '', repeatPassword: '' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
    setIsLoading(false);
  };

  const onChangeHandler = (name) => (value) => {
    setPasswordpasswordformData((prev) => ({ ...prev, [name]: value }));
  };

  const submitFormHandler = async () => {
    const controller = new AbortController();
    const validPasswordProvided = Object.values(passwordFormData).every((el) => el);
    setIsLoading(true);

    try {
      if (validPasswordProvided) {
        await changePasswordHandler();
      }
      if (userPhoneNumber && isPhoneNumberValid && !phoneAreSame) {
        await api.post('/auth/updateUserPhoneNumber', { phone: userPhoneNumber }, { signal: controller.signal });
        showNotification({ type: 'success', message: t('settings.phoneNumberChanged') });
      }
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
      await fetchUserDetails({ signal: controller.signal });
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    if (id) {
      fetchUserDetails({ signal: controller.signal });
    }
    return () => controller.abort();
  }, []);

  useKeyDown({ key: 'Enter', callback: changePasswordHandler, canInvoke: !isReadOnly });

  useEffect(() => {
    if (phone) {
      setUserPhoneNumber(phone);
    }
  }, [phone]);

  if (isLoading) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} />;
  }

  return (
    <div className={classes.container}>
      {showModal && <DisconnectAccountModal onCancel={modalHandler} />}
      <div className={classes.header}>
        <div className={classes.title}>{t('settings.myAccount')}</div>
      </div>
      <div className={classes.body}>
        <div className={classes.accountInfo}>
          <div>
            <div className={classes.subTitle}>{t('settings.userAccount')}</div>
            <div className={classes.accountDetails}>
              {Boolean(pictureUrl) && (
                <div>
                  <img src={pictureUrl} className={classes.userPicture} alt="Business profile" />
                  <GoogleIcon className={classes.googleIcon} />
                </div>
              )}
              <div className={classes.textWrapper}>
                <div className={classes.accountName}>{accountName || t('global.noData')}</div>
                <div className={classes.mail}>{email}</div>
              </div>
            </div>
          </div>
          <div className={classes.links}>
            <div className={classes.linksWrapper}>
              <a href="/politykaprywatnosci" target="_blank" rel="noreferrer">
                {t('settings.privacyPolicy')}
              </a>
              <a href="/regulamin" target="_blank" rel="noreferrer">
                {t('settings.termsOfServiceLink')}
              </a>
            </div>
          </div>
        </div>
        <Input
          showErrorAfterBlur
          className={classes.phoneNumberInput}
          value={userPhoneNumber}
          onChange={(value) => setUserPhoneNumber(value)}
          label={t('settings.phoneNumber')}
          validator={phoneNumberValidator}
          transformer={phoneNumberTransformer}
          onError={(e) => setIsPhoneNumberValid(Boolean(!e))}
        />
        <ChangePassword
          formData={passwordFormData}
          onChangeHandler={onChangeHandler}
          onReadOnlyChange={setPasswordFormDataIsValid}
        />

        <Button
          className={classes.saveBtn}
          label={t('global.save')}
          onClick={submitFormHandler}
          readOnly={isReadOnly}
        />

        <Button
          readOnly={isManager}
          onClick={modalHandler}
          className={classes.disconnectBtn}
          label={t('settings.deleteAccount')}
        />
      </div>
    </div>
  );
};
export default MyAccount;

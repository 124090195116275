import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import {
  actionEnum,
  paymentHistoryFiltersConfig,
  paymentHistoryParser,
} from '../../../../SEO/modules/Settings/PaymentHistory/utils';
import { payloadEnum } from '../../../../SEO/views/AddPost/utils';
import { SORT_DIRECTION_ENUM } from '../../../../../shared/helpers/const';
import { filterChangeHandler } from '../../../../../shared/helpers/helpers';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { getApiPayload } from '../../../../SEO/modules/Settings/SubscriptionSettings/utils';
import List from '../../../../../shared/components/List';
import { adminPaymentListConfig } from './utils';
import AdminPagination from '../../../components/AdminPagination';
import FilterTools from '../../../../SEO/modules/Settings/FilterTools';
import EmptyState from '../../../../SEO/components/EmptyState';
import { fetchInvoice } from '../../../../SEO/modules/Settings/PaymentHistoryList/utils';

const { LIMIT, OFFSET } = payloadEnum;
const { ASC, DESC } = SORT_DIRECTION_ENUM;
const { getInvoice } = actionEnum;

const UserPaymentHistory = ({ userData }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [paymentHistoryData, setPaymentHistoryData] = useState({ data: [], total: 0 });

  const [filters, setFilters] = useState({ limit: 10, offset: 0, search: '' });
  const [order, setOrder] = useState({ column: '', direction: '' });

  const { data, total } = paymentHistoryData;
  const isNoData = !data.length;
  const isFiltersUsed = (filters.subscription || filters.paymentType || filters.status || filters.search) && !isLoading;

  const fetchUserPaymentData = async () => {
    const apiPayload = getApiPayload({ filters, order });
    const { orderBy, ...rest } = apiPayload.filters;
    setIsLoading(true);
    try {
      const {
        data: { results, total: totalResults },
      } = await api.post('/acp/payment/listPayments', {
        user_id: userData.id,
        filters: rest,
        orderBy,
      });
      setPaymentHistoryData({ data: paymentHistoryParser(results, [], true), total: totalResults });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOrderClick = (column) => {
    setOrder((prev) => ({
      column,
      direction: prev.column === column && prev.direction === ASC ? DESC : ASC,
    }));
  };
  const handleListAction = useCallback((props) => {
    const [_, key, row] = props;
    const { id } = row;
    if (key.value === getInvoice) {
      setIsLoading(true);
      try {
        fetchInvoice({ id, api, apiPath: '/acp/invoice/getInvoiceUrl', showNotification });
      } catch (err) {
        handleApiError({ err, showNotification, t });
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const handleFiltersChange = useCallback(filterChangeHandler(setFilters), []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchUserPaymentData({ signal });

    return () => {
      controller.abort();
    };
  }, [filters, order]);

  if (isLoading) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} />;
  }

  return (
    <div className={classes.wrapper}>
      <FilterTools
        admin
        label={t('global.search')}
        subscription={filters.subscription}
        paymentType={filters.paymentType}
        status={filters.status}
        search={filters.search}
        dateFromValue={filters.dateFrom}
        dateToValue={filters.dateTo}
        config={paymentHistoryFiltersConfig}
        onChange={handleFiltersChange}
      />
      {isNoData ? (
        <EmptyState
          admin
          title={t('adminControlPanel.noUserPaymentHistoryData')}
          subTitle={
            isFiltersUsed ? t('adminControlPanel.forSelectedFiltersNoData') : t('adminControlPanel.noSubscriptionData')
          }
        />
      ) : (
        <>
          <List
            className={classes.list}
            config={adminPaymentListConfig}
            data={paymentHistoryData.data}
            headerClassName={classes.listHeader}
            sortOrder={order.direction}
            sortKey={order.column}
            onSort={handleOrderClick}
            onAction={(...props) => handleListAction(props)}
          />
          <AdminPagination
            noExport
            limit={filters.limit}
            total={total}
            offset={filters.offset}
            onLimitChange={handleFiltersChange(LIMIT)}
            onPageChange={handleFiltersChange(OFFSET)}
          />
        </>
      )}
    </div>
  );
};

export default UserPaymentHistory;

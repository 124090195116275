import React from 'react';
import clsx from 'clsx';
import Pill from '../../../../../shared/components/Pill';
import { DATE_FORMAT_DD_MM_YYYY, DATE_FORMAT_FULL_MONTH } from '../../../../SEO/helpers/constants';
import classes from './styles.module.css';
import { formatDate } from '../../../../../shared/helpers/helpers';

export const readOnlyFields = ({ t, data }) => {
  const { lastLogin, activeChange, createdAt, active, activeSubscription } = data;
  const activePillLabel = active ? t('adminControlPanel.active') : t('adminControlPanel.notActive');
  const subscriptionsPillLabel = activeSubscription
    ? t('adminControlPanel.active')
    : t('adminControlPanel.noActiveSubscription');

  return [
    {
      id: 'createdAt',
      label: t('adminControlPanel.accountCreationDate'),
      value: formatDate(createdAt, DATE_FORMAT_FULL_MONTH),
    },
    {
      id: 'lastLogin',
      label: t('adminControlPanel.lastLoggedIn'),
      value: formatDate(lastLogin, DATE_FORMAT_FULL_MONTH),
    },
    {
      id: 'active',
      label: t('adminControlPanel.accountState'),
      value: <Pill className={clsx(classes.pill, !active && classes.noActivePill)} label={activePillLabel} />,
      additional: t('adminControlPanel.lastStatusChangeDate', {
        date: formatDate(activeChange, DATE_FORMAT_DD_MM_YYYY),
      }),
    },
    {
      id: 'subscriptions',
      label: t('adminControlPanel.subscriptions'),
      value: (
        <Pill
          className={clsx(classes.pill, !activeSubscription && classes.noActivePill)}
          label={subscriptionsPillLabel}
        />
      ),
    },
  ];
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ViewTitle from '../../components/ViewTitle';
import TabNavigator from '../../components/TabNavigator';
import { generateDetailsTabConfiguration, calculateFullName, componentMap } from './utils';
import AdminLoader from '../../components/AdminLoader';
import classes from './styles.module.css';
import { useFetchData } from '../../../../shared/helpers/hooks';

const ManagerDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: managerData, fetchData, isLoading } = useFetchData();

  const [tabs, setTabs] = useState(generateDetailsTabConfiguration(t, 0));
  const [activeTab, setActiveTab] = useState(tabs.length > 0 ? tabs[0] : null);

  const fullName = managerData ? calculateFullName(managerData) : '';

  const tabClickHandler = (tab) => {
    setActiveTab(tab);
  };

  const handleFetchData = async () => {
    await fetchData('/acp/client/managerDetails', { id: Number(id) });
  };
  useEffect(() => {
    if (id) {
      const numericId = Number(id);
      if (!Number.isNaN(numericId)) {
        fetchData('/acp/client/managerDetails', { id: numericId });
      }
    }
  }, [id]);

  useEffect(() => {
    if (managerData) {
      const { businessProfiles, cardTotalNumber } = managerData;
      setTabs(generateDetailsTabConfiguration(t, businessProfiles, cardTotalNumber));
    }
  }, [managerData]);

  if (isLoading || !managerData) {
    return (
      <div className={classes.loaderWrapper}>
        <AdminLoader />
      </div>
    );
  }

  return (
    <div>
      <ViewTitle withArrowBack title={fullName} />
      <TabNavigator tabs={tabs} activeTab={activeTab.label} onTabClick={tabClickHandler} />
      {componentMap(managerData, handleFetchData)[activeTab.key]}
    </div>
  );
};

export default ManagerDetails;

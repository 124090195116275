import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import StatisticBox from '../StatisticBox';
import { transformStatisticData } from './utils';
import StatisticBoxes from '../StatisticBoxes';
import { PROFILE_STATE_ENUM } from '../../../components/DashboardLayout/utils';

const Statistics = ({
  status,
  data = [],
  searchDataDetails = [],
  additionalInteractions = [],
  onChartToDisplayChange,
}) => {
  const { t } = useTranslation();
  const [moreSearchDetailsOn, setMoreSearchDetailsOn] = useState(false);
  const [moreInteractionDetailsOn, setMoreInteractionDetailsOn] = useState(false);
  const { verified, processing } = PROFILE_STATE_ENUM;
  const { statisticBoxesWithData, searchDetailBoxesWithData, interactionBoxesWithData } = useMemo(
    () => transformStatisticData({ data, searchDataDetails, additionalInteractions }),
    [data, searchDataDetails, additionalInteractions],
  );
  const VIEWS_NUMBER = t('dashboard.viewsNumber');
  const INTERACTIONS_NUMBER = t('dashboard.interactionsNumber');
  const shouldRenderMoreDetails = [verified, processing].includes(status);

  const toggleMoreDetails = useCallback(
    (clickedBoxTitle) => {
      const boxesWithDetails = [VIEWS_NUMBER, INTERACTIONS_NUMBER];
      if (!shouldRenderMoreDetails || !boxesWithDetails.includes(clickedBoxTitle)) {
        return;
      }

      onChartToDisplayChange(clickedBoxTitle);
      if (clickedBoxTitle === VIEWS_NUMBER) {
        setMoreInteractionDetailsOn(false);
        setMoreSearchDetailsOn((prevState) => !prevState);
      }
      if (clickedBoxTitle === INTERACTIONS_NUMBER) {
        setMoreSearchDetailsOn(false);
        setMoreInteractionDetailsOn((prevState) => !prevState);
      }
    },
    [status, onChartToDisplayChange, VIEWS_NUMBER, INTERACTIONS_NUMBER],
  );

  return (
    <>
      <div className={clsx(classes.wrapper, status !== verified && classes.noData)}>
        {statisticBoxesWithData.map((box) => (
          <StatisticBox
            key={box.id}
            Icon={box.Icon}
            toolTipTitle={t(box.toolTipTitle)}
            comparison={box.comparison}
            title={t(box.title)}
            value={box.value}
            tooltipText={t(box.toolTipText)}
            status={status}
            onClick={toggleMoreDetails}
          />
        ))}
      </div>
      {moreSearchDetailsOn && (
        <div className={classes.searchDataWrapper}>
          <StatisticBoxes statisticData={searchDetailBoxesWithData} status={status} />
        </div>
      )}
      {moreInteractionDetailsOn && (
        <div className={classes.searchDataWrapper}>
          <StatisticBoxes statisticData={interactionBoxesWithData.slice(0, 4)} status={status} />
        </div>
      )}
    </>
  );
};
export default Statistics;

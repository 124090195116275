import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { EXTERNAL_ADDRESSES } from '../../../../../shared/helpers/const';
import { ReactComponent as InfoIcon } from '../../../../../shared/assets/circleInfo.svg';

const SubscriptionSinglePaymentInfo = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.singlePaymentInfo}>
      <div className={classes.leftSideWrapper}>
        <InfoIcon className={classes.infoIcon} />
      </div>
      <div className={classes.rightSideWrapper}>
        <div>{t('subscription.singlePaymentInfoTitle')}</div>
        <div className={classes.rowContainer}>
          <a type="tel" href={`tel:${EXTERNAL_ADDRESSES.PHONE_NUMBER}`}>
            {EXTERNAL_ADDRESSES.PHONE_NUMBER}
          </a>
          <div>{EXTERNAL_ADDRESSES.EMAIL}</div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSinglePaymentInfo;

import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import { useNotification } from '../../helpers/notification';

const Day = ({
  date,
  selectedStartDate,
  selectedEndDate,
  handleDayClick,
  futureDaysNotAvailable,
  admin,
  minDate,
  maxDate,
  messageForDateAfterMaxDate,
}) => {
  const { showNotification } = useNotification();
  const today = dayjs();
  const isFutureDay = date.isAfter(today, 'day');
  const isPastDay = Boolean(minDate) && date.isBefore(minDate, 'day');
  const isSelectedDay = date.isSame(selectedStartDate, 'day') || date.isSame(selectedEndDate, 'day');
  const isBetweenDays = date.isAfter(selectedStartDate, 'day') && date.isBefore(selectedEndDate, 'day');
  const isToday = date.isSame(today, 'day');
  const displayFutureDay = (futureDaysNotAvailable && isFutureDay) || (Boolean(minDate) && isPastDay);
  const isBeyondMaxDate = Boolean(maxDate) && date.isAfter(maxDate, 'day');

  const dayClassName = clsx(
    classes.day,
    isSelectedDay && classes.selectedDay,
    isBetweenDays && classes.betweenDays,
    isToday && classes.today,
    displayFutureDay && classes.futureDayNotAvailable,
    isBeyondMaxDate && classes.futureDayNotAvailable,
    admin && classes.admin,
  );

  const handleClick = () => {
    if (isBeyondMaxDate) {
      showNotification({ type: 'error', message: messageForDateAfterMaxDate, bodyClassName: classes.notification });
    }
    if ((!futureDaysNotAvailable || !isFutureDay) && !isPastDay && !isBeyondMaxDate) {
      handleDayClick(date);
    }
  };

  return (
    <div className={dayClassName} onClick={handleClick}>
      {date.date()}
    </div>
  );
};

export default Day;

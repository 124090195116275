import React from 'react';
import clsx from 'clsx';
import i18next from 'i18next';
import classes from './styles.module.css';
import { capitalizeFirstLetter } from '../../../../../shared/components/Input/transformers';
import { renderPaidDetails } from '../../../../SEO/modules/Settings/PaymentHistoryList/utils';
import Pill from '../../../../../shared/components/Pill';

const renderStatus = ({ value }) => (
  <Pill
    label={i18next.t(`adminControlPanel.${value.toLowerCase()}`)}
    className={clsx(classes.pill, classes[value.toLowerCase()])}
  />
);
export const adminPaymentListConfig = [
  {
    key: 'order_date',
    sort: 'order_date',
    header: 'adminControlPanel.orderDate',
    render: (val) => val,
    width: 1,
    rowClassName: classes.cell,
    bold: true,
  },

  {
    key: 'id',
    header: 'adminControlPanel.orderNo',
    render: (val) => val,
    width: 1,
    rowClassName: classes.cell,
    bold: true,
  },
  {
    key: 'subscription',
    sort: 'subscription',
    header: 'adminControlPanel.plan',
    render: capitalizeFirstLetter,
    width: 1,
    rowClassName: clsx(classes.subscriptionName, classes.cell),
    bold: true,
  },
  {
    key: 'amount',
    sort: 'amount',
    render: (val) => `${val / 100} PLN`,
    header: 'adminControlPanel.amount',
    width: 1,
    bold: true,
    rowClassName: classes.cell,
  },
  {
    key: 'paidBy',
    header: 'adminControlPanel.payment',
    render: renderPaidDetails,
    width: 1,
    rowClassName: classes.cell,
  },
  {
    key: 'status',
    render: renderStatus,
    header: 'settings.status',
    width: 1,
  },
  {
    key: 'action',
    action: true,
    width: 0.25,
    render: (value) => (value?.Icon ? <div className={classes.iconWrapper}>{value.Icon}</div> : value),
    rowClassName: classes.actionCell,
  },
];

import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { activePillConfig } from '../../../views/AdministratorList/utils';

const ExpandedUserRow = ({ userData }) => {
  const { t } = useTranslation();

  const { businessProfiles, status = true, cardTotalNumber, activeSubscriptions } = userData;

  return (
    <tr id={classes.expandedUserRowWrapper} className={clsx(classes.wrapper, classes.expandedUserRowWrapper)}>
      <td className={classes.cell}>
        <span className={classes.label}>{t('adminControlPanel.businessCardsNumber')}</span>
        <span className={classes.value}>{businessProfiles}</span>
      </td>
      <td className={classes.cell}>
        <span className={classes.label}>{t('adminControlPanel.activeSubscriptionsNumber')}</span>
        <span className={classes.value}>{activeSubscriptions || 0}</span>
      </td>
      <td className={classes.cell}>
        <span className={classes.label}>{t('adminControlPanel.opinieoCardsNumber')}</span>
        <span className={classes.value}>{cardTotalNumber}</span>
      </td>
      <td className={classes.cell}>
        <span className={classes.label}>{t('adminControlPanel.accountState')}</span>
        <Pill className={clsx(classes.pill, status && classes.activeStatusPill)} label={activePillConfig(status, t)} />
      </td>
    </tr>
  );
};

export default ExpandedUserRow;

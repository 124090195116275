import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { parseStyle } from './utils';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';

const List = ({
  className,
  data,
  config,
  sortKey,
  sortOrder,
  headerClassName,
  onSort = () => {},
  onAction = () => {},
  onClick = () => {},
}) => {
  const { t } = useTranslation();

  const parsedConfig = useMemo(
    () => config.map((el) => {
      const style = parseStyle(el.width, el.bold);
      return {
        ...el,
        renderHeader: typeof el.header === 'function' ? el.header : () => t(el.header),
        renderCell: typeof el.render === 'function' ? el.render : (val) => val,
        style,
      };
    }),
    [data, config],
  );

  const handleSort = (key) => () => {
    if (key) {
      onSort(key);
    }
  };

  const handleCell = (cellConfig, row) => (e) => {
    if (cellConfig.action) {
      onAction(cellConfig.key, row[cellConfig.key], row, data);
      e.stopPropagation();
    }
  };

  const handleClick = (row) => () => {
    onClick(row);
  };

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.header}>
        {parsedConfig.map((el) => (
          <div
            className={clsx(classes.headerCell, headerClassName)}
            key={el.key}
            style={{ flex: el.style.flex }}
            onClick={handleSort(el.sort)}
          >
            <div className={classes.title}>{el.renderHeader(data)}</div>
            {el.key === sortKey && (
              <div className={clsx(classes.sortIcon, sortOrder === 'ASC' && classes.rotated)}>
                <SortIcon height="10px" />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={classes.list}>
        {data.map((row) => (
          <div className={classes.row} key={`list_row_${row.id}`} onClick={handleClick(row)}>
            {parsedConfig.map((el) => (
              <div
                key={`list_row_${row.key}_${el.key}_${row.createdAt}`}
                className={clsx(classes.cell, el.rowClassName)}
                style={el.style}
                onClick={handleCell(el, row)}
              >
                {el.renderCell(row[el.key], row, data)}
              </div>
            ))}
          </div>
        ))}
        {!data.length && <div className={classes.noData}>{t('global.noData')}</div>}
      </div>
    </div>
  );
};

export default List;

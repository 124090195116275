import { ReactComponent as CheckIcon } from '../../../../../shared/assets/check.svg';
import { ReactComponent as Duplicate } from '../../../../../shared/assets/copy.svg';
import { ReactComponent as Suspended } from '../../../../../shared/assets/clock.svg';
import { ReactComponent as NotVerfied } from '../../../../../shared/assets/notVerified.svg';

export const statusConfig = (t, adminPreview) => ({
  verified: {
    status: t('dashboard.verified'),
    statusWithLowerCase: t('dashboard.verified').toLowerCase(),
    Icon: CheckIcon,
    subInfo: t('dashboard.verifiedSubInfo'),
  },
  notVerified: {
    status: t('dashboard.notVerified'),
    statusWithLowerCase: t('dashboard.notVerified').toLowerCase(),
    Icon: NotVerfied,
    subInfo: adminPreview ? t('adminControlPanel.notVerifiedSubInfoAdmin') : t('dashboard.notVerifiedSubInfo'),
  },
  pending: {
    status: t('dashboard.pending'),
  },
  duplicate: {
    status: t('dashboard.duplicate'),
    statusWithLowerCase: t('dashboard.duplicate').toLowerCase(),
    Icon: Duplicate,
    subInfo: t('dashboard.duplicateSubInfo'),
  },
  processing: {
    status: t('settings.processing'),
    statusWithLowerCase: t('dashboard.processingStatus').toLowerCase(),
    Icon: Suspended,
    subInfo: t('dashboard.processingSubinfo'),
  },
  suspended: {
    status: t('dashboard.suspended'),
    statusWithLowerCase: t('dashboard.suspended').toLowerCase(),
    Icon: Suspended,
    subInfo: adminPreview ? t('adminControlPanel.suspendedSubInfoAdmin') : t('dashboard.suspendedSubInfo'),
  },
});

export const SUSPENDED_PROFILE_ACTION_URL = 'https://support.google.com/business/troubleshooter/2690129';

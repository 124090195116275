import React, { useCallback, useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import SearchInput from '../../../../../shared/components/SearchInput';
import Button from '../../../../../shared/components/Button';
import Modal from '../../../../../shared/components/Modal';
import Calendar from '../../../../../shared/components/Calendar';
import { useModal } from '../../../../../shared/helpers/hooks';
import { debounce } from '../../../../../shared/helpers/debounce';
import { getFiltersCount, renderFilterOptions } from './utils';
import { ReactComponent as FilterIcon } from '../../../../../shared/assets/filter.svg';

const MIN_SEARCH_LENGTH = 3;

const FilterTools = memo(
  ({ label, onChange, config, subscription, paymentType, status, search, dateFromValue, dateToValue, admin }) => {
    const { t } = useTranslation();

    const [selectedFilters, setSelectedFilters] = useState({
      subscription: [],
      paymentType: [],
      status: [],
    });

    const [isFiltersModalOpen, , toggleFilters] = useModal();
    const [isCalendarOpen, , toggleCalendar] = useModal();

    const isReadOnlyFilterBtn = Object.values(selectedFilters).every((filter) => !filter?.length);
    const totalSelectedFilters = getFiltersCount(selectedFilters);
    const filtersBtnLabel = `${t('settings.filters')} ${totalSelectedFilters ? `(${totalSelectedFilters})` : ''}`;

    const debouncedSearch = useCallback(
      debounce((value) => {
        if (!value) {
          onChange('search')('');
          return;
        }
        if (value.length >= MIN_SEARCH_LENGTH) {
          onChange('search')(value);
        }
      }, 500),
      [onChange],
    );

    const handleFiltersClick = (option) => {
      const { value, filterKey } = option;

      setSelectedFilters((prev) => {
        const currentValues = prev[filterKey] || [];

        return currentValues.includes(value)
          ? { ...prev, [filterKey]: currentValues.filter((item) => item !== value) }
          : { ...prev, [filterKey]: [...currentValues, value] };
      });
    };

    const onFilterClick = () => {
      Object.entries(selectedFilters).forEach(([key, value]) => {
        onChange(key)(value?.length ? value : null);
      });
      toggleFilters();
    };

    const resetClickHandler = () => {
      setSelectedFilters({});
      Object.keys(selectedFilters).forEach((key) => onChange(key)(null));
      toggleFilters();
    };

    const handleFiltersChange = (value) => {
      const { dateFrom, dateTo } = value;
      setSelectedFilters((prev) => ({
        ...prev,
        dateFrom,
        dateTo,
      }));
      toggleCalendar();
    };

    const onCalendarClose = () => {
      setSelectedFilters((prev) => ({
        ...prev,
        dateFrom: '',
        dateTo: '',
      }));
      toggleCalendar();
    };

    useEffect(() => {
      if (subscription || paymentType || status || dateFromValue || dateToValue) {
        setSelectedFilters({
          subscription: subscription || [],
          paymentType: paymentType || [],
          status: status || [],
          dateFrom: dateFromValue || null,
          dateTo: dateToValue || null,
        });
      }
    }, [subscription, paymentType, status, dateFromValue, dateToValue]);

    return (
      <div className={clsx(classes.toolsWrapper, admin && classes.adminToolsWrapper)}>
        <SearchInput
          className={clsx(classes.searchInput, admin && classes.adminSearchInput)}
          label={label || t('settings.searchTransaction')}
          onSearch={debouncedSearch}
          value={search}
        />
        <Button
          className={clsx(classes.filterButton, admin && classes.adminBtn)}
          label={filtersBtnLabel}
          Icon={FilterIcon}
          onClick={toggleFilters}
        />
        {isFiltersModalOpen && (
          <Modal
            backdropLocked={isCalendarOpen}
            contentStyle={classes.modalContent}
            onCancel={toggleFilters}
            modalTitle={t('settings.filters')}
          >
            {renderFilterOptions({ admin, config, selectedFilters, toggleCalendar, handleFiltersClick })}
            <div className={classes.actionsWrapper}>
              <Button
                className={clsx(classes.resetBtn, admin && classes.adminResetBtn)}
                onClick={resetClickHandler}
                label={t('settings.reset')}
              />
              <Button
                readOnly={isReadOnlyFilterBtn}
                className={clsx(classes.filterBtn, admin && classes.adminFilterBtn)}
                onClick={onFilterClick}
                label={t('settings.filter')}
              />
            </div>
          </Modal>
        )}
        {isCalendarOpen && (
          <Calendar
            admin={admin}
            selectedDate={selectedFilters.dateFrom}
            selectedEndPeriodDate={selectedFilters.dateTo}
            quickSelect
            onClose={onCalendarClose}
            onChange={handleFiltersChange}
          />
        )}
      </div>
    );
  },
);

export default FilterTools;

import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import { useModal } from '../../../../../shared/helpers/hooks';
import CancelSubscriptionModalConfirmation from '../CancelSubscriptionModalConfirmation';
import SubscriptionPlanInfo from '../SubscriptionPlanInfo';
import SubscriptionSummary from '../SubscriptionSummary';
import SubscriptionSinglePaymentInfo from '../SubscriptionSinglePaymentInfo';
import { DATE_FORMAT_DD_MM_YYYY } from '../../../helpers/constants';
import SubscriptionActions from '../SubscriptionActions';
import { getDisplayCondition } from './utils';
import { ReactComponent as CircleInfo } from '../../../../../shared/assets/circleInfo.svg';

const SubscriptionDetails = ({ refetchFn, subscriptionData, isActive }) => {
  const { t } = useTranslation();
  const { subscription, subscriptionDate, paymentType, status } = subscriptionData;
  const [isOpenConfirmationModal, setIsOpenConfirmationModal, toggleConfirmationModal] = useModal();
  const { isSinglePaymentInformationDisplayed } = getDisplayCondition(subscription, paymentType);

  const isTrial = subscription === 'trial';

  const onCancelModalClose = async () => {
    await refetchFn();
    toggleConfirmationModal();
  };

  return (
    <div className={classes.wrapper}>
      {isOpenConfirmationModal && (
        <CancelSubscriptionModalConfirmation
          title={t('subscription.cancelSubscriptionModalTitle')}
          onClose={onCancelModalClose}
          expireDate={dayjs(subscriptionDate).format(DATE_FORMAT_DD_MM_YYYY)}
          refetchFn={refetchFn}
        />
      )}
      <SubscriptionPlanInfo subscription={subscription} paymentType={paymentType} />
      <SubscriptionSummary subscriptionData={subscriptionData} subscriptionStatus={status} />
      {isSinglePaymentInformationDisplayed && <SubscriptionSinglePaymentInfo />}
      {!isTrial && isActive ? (
        <SubscriptionActions
          paymentType={paymentType}
          toggleConfirmationModal={toggleConfirmationModal}
          subscriptionData={subscriptionData}
        />
      ) : (
        <div className={classes.aboutRecurringChange}>
          <CircleInfo className={classes.infoIcon} />
          <p>{t('subscription.purchaseAvailbableAfterExpire')}</p>
        </div>
      )}
    </div>
  );
};

export default SubscriptionDetails;

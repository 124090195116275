import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PurchaseFormBusinessProfileDetails from '../PurchaseFormBusinessProfileDetials';
import classes from './styles.module.css';
import Checkbox from '../../../../../shared/components/Checkbox';
import Button from '../../../../../shared/components/Button';
import BuyerData from '../BuyerData';
import PruchaseSummaryPaymentDetails from '../PurchaseSummaryPaymentDetails';
import { getOfferDetails, termsOfServiceLabel } from './utils';
import { POLISH_CURRENCY, subscriptionsConfig } from '../../../helpers/constants';
import { paymentMethodEnum } from '../PaymentMethodItem/utils';
import { capitalizeFirstLetter } from '../../../../../shared/components/Input/transformers';

const PurchaseSummary = ({ onRecurringForm, submitFormHandler, onDataChange, payload }) => {
  const { t } = useTranslation();

  const [buttonReadOnly, setButtonReadOnly] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState({ type: null });
  const [consetForRecurringPayment, setConsetForRecurringPayment] = useState(false);

  const isTrial = payload?.subscription.toLowerCase() === subscriptionsConfig.trial;
  const isReccuring = paymentDetails?.type === paymentMethodEnum.recurring;

  const btnLabel = () => {
    if (isTrial) {
      return t('subscription.activateTrial');
    }
    if (isReccuring) {
      return t('subscription.proccedToCheckout');
    }
    return t('settings.goToPayment');
  };

  const handleCheckboxClick = (name) => (value) => {
    onDataChange(name)(value);
  };
  const onSummaryButtonClick = () => (isReccuring ? onRecurringForm() : submitFormHandler());

  const handleInvoiceChang = (invoiceId) => {
    onDataChange('invoiceDetailsId')(invoiceId);
  };

  useEffect(() => {
    const isConsentsChecked = payload.consentPrivacyPolicy && payload.consentToProvideService && (!isReccuring || consetForRecurringPayment);
    const selectedOffer = { title: payload.subscription, type: payload.paymentType };

    setButtonReadOnly(!isConsentsChecked || (isTrial ? false : !payload.invoiceDetailsId));
    setPaymentDetails(getOfferDetails({ isTrial, selectedOffer }));
  }, [payload, isTrial, consetForRecurringPayment]);

  return (
    <div className={classes.wrapper}>
      <PurchaseFormBusinessProfileDetails />
      <PruchaseSummaryPaymentDetails selectedPlan={payload?.subscription} paymentDetails={paymentDetails} />
      {!isTrial && (
        <div className={classes.buyerDataWrapper}>
          <BuyerData onChange={handleInvoiceChang} />
        </div>
      )}
      <div className={classes.priceWrapper}>
        <div className={classes.price}>{t('subscription.priceToPay')}</div>
        <div className={classes.price}>
          {`${paymentDetails.price} ${POLISH_CURRENCY}`}
          {!isTrial && (
            <>
              <span className={classes.pricePeriod}>{t(paymentDetails.priceTagShort)}</span>
              <div className={classes.taxIncluded}>{t('settings.taxIncluded')}</div>
            </>
          )}
        </div>
      </div>
      <div className={classes.consentWrapper}>
        {isReccuring && <p className={classes.cyclicSubInfo}>{t('subscription.cyclicPaymentSubinfo')}</p>}

        {isReccuring && (
          <Checkbox
            checkboxStyle={clsx(classes.checkboxStyle)}
            checkIconStyle={classes.checkIconStyle}
            className={clsx(classes.checkbox)}
            label={t('subscription.reccuringPaymentChecboxLabel', {
              plan: capitalizeFirstLetter(payload.subscription),
            })}
            labelPosition="right"
            onClick={() => setConsetForRecurringPayment((prev) => !prev)}
            value={consetForRecurringPayment}
          />
        )}
        <Checkbox
          checkboxStyle={clsx(classes.checkboxStyle)}
          checkIconStyle={classes.checkIconStyle}
          className={clsx(classes.checkbox)}
          label={termsOfServiceLabel(t)}
          labelPosition="right"
          onClick={handleCheckboxClick('consentPrivacyPolicy')}
          value={payload.consentPrivacyPolicy}
        />
        <Checkbox
          checkboxStyle={clsx(classes.checkboxStyle)}
          checkIconStyle={classes.checkIconStyle}
          className={clsx(classes.checkbox)}
          label={t('settings.consentToStartServiceBefore14Days')}
          labelPosition="right"
          onClick={handleCheckboxClick('consentToProvideService')}
          value={payload.consentToProvideService}
        />
      </div>
      <div className={classes.actionsWrapper}>
        <Button readOnly={buttonReadOnly} label={btnLabel()} className={classes.btn} onClick={onSummaryButtonClick} />
      </div>
    </div>
  );
};

export default PurchaseSummary;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './style.module.css';
import FiltersDropDown from '../../../../../shared/components/FiltersDropDown';
import { ReactComponent as QuestionMark } from '../../../../../shared/assets/questionMark.svg';
import Modal from '../../../../../shared/components/Modal';
import ExpandableSection from '../../../../../shared/components/ExpandableSection';
import FiltersDescriptionModal from '../FiltersModal';

const SelectBusinessFilters = ({ filters, handleFiltersChange, focusedKey, setFocusedKey, subscription, readOnly }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modalHandler = () => setShowModal((prev) => !prev);
  return (
    <>
      {showModal && (
        <Modal
          contentStyle={classes.modalContentStyle}
          modalTitle={t('setFilters.mapProperties')}
          onCancel={modalHandler}
        >
          <FiltersDescriptionModal />
        </Modal>
      )}
      <div className={classes.wrapper}>
        <div>
          <div className={classes.sectionName}>
            {`3. ${t('setFilters.mapProperties')}`}
            <QuestionMark onClick={modalHandler} className={classes.questionMarkInfo} />
          </div>
        </div>
        <FiltersDropDown
          filters={filters}
          handleFiltersChange={handleFiltersChange}
          focusedKey={focusedKey}
          setFocusedKey={setFocusedKey}
          subscription={subscription}
          readOnly={readOnly}
        />
        <ExpandableSection
          wrapperStyle={classes.expandableWrapper}
          sectionNameStyle={classes.expandableSectionName}
          sectionName={t('setFilters.whatAreMapProperties')}
        >
          <FiltersDescriptionModal />
        </ExpandableSection>
      </div>
    </>
  );
};

export default SelectBusinessFilters;

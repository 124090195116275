import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import SingleLinkWrapper from '../../../../../shared/components/SingleLinkWrapper';
import Calendar from '../../../../../shared/components/Calendar';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as CalendarIcon } from '../../../../../shared/assets/calendar.svg';
import { getSelectDateLabel } from './utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const CardStatistic = ({ cardData }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [links, setLinks] = useState(cardData.links);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const btnLabel = getSelectDateLabel(selectedDate, t);

  const handleDateChangeFetchData = async ({ dateFrom, dateTo }) => {
    setIsLoading(true);
    try {
      const { data } = await api.post('/acp/card/linkList', {
        filters: {
          cardId: cardData.id,
          dateFrom,
          dateTo,
        },
      });
      setLinks(data.results);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const toggleCalendar = () => {
    setShowCalendar((prev) => !prev);
  };

  const onDateChangeHandler = (value) => {
    setSelectedDate(value);
    handleDateChangeFetchData({ dateFrom: value.dateFrom, dateTo: value.dateTo });
    toggleCalendar();
  };

  const onCloseCalendar = () => {
    setSelectedDate(null);
    handleDateChangeFetchData({});
    toggleCalendar();
  };

  return (
    <div className={classes.wrapper}>
      {isLoading && <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />}
      <div>
        <Button
          label={btnLabel}
          Icon={CalendarIcon}
          className={classes.datePickerBtn}
          onClick={toggleCalendar}
          iconStyle={classes.calendarIconStyle}
        />
      </div>
      {showCalendar && (
        <Calendar
          admin
          quickSelect
          minDate={cardData?.createdAt}
          onClose={onCloseCalendar}
          onChange={onDateChangeHandler}
        />
      )}
      <div className={classes.list}>
        {links.map((link) => (
          <SingleLinkWrapper key={link.id} link={link} cardData={cardData} />
        ))}
      </div>
    </div>
  );
};

export default CardStatistic;

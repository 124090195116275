import React, { useContext } from 'react';

const defaultAuth = {
  isAuth: null,
  isAdmin: null,
  isManager: null,
  setAuth: ({ isAuth, login, id, businessProfiles, isAdmin, isManager }) => {
    throw 'Auth context is not initialized';
  },
  clearAuth: () => {
    throw 'Auth context is not initialized';
  },
  login: null,
  businessProfiles: null,
  id: null,
};

export const AuthContext = React.createContext(defaultAuth);

export const useAuth = () => useContext(AuthContext);

export const getStorageTokens = () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  const adminToken = localStorage.getItem('adminToken');
  const refreshAdminToken = localStorage.getItem('refreshAdminToken');
  return { token, refreshToken, adminToken, refreshAdminToken };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import Tooltip from '../../../../../shared/components/Tooltip';
import KeywordItem from '../KeywordItem';
import { PROFILE_STATUS_ENUM } from '../../../helpers/enums';
import { ReactComponent as CircleInfo } from '../../../../../shared/assets/circleInfo.svg';
import { useDashboard } from '../../../helpers/dashboard';
import { subscriptionsConfig } from '../../../helpers/constants';

const BusinessKeywords = ({ keywords, maxValue, keywordModalHandler, profileStatus }) => {
  const { t } = useTranslation();
  const {
    businessProfile: { subscription },
  } = useDashboard();
  const isFreeSubPlan = subscription === subscriptionsConfig.free;
  const { verified, processing } = PROFILE_STATUS_ENUM;
  const boxKeywords = keywords.length ? keywords.slice(0, 4) : [];
  const shouldShowMore = keywords.length > 4 && !isFreeSubPlan;
  const isBusinessNotVerified = ![verified, processing].includes(profileStatus);

  return (
    <div className={clsx(classes.keywords, isBusinessNotVerified && classes.noData)}>
      <div className={classes.cardHeader}>
        <div className={classes.title}>
          {t('dashboard.businessKeywords')}
          {!isBusinessNotVerified && (
            <Tooltip
              tooltipStyle={classes.tooltipStyle}
              text={t('dashboard.keywordTooltip')}
              toolTipTitle={t('dashboard.keywordToolTipTitle')}
            >
              <CircleInfo className={classes.infoIcon} />
            </Tooltip>
          )}
        </div>
      </div>
      <div className={classes.keywordsList}>
        {boxKeywords.length ? (
          boxKeywords.map((keyword) => <KeywordItem keyword={keyword} maxValue={maxValue} key={keyword.keyword} />)
        ) : (
          <div className={classes.noKeywords}>{t('dashboard.noKeywords')}</div>
        )}
      </div>
      {shouldShowMore && (
        <Button label={t('dashboard.showMoreKeywords')} onClick={keywordModalHandler} className={classes.keywordBtn} />
      )}
    </div>
  );
};
export default BusinessKeywords;

import React from 'react';
import i18next from 'i18next';
import classes from './styles.module.css';
import { formatDate } from '../../../../../shared/helpers/helpers';

const propertyNameConfig = {
  name: 'name',
  address: 'address',
  availableReports: 'availableReports',
  cardTotalNumber: 'cardTotalNumber',
};

export const sortConfig = {
  sortName: propertyNameConfig.name,
  sortReports: 'available_reports',
  sortCardsNumber: 'card_total_number',
};
const { name, address, availableReports, cardTotalNumber } = propertyNameConfig;
const { sortCardsNumber, sortName, sortReports } = sortConfig;

export const managerBusinessCardTableColumnsConfig = (t) => [
  { header: t('adminControlPanel.businessProfile'), property: name, sort: sortName, width: '50%' },
  { header: t('adminControlPanel.businessCardAddress'), property: address, width: '50%' },
  {
    header: t('adminControlPanel.availableReports'),
    property: availableReports,
    sort: sortReports,
    width: '30%',
  },
  { header: t('adminControlPanel.opinieoCards'), property: cardTotalNumber, sort: sortCardsNumber, width: '30%' },
];

export const renderBussinessCardDetails = (businessProfile) => {
  const { subscriptionBuyDate, subscriptionDate, subscriptionStatus } = businessProfile;

  return (
    <div className={classes.aboutSubscriptionRow}>
      <div>
        {i18next.t('subscription.activationDate')}
        <div>{formatDate(subscriptionBuyDate)}</div>
      </div>
      <div>
        {subscriptionStatus ? i18next.t('settings.nextPaymentDate') : i18next.t('subscription.expireDate')}
        <div>{formatDate(subscriptionDate)}</div>
      </div>
    </div>
  );
};

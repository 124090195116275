import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { isButtonDisabled, formInputEnum } from './utils';
import {
  getConfirmationPasswordErrorMessage,
  getErrorMessage,
  passwordErrorsConfig,
} from '../../../views/SetPassword/utils';
import SubInformation from '../../../../../shared/components/SubInformation';
import Checkbox from '../../../../../shared/components/Checkbox';

const ChangePassword = ({ formData, onChangeHandler, onReadOnlyChange }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirmationBlurred, setPasswordConfirmationBlurred] = useState(false);

  const { currentPassword, newPassword, repeatPassword } = formInputEnum;

  const errConfirmationPasswordMessage = getConfirmationPasswordErrorMessage({
    password: formData.newPassword,
    passwordConfirmation: formData.repeatPassword,
    passwordConfirmationBlurred,
  });

  const errMessage = getErrorMessage({
    password: formData.newPassword,
    passwordConfirmation: formData.repeatPassword,
    passwordConfirmationBlurred,
  });

  const confirmationPasswordBlurHandler = (val) => {
    setPasswordConfirmationBlurred(val);
  };

  const passwordHepler = t(errMessage.message) === t(passwordErrorsConfig.passwordIsOk)
    ? t(errConfirmationPasswordMessage.message)
    : t(errMessage.message);

  useEffect(() => {
    onReadOnlyChange(!isButtonDisabled(errMessage, errConfirmationPasswordMessage, formData, passwordErrorsConfig));
  }, [errMessage, errConfirmationPasswordMessage, formData]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.subTitle}>{t('global.password')}</div>
      </div>

      <form className={classes.form}>
        <Input
          type="password"
          showErrorAfterBlur
          label={t('settings.currentPassword')}
          onChange={onChangeHandler(currentPassword)}
          value={formData.currentPassword}
        />
        <Input
          type={showPassword ? 'text' : 'password'}
          label={t('settings.newPasword')}
          onChange={onChangeHandler(newPassword)}
          showErrorAfterBlur
          value={formData.newPassword}
        />
        <Input
          onBlur={() => confirmationPasswordBlurHandler(true)}
          onFocus={() => confirmationPasswordBlurHandler(false)}
          type={showPassword ? 'text' : 'password'}
          label={t('settings.repeatPassword')}
          onChange={onChangeHandler(repeatPassword)}
          error={errMessage.errorPasswordConfirmation}
          showErrorAfterBlur
          value={formData.repeatPassword}
        />
        <SubInformation label={passwordHepler} className={classes.passwordHelper} />
        <Checkbox
          checkboxStyle={classes.checkboxStyle}
          checkIconStyle={classes.checkIconStyle}
          onClick={() => setShowPassword((prev) => !prev)}
          labelPosition="right"
          className={classes.showPassword}
          label={t('setPassword.showPassword')}
        />
      </form>
    </div>
  );
};
export default ChangePassword;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import classes from './styles.module.css';
import PaymentDataItem from '../PaymentDataItem';

const PaymentDataModal = ({ onClose, userId }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [userPaymentData, setUserPaymentData] = useState(null);

  const fetchUserPaymentData = async () => {
    try {
      const {
        data: { userInvoiceDetails },
      } = await api.post('/acp/invoice/getUserInvoiceDetails', { userId });
      setUserPaymentData(userInvoiceDetails);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };

  useEffect(() => {
    fetchUserPaymentData();
  }, []);

  if (!userPaymentData) return null;

  return (
    <Modal contentStyle={classes.contentStyle} onCancel={onClose} modalTitle={t('adminControlPanel.paymentData')}>
      <div className={classes.dataList}>
        {userPaymentData.map((paymentData) => (
          <PaymentDataItem data={paymentData} />
        ))}
      </div>
    </Modal>
  );
};

export default PaymentDataModal;

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../shared/components/Button';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import classes from './styles.module.css';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';

const SubscriptionToggler = ({ subscriptionStatus, onSuccess = async () => {}, id }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [loading, setLoading] = useState(false);

  const addSubscriptionToManager = async () => {
    try {
      setLoading(true);
      await api.post('/acp/subscription/addSubscription', { userId: id });
      await onSuccess();
      showNotification({ type: 'success', message: t('adminControlPanel.subscriptionAdded') });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setLoading(false);
    }
  };

  const removeSubscriptionFromManager = async () => {
    try {
      setLoading(true);
      await api.post('/acp/subscription/deleteSubscription', { userId: id });
      await onSuccess();
      showNotification({ type: 'success', message: t('adminControlPanel.subscriptionRemoved') });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setLoading(false);
    }
  };

  return ['ACTIVE'].includes(subscriptionStatus) ? (
    <Button
      className={classes.actionBtn}
      label={t('adminControlPanel.removeSubscription')}
      onClick={removeSubscriptionFromManager}
    />
  ) : (
    <Button
      className={classes.addSubBtn}
      label={t('adminControlPanel.addSubscription')}
      onClick={addSubscriptionToManager}
    />
  );
};

export default SubscriptionToggler;

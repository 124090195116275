import React from 'react';
import classes from './styles.module.css';

const Link = ({ href, target, refPolicy, rel, label }) => (
  <a className={classes.linkStyle} href={href} target={target} referrerPolicy={refPolicy} rel={rel}>
    {label}
  </a>
);

export default Link;

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Gallery from '../../../../../shared/components/Gallery';
import Checkbox from '../../../../../shared/components/Checkbox';
import PostponedMediaItem from '../../BusinessProfileEdit/PostponedMediaItem';
import { ReactComponent as CloseIcon } from '../../../../../shared/assets/close.svg';
import VideoPlayer from '../../../../../shared/components/VideoPlayer';
import { useGetSubscriptionData, useModal } from '../../../../../shared/helpers/hooks';
import ConfirmDeleteModal from '../../BusinessProfileEdit/ConfirmDeleteModal';
import Button from '../../../../../shared/components/Button';
import { MEDIA_ENUM } from '../AddMediaModal/utils';
import { useDashboard } from '../../../helpers/dashboard';

const MediaItem = ({
  item,
  onDelete,
  isEdit,
  galleryCollection,
  onSelect,
  selectedItems,
  onSuccess,
  index,
  changeCategoryItemType,
  adminPreview,
}) => {
  const { t } = useTranslation();
  const [fullSize, setFullSize] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal, toggleDeleteModal] = useModal();

  const { name, isImage, thumbnailUrl, googleUrl, publishDate } = item;
  const category = item.category || item.locationAssociation.category;
  const source = !category === MEDIA_ENUM.PROFILE ? googleUrl : thumbnailUrl;

  const isCover = category === MEDIA_ENUM.COVER;
  const isProfile = category === MEDIA_ENUM.PROFILE;
  const { isReadOnlySubPlan } = useGetSubscriptionData();

  const changeTypeLabel = adminPreview || isReadOnlySubPlan;

  const handleOnClick = (e) => {
    e.stopPropagation();
    setFullSize((prev) => !prev);
    setCurrentImage(index);
  };

  const closeGalleryHandler = () => {
    setFullSize(false);
  };

  const onNext = (event) => {
    event.stopPropagation();
    setCurrentImage((prev) => (prev + 1) % galleryCollection.length);
  };

  const onPrev = (event) => {
    event.stopPropagation();
    setCurrentImage((prev) => (prev - 1 + galleryCollection.length) % galleryCollection.length);
  };

  const changeCategoryHandler = (e) => {
    if (adminPreview || isReadOnlySubPlan) {
      return;
    }
    changeCategoryItemType(e, item);
  };

  const renderSpecificButton = () => {
    if (isCover) {
      return (
        <Button
          label={t(`media.${changeTypeLabel ? 'cover' : 'changeCover'}`)}
          className={classes.changeCoverBtn}
          onClick={changeCategoryHandler}
        />
      );
    }
    if (isProfile) {
      return (
        <Button
          label={t(`media.${changeTypeLabel ? 'profile' : 'changeProfile'}`)}
          className={classes.changeCoverBtn}
          onClick={changeCategoryHandler}
        />
      );
    }
    return null;
  };
  useEffect(() => {
    setIsSelected(selectedItems.includes(item));
  }, [selectedItems.length]);

  return (
    <>
      <div className={classes.content}>
        {confirmDeleteModal && (
          <ConfirmDeleteModal
            onClose={toggleDeleteModal}
            onConfirm={(e) => {
              onDelete(e, item);
              setConfirmDeleteModal(false);
            }}
          />
        )}
        {publishDate ? (
          <PostponedMediaItem
            onItemClick={handleOnClick}
            item={item}
            isSelected={isSelected}
            isEdit={isEdit}
            onSuccess={onSuccess}
          />
        ) : (
          <div onClick={handleOnClick} className={clsx(classes.userImage, isSelected && classes.borderSelected)}>
            {isImage ? (
              <>
                {renderSpecificButton()}
                <img
                  className={clsx(classes.userImage, isSelected && classes.borderSelected)}
                  key={name}
                  src={source}
                  alt="business profile media"
                />
              </>
            ) : (
              <VideoPlayer
                videoUrl={item.sourceUrl}
                className={clsx(classes.userVideo, isSelected && classes.borderSelected)}
              />
            )}
          </div>
        )}

        {!changeTypeLabel && (
          <div className={classes.itemActions}>
            <Checkbox
              value={isSelected}
              onClick={() => onSelect(item)}
              checkboxStyle={classes.checkboxStyle}
              className={classes.checkbox}
            />
            <CloseIcon onClick={toggleDeleteModal} className={classes.closeIcon} />
          </div>
        )}
      </div>
      {fullSize && (
        <Gallery
          media={galleryCollection}
          onGalleryClose={closeGalleryHandler}
          currentImage={currentImage}
          onNext={onNext}
          onPrev={onPrev}
        />
      )}
    </>
  );
};
export default MediaItem;

export const apiRoutes = {
  login: '/auth/login',
  signUp: '/auth/signUp',
  logout: '/auth/logout',
  changePassword: '/auth/changePassword',
  getVerificationStatus: '/business_profile/getVerificationStatus',
  getReviews: '/review/getReviews',
  detachBusinessProfile: '/business_profile/detachBusinessProfiles',
  getBusinessProfileChangeHistory: '/business_profile/getBusinessProfileChangeHistory',
  getReviewsLink: '/review/getReviewsLink',
  deleteReviewReply: '/review/deleteReply',
  answerWithTemplate: '/review/postReviewsTemplate',
  verifyBusinessProfile: '/business_profile/verifyBusinessProfile',
  generateReportPdf: '/report/generateReportPdf',
  getReportList: '/report/getReportList',
  subscriptionByBpId: '/subscription/subscriptionByBpId',
  getBusinessProfile: '/business_profile/getBusinessProfile',
  getMediaList: '/media/mediaList',
  updateBusinessProfileTitle: '/business_profile/updateBusinessProfileTitle',
};

export const adminApiRoutes = {
  acpGetReviews: 'acp/client/getReviews',
  acpGetReportList: '/acp/client/getReportList',
  acpGetBusinessProfileChangeHistory: '/acp/client/getBusinessProfileChangeHistory',
};

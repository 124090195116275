export const seoRoutes = {
  importProfiles: '/pick_business_profiles',
  login: '/login',
  setPassword: '/set_password',
  dashboard: '/dashboard',
  createBusinessProfile: '/dashboard/createBusinessProfile',
  settings: '/dashboard/settings',
  opinieoCards: '/dashboard/cards',
  reportList: '/dashboard/report_list',
  subscription: '/dashboard/subscription',
};

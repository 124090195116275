import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { hourValidator, plannedPostValidator } from '../../../../../shared/components/Input/validators';
import { formEnum } from '../ModalForm/utils/utils';
import { useDashboard } from '../../../helpers/dashboard';
import { DATE_FORMAT_DD_MM_YYYY, DEFAULT_DATE_FORMAT } from '../../../helpers/constants';
import DateInput from '../../../../../shared/components/DateInput';
import { getCorrectDate } from './utils';
import { hourTransformer } from '../../../../../shared/components/Input/transformers';
import { ONE_YEAR_LATER, TODAY } from '../../../../../shared/helpers/const';
import { paymentMethodEnum } from '../../Subscription/PaymentMethodItem/utils';
import { useAuth } from '../../../../../shared/helpers/auth';
import { LOCAL_POST_FIELD_ENUM, SUBSCRIPTION_STATUS_ENUM } from '../../../../../shared/helpers/enums';

const { DATE, TIME } = LOCAL_POST_FIELD_ENUM;
const PlannedPost = ({
  onPublicationDateChange,
  data = { type: null, publishDate: null, localPost: null },
  media = false,
  plannedPublishDate,
  onError,
}) => {
  const { t } = useTranslation();
  const { isManager } = useAuth();
  const {
    businessProfile: { subscriptionDate, paymentType, status: subStaus },
  } = useDashboard();
  const { publishDate, type, localPost } = data;
  const isFormWithDateInput = [formEnum.EVENT, formEnum.OFFER].includes(type);
  const [plannedPublicationDate, setPlannedPublicationDate] = useState({ date: '', time: '09:00' });
  const [plannedDateError, setPlannedDateError] = useState(false);
  const isCancelled = subStaus === SUBSCRIPTION_STATUS_ENUM.CANCELED;
  const maxDate = (paymentType !== paymentMethodEnum.recurring || isCancelled) && !isManager
    ? dayjs(subscriptionDate)
    : ONE_YEAR_LATER;

  const formatDate = (date) => dayjs(date, 'YYYY-MM-DD');

  const getEndDate = () => {
    const endDateArray = localPost?.event?.schedule?.endDate;
    return endDateArray ? Object.values(endDateArray).join('-') : null;
  };

  const isDateAfterOrSame = (date1, date2) => {
    const formattedDate1 = dayjs(date1);
    const formattedDate2 = formatDate(date2);
    return formattedDate1.isAfter(formattedDate2) || formattedDate1.isSame(formattedDate2);
  };

  const isPlannedAfterEnd = isFormWithDateInput && isDateAfterOrSame(plannedPublicationDate.date, getEndDate(localPost));
  const displayLocalPostError = isFormWithDateInput && isPlannedAfterEnd && !media;
  const displayUpdatePostError = !isFormWithDateInput && plannedDateError;

  const onDateChange = (name) => (value) => {
    if (name === TIME) {
      const timeIsNotValid = hourValidator(value) || value.length !== 5;
      onError(timeIsNotValid);
    }
    if (name === DATE && !value.date.length) {
      setPlannedPublicationDate((prev) => ({ ...prev, [name]: null }));
      return;
    }
    if (name === DATE) {
      setPlannedPublicationDate((prev) => ({ ...prev, [name]: dayjs(value.date).format(DATE_FORMAT_DD_MM_YYYY) }));
      return;
    }
    if (name === TIME && value?.length > 4) {
      setPlannedPublicationDate((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validateAndSetPublicationDate = () => {
    const { date, time } = plannedPublicationDate;
    const fullDate = `${getCorrectDate(date)} ${time}`;
    const dayjsFullDateIsValid = dayjs(fullDate).isValid();
    if (dayjsFullDateIsValid) {
      onPublicationDateChange(fullDate);
    } else {
      onPublicationDateChange(null);
    }
    setPlannedDateError(plannedPostValidator(publishDate));
  };

  const updatePlannedPublicationDate = () => {
    const plannedPublicationDateValue = publishDate || plannedPublishDate;
    if (plannedPublicationDateValue && dayjs(plannedPublicationDateValue).isValid()) {
      const correctDate = dayjs(plannedPublicationDateValue).format(DEFAULT_DATE_FORMAT);
      const correctTime = dayjs(plannedPublicationDateValue).format('HH:mm');
      setPlannedPublicationDate({ date: correctDate, time: correctTime });
    }
  };

  useEffect(validateAndSetPublicationDate, [plannedPublicationDate, publishDate]);
  useEffect(updatePlannedPublicationDate, [publishDate, media, plannedPublishDate]);

  useEffect(() => {
    if (media) {
      onError(false);
    }
  }, []);

  return (
    <div>
      <div className={classes.plannedBox}>
        <DateInput
          onChange={onDateChange(DATE)}
          placeholder={t('posts.publicationDateOptional')}
          className={classes.date}
          label={t('posts.publicationDate')}
          minDate={TODAY}
          maxDate={maxDate}
          dateFrom={plannedPublicationDate.date}
          value={
            dayjs(plannedPublicationDate.date).isValid()
              ? dayjs(plannedPublicationDate.date).format(DATE_FORMAT_DD_MM_YYYY)
              : ''
          }
          messageForDateAfterMaxDate={t('posts.publicationDateBeforeSubExpire', {
            date: dayjs(subscriptionDate).format(DATE_FORMAT_DD_MM_YYYY),
          })}
        />

        {plannedPublicationDate.date && (
          <Input
            label={t('posts.publicationTime')}
            autoComplete="off"
            className={classes.timeInput}
            value={plannedPublicationDate.time}
            transformer={hourTransformer}
            validator={hourValidator}
            showErrorAfterBlur
            onChange={onDateChange(TIME)}
          />
        )}
      </div>
      {Boolean(displayLocalPostError) && <div className={classes.error}>{t('posts.publicationDateAfterEndDate')}</div>}
      {Boolean(displayUpdatePostError || plannedDateError) && (
        <div className={classes.error}>{t('businessProfileEdit.postoponedMediaMustBeAfterToday')}</div>
      )}
    </div>
  );
};
export default PlannedPost;

export const PAID_BY_ENUM = {
  BLIK: 'blik',
  CARD: 'card',
  BANK_ACCOUNT: 'bank_account',
};

export const SUBSCRIPTION_STATUS_ENUM = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
};

export const PAYLOAD_KEY_ENUM = {
  SEARCH: 'search',
  LIMIT: 'limit',
  OFFSET: 'offset',
  ORDER_BY: 'orderBy',
  ORDER: 'order',
};

export const LOCAL_POST_FIELD_ENUM = {
  TIME: 'time',
  DATE: 'date',
};

import React, { act } from 'react';
import clsx from 'clsx';
import i18next from 'i18next';
import Checkbox from '../../../../shared/components/Checkbox';
import { DATE_FORMAT_DD_MM_YYYY_HH_MM } from '../../helpers/constants';
import classes from './styles.module.css';
import Pill from '../../../../shared/components/Pill';
import { formatDate } from '../../../../shared/helpers/helpers';
import { ReactComponent as Logo } from '../../../../shared/assets/logoIcon.svg';
import { ReactComponent as CircleInfo } from '../../../../shared/assets/circleInfo.svg';
import { ReactComponent as Card } from '../../../../shared/assets/credit-card.svg';

export const FILTERS_ENUM = {
  businessProfileId: 'businessProfileId',
  status: 'status',
  type: 'type',
};

export const NOTIFICATION_TYPE_ENUM = {
  business: 'BUSINESS_PROFILE',
  system: 'SYSTEM',
  opinieo: 'OPINIEO',
};

export const NOTIFCATION_STATUS_ENUM = {
  viewed: 'VIEWED',
  notViewed: 'NOT_VIEWED',
};

export const NOTIFICATION_ORDER_COLUMN_ENUM = {
  date: 'date',
};

export const getBusinessProfileBasicData = (list, businessProfileId, withImage = false) => {
  const foundBusinessProfile = list.find((profile) => profile.id === businessProfileId);

  if (withImage) {
    return foundBusinessProfile
      ? { name: foundBusinessProfile.name, address: foundBusinessProfile.address, image: foundBusinessProfile.image }
      : '';
  }
  return foundBusinessProfile ? { name: foundBusinessProfile.name, address: foundBusinessProfile.address } : '';
};

export const getParsedNotificationData = (data, businessProfiles) => data.map((item) => ({
  ...item,
  isSelected: false,
  date: {
    date: formatDate(item.date, DATE_FORMAT_DD_MM_YYYY_HH_MM),
    isNew: item.status === NOTIFCATION_STATUS_ENUM.notViewed,
  },
  connectedBusinessProfile: getBusinessProfileBasicData(businessProfiles, item.businessProfileId),
}));

export const renderDateWithStatus = (date) => {
  const { date: dateValue, isNew } = date;

  return (
    <div className={classes.dateWrapper}>
      <span>{dateValue}</span>
      {isNew && <span className={classes.notViewedDot} />}
    </div>
  );
};
export const typeEnum = {
  [NOTIFICATION_TYPE_ENUM.business]: 'notifications.business',
  [NOTIFICATION_TYPE_ENUM.system]: 'notifications.system',
  [NOTIFICATION_TYPE_ENUM.opinieo]: 'notifications.opinieo',
};

export const iconTypeEnum = {
  [NOTIFICATION_TYPE_ENUM.business]: () => <Logo className={classes.iconType} />,
  [NOTIFICATION_TYPE_ENUM.system]: () => <CircleInfo className={classes.iconType} />,
  [NOTIFICATION_TYPE_ENUM.opinieo]: () => <Card className={classes.iconType} />,
};

export const renderTypePill = (type) => (
  <Pill className={clsx(classes.pill, classes[type.toLowerCase()])} label={i18next.t(typeEnum[type])} />
);

const renderConnectedBusinessProfile = (businessProfile) => {
  const { name, address } = businessProfile;

  if (!name || !address) {
    return '-';
  }
  return (
    <div className={classes.connectedBusinessProfile}>
      <span>{name}</span>
      <span>{address}</span>
    </div>
  );
};

export const notificationListConfig = (t, selectAllHandler, selectedItems) => [
  {
    key: 'isSelected',
    header: () => (
      <Checkbox className={classes.checkbox} checkboxStyle={classes.checkboxStyle} onClick={selectAllHandler} />
    ),
    render: (val, row) => (
      <Checkbox
        className={classes.checkbox}
        checkboxStyle={classes.checkboxStyle}
        value={selectedItems.includes(row.id)}
        onClick={() => selectAllHandler(row.id)}
      />
    ),
    width: 0.1,
    action: true,
  },
  {
    key: 'date',
    header: 'notifications.date',
    render: renderDateWithStatus,
    width: 0.75,
    bold: true,
  },
  {
    key: 'connectedBusinessProfile',
    header: 'notifications.connectedBusinessProfile',
    render: renderConnectedBusinessProfile,
    width: 1.25,
  },
  {
    key: 'content',
    header: t('notifications.content'),
    render: (val) => t(val),
    width: 1.25,
  },
  {
    key: 'type',
    header: 'notifications.type',
    render: renderTypePill,
    width: 0.75,
  },
];

import React from 'react';
import clsx from 'clsx';
import Pill from '../../../../shared/components/Pill';
import classes from './styles.module.css';
import { formatDate } from '../../../../shared/helpers/helpers';

export const subscriptionStatusConfig = {
  ACTIVE: 'adminControlPanel.active',
  CANCELED: 'adminControlPanel.canceled',
  INACTIVE: 'adminControlPanel.inactive',
};

export const activePillConfig = (active, t) => {
  if (typeof active === 'boolean') {
    return active ? t('adminControlPanel.active') : t('adminControlPanel.notActive');
  }
  const activePillLabel = subscriptionStatusConfig[active];
  return activePillLabel ? t(activePillLabel) : t('adminControlPanel.notActive');
};

export const columns = (t) => [
  { header: t('adminControlPanel.lastName'), property: 'lastName', sort: 'last_name', width: '60%' },
  { header: t('adminControlPanel.name'), property: 'name', width: '80%' },
  { header: t('adminControlPanel.email'), property: 'login', sort: 'login', width: '200%' },
  { header: t('adminControlPanel.accountCreated'), property: 'createdAt', width: '80%' },
  { header: t('adminControlPanel.lastLogin'), property: 'lastLogin', width: '90%' },
  { header: t('adminControlPanel.status'), property: 'active', width: '90%' },
];
export const MIN_SEARCH_LENGTH = 3;

export const parseUserDataList = (data, t) => data.map((item) => ({
  key: item.id,
  id: item.id,
  lastName: item.lastName,
  name: item.name,
  login: item.login,
  createdAt: formatDate(item.createdAt),
  lastLogin: formatDate(item.lastLogin),
  active: (
    <Pill
      className={clsx(classes.pill, item.active && classes.activeStatusPill)}
      label={activePillConfig(item.active, t)}
    />
  ),
}));

export const FILTERS_ENUM = {
  LIMIT: 'limit',
  OFFSET: 'offset',
  SEARCH: 'search',
  ACTIVE: 'active',
};

export const SORT_COLUMNS = {
  id: 'id',
  login: 'login',
  name: 'name',
  lastName: 'lastName',
  createdAt: 'created_at',
  active: 'active',
  roleType: 'role_type',
  lastLogin: 'last_login',
  activeChangedAt: 'active_change_at',
};

export const activeListCollection = (t) => [
  { id: 1, name: t('adminControlPanel.all'), value: null },
  { id: 2, name: t('adminControlPanel.active'), value: true },
  { id: 3, name: t('adminControlPanel.notActive'), value: false },
];

import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';

const RecordRow = ({ title, data, recordKey }) => {
  const { t } = useTranslation();
  const value = data[recordKey] || t('adminControlPanel.noData');

  return (
    <div key={recordKey}>
      <div className={classes.dataTitle}>{title}</div>
      <div className={classes.dataValue}>{value}</div>
    </div>
  );
};

export default RecordRow;

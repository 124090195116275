import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import Calendar from '../Calendar';
import { ReactComponent as CalendarIcon } from '../../../../../shared/assets/calendar.svg';
import { PROFILE_STATE_ENUM } from '../../../components/DashboardLayout/utils';
import { useGetSubscriptionData } from '../../../../../shared/helpers/hooks';

const Header = ({ onPeriodSelect, status, period }) => {
  const { t } = useTranslation();

  const [openCalendar, setOpenCalendar] = useState(false);

  const { isReadOnlySubPlan } = useGetSubscriptionData();

  const isVerified = [PROFILE_STATE_ENUM.verified, PROFILE_STATE_ENUM.processing].includes(status);
  const isPeriodSelected = period !== t('dashboard.last30Days');
  const isChandePeriodBtnRendered = isVerified && !isReadOnlySubPlan;

  const toggleCalendar = () => {
    setOpenCalendar((prev) => !prev);
  };

  return (
    <div className={clsx(classes.wrapper, isPeriodSelected && classes.flexDirCol)}>
      <div className={classes.header}>{t('dashboard.dashboard')}</div>
      <div className={classes.actions}>
        {isChandePeriodBtnRendered && (
        <Button
          onClick={toggleCalendar}
          Icon={CalendarIcon}
          iconStyle={classes.calendarIcon}
          className={classes.openCalendar}
          label={period}
        />
        )}
        {openCalendar && <Calendar onCancel={toggleCalendar} onPeriodChange={onPeriodSelect} />}
      </div>
    </div>
  );
};

export default Header;

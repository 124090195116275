import React from 'react';
import classes from './styles.module.css';
import { ReactComponent as InfoIcon } from '../../assets/circleInfo.svg';

const TextHelper = ({ messaage }) => (
  <div className={classes.passwordHelper}>
    <InfoIcon className={classes.infoIcon} />
    <p>{messaage}</p>
  </div>
);

export default TextHelper;

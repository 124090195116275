import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useAuth } from '../../../../../shared/helpers/auth';
import List from '../../../../../shared/components/List';
import Pagination from '../../../../../shared/components/Pagination';
import {
  DEFAULT_FILTERS,
  getApiPayload,
  parseDataToListColumns,
  SUBSCRIPTION_ACTION_KEY_ENUM,
  subscriptionFilterConfig,
  subscriptionListConfig,
} from './utils';
import { handleApiError } from '../ChangePassword/utils';
import EmptyState from '../../../components/EmptyState';
import { payloadEnum } from '../../../views/AddPost/utils';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { useDashboard } from '../../../helpers/dashboard';
import FilterTools from '../FilterTools';
import { SORT_DIRECTION_ENUM } from '../../../../../shared/helpers/const';
import Link from '../../../../../shared/components/Link';
import { filterChangeHandler } from '../../../../../shared/helpers/helpers';

const { ASC, DESC } = SORT_DIRECTION_ENUM;
const { LIMIT, OFFSET } = payloadEnum;
const { MANAGE } = SUBSCRIPTION_ACTION_KEY_ENUM;

const SubscriptionSettings = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { selectBusinessProfile } = useDashboard();
  const { id } = useAuth();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [subscriptions, setSubscriptions] = useState({ data: [], totalRecords: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS, userId: id });
  const [order, setOrder] = useState({ column: '', direction: '' });

  const { data, totalRecords } = subscriptions;
  const isFiltersUsed = (filters.subscription || filters.paymentType || filters.status || filters.search) && !isLoading;
  const isNoData = !isLoading && !data.length && !error;

  const handleListAction = useCallback(
    ([action, , row]) => {
      if (action === MANAGE) {
        selectBusinessProfile(row.bpId);
        navigate('/dashboard/subscription');
      }
    },
    [selectBusinessProfile, navigate],
  );

  const handleOrderClick = (column) => {
    setOrder((prev) => ({
      column,
      direction: prev.column === column && prev.direction === ASC ? DESC : ASC,
    }));
  };

  const updateSubscriptions = ({ results, total }) => {
    setSubscriptions({ data: parseDataToListColumns(results), totalRecords: total });
  };

  const fetchSubscriptions = async (controller) => {
    const payload = getApiPayload({ filters, order });
    try {
      const { data: apiData } = await api.post('/subscription/subscriptionsList', payload, {
        signal: controller.signal,
      });
      return { results: apiData.results, total: apiData.total };
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    return { results: [], total: 0 };
  };

  const fetchSubscriptionList = useCallback(
    async (controller) => {
      setIsLoading(true);
      setError(null);

      try {
        const { results, total } = await fetchSubscriptions(controller);
        updateSubscriptions({ results, total });
      } catch (err) {
        handleApiError({ err, showNotification, t });
      } finally {
        setIsLoading(false);
      }
    },
    [api, filters, order, showNotification, t],
  );

  const handleFiltersChange = useCallback(filterChangeHandler(setFilters), []);

  const page = useMemo(() => Math.floor(filters.offset / filters.limit) + 1, [filters.offset, filters.limit]);

  useEffect(() => {
    const controller = new AbortController();

    fetchSubscriptionList(controller);

    return () => controller.abort();
  }, [filters, order, fetchSubscriptionList]);

  if (isLoading) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{t('settings.subscriptions')}</div>
        {Boolean(data.length) && (
          <Link
            href="/#pricing"
            target="_blank"
            refPolicy="no-referrer"
            rel="noreferrer"
            label={t('settings.displayPlans')}
          />
        )}
      </div>
      <FilterTools
        label={t('settings.searchSubscriptions')}
        config={subscriptionFilterConfig}
        onChange={handleFiltersChange}
        subscription={filters.subscription}
        paymentType={filters.paymentType}
        status={filters.status}
        search={filters.search}
      />
      {isNoData ? (
        <EmptyState
          title={t('settings.noData')}
          subTitle={isFiltersUsed ? t('settings.forSelectedFiltersNoData') : t('settings.noSubscriptionData')}
          buttonLabel={!isFiltersUsed && t('settings.displayPlans')}
          buttonOnClick={() => window.open('/#pricing', '_blank')}
          Icon={null}
        />
      ) : (
        <>
          <List
            onAction={(...props) => handleListAction(props)}
            className={classes.list}
            data={data}
            config={subscriptionListConfig(t)}
            onSort={handleOrderClick}
            sortKey={order.column}
            sortOrder={order.direction}
          />
          <Pagination
            className={classes.pagination}
            page={page}
            total={totalRecords}
            limit={filters.limit}
            onLimitChange={handleFiltersChange(LIMIT)}
            onPageChange={handleFiltersChange(OFFSET)}
          />
        </>
      )}
    </div>
  );
};

export default SubscriptionSettings;

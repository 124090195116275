import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { SUSPENDED_PROFILE_ACTION_URL, statusConfig } from './utils';
import Button from '../../../../../shared/components/Button';
import Loader from '../../../components/Loader';
import { PROFILE_STATUS_ENUM } from '../../../helpers/enums';
import { PROFILE_STATE_ENUM } from '../../../components/DashboardLayout/utils';
import { useDashboard } from '../../../helpers/dashboard';
import { useApi } from '../../../../../shared/helpers/api';

const BusinessStatus = ({ status = 'verified', forDashboard = true, adminPreview }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api } = useApi();
  const {
    businessProfile: { id: businessProfileId },
  } = useDashboard();
  const [statusToDisplay, setStatusToDisplay] = useState();

  const { duplicate, suspended, notVerified } = PROFILE_STATUS_ENUM;
  const translatedStatusConfig = statusConfig(t, adminPreview);
  const shouldRenderLinkForm = [duplicate, suspended].includes(status) && !adminPreview;
  const shouldRenderVerifyButton = status === notVerified && !adminPreview;
  const [placeId, setPlaceId] = useState(null);

  const fetchBusinessProfile = async () => {
    const controller = new AbortController();
    if (status === PROFILE_STATE_ENUM.duplicate) {
      const {
        data: { metadata },
      } = await api.post('/business_profile/getBusinessProfile', { businessProfileId }, { signal: controller.current });
      setPlaceId(metadata.placeId);
    }

    return () => controller.abort();
  };

  const linkForm = status === suspended ? SUSPENDED_PROFILE_ACTION_URL : `https://business.google.com/arc/p/${placeId}`;
  const navigateVerify = () => {
    navigate('/dashboard/verification');
  };

  useEffect(() => {
    if (!status) return;
    setStatusToDisplay(translatedStatusConfig[status]);
  }, [status]);

  if (!status) {
    return <Loader />;
  }

  return (
    <div className={classes.businessCardStatusBox}>
      <div className={clsx(classes.title, !forDashboard && classes.flexRow)}>
        {forDashboard && t('dashboard.businessCardStatusBoxTitle')}
        <div className={classes.box}>
          <div className={clsx(classes.status, classes[status])}>{statusToDisplay?.status}</div>
          <div className={clsx(classes.iconWrapper, classes[status])}>
            {statusToDisplay && statusToDisplay.Icon && (
              <statusToDisplay.Icon className={clsx(classes.icon, classes[status])} />
            )}
          </div>
          <div className={classes.subInfo}>{statusToDisplay?.subInfo}</div>
          {shouldRenderLinkForm && (
            <a
              target="_blank"
              referrerPolicy="no-referrer"
              href={linkForm}
              className={classes.suspendedForm}
              rel="noreferrer"
            >
              {t('dashboard.suspendedForm')}
            </a>
          )}
          {shouldRenderVerifyButton && (
            <Button
              onClick={navigateVerify}
              label={t('businessProfileCreate.verify')}
              className={classes.verifyButton}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default BusinessStatus;

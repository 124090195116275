import React from 'react';
import Button from '../../../../shared/components/Button';

const DualActionButtons = ({ positiveButton, negativeButton, containerClassName = '' }) => {
  const {
    label: positiveLabel,
    Icon: positiveIcon,
    onClick: positiveAction,
    id: positiveId = 'positiveButton',
    className: positiveClassName = '',
    disabled: positiveDisabled = false,
  } = positiveButton;

  const {
    label: negativeLabel,
    onClick: negativeAction,
    id: negativeId = 'negativeButton',
    className: negativeClassName = '',
    disabled: negativeDisabled = false,
  } = negativeButton;

  return (
    <div className={containerClassName}>
      <Button
        label={positiveLabel}
        Icon={positiveIcon}
        onClick={positiveAction}
        idName={positiveId}
        className={positiveClassName}
        readOnly={positiveDisabled}
      />
      <Button
        label={negativeLabel}
        onClick={negativeAction}
        idName={negativeId}
        className={negativeClassName}
        readOnly={negativeDisabled}
      />
    </div>
  );
};

export default DualActionButtons;

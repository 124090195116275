export const getCorrectDate = (date) => {
  if (!date) {
    return '';
  }
  if (date.includes('-')) {
    return date;
  }
  if (date.includes('/')) {
    return date.split('/').reverse().join('-');
  }
  const [day, month, year] = date.split('.');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import clsx from 'clsx';
import classes from './styles.module.css';
import { availablePeriods, getStartDate } from './utils';
import Pill from '../Pill';
import Month from '../Month';
import Button from '../Button';
import { DEFAULT_DATE_FORMAT } from '../../../app/SEO/helpers/constants';
import { useCloseOnOutsideClick, useDraggable } from '../../helpers/hooks';
import MonthList from '../MonthList';
import { ONE_YEAR_BEFORE } from '../../helpers/const';

const Calendar = ({
  onClose = () => {},
  filters,
  onChange,
  isSingleMonth = false,
  quickSelect,
  oneDayChoose,
  maxDate = dayjs(),
  selectedDate = null,
  selectedEndPeriodDate = null,
  className,
  admin,
  monthOnly,
  minDate,
  label = 'global.search',
  selectOnDateClick = false,
  messageForDateAfterMaxDate,
}) => {
  dayjs.extend(isToday);
  const { t } = useTranslation();
  const calendarRef = useRef();
  const [currentMonth, setCurrentMonth] = useState(dayjs().startOf('month'));
  const [nextMonth, setNextMonth] = useState(dayjs(minDate).startOf('month').add(1, 'month'));
  const [selectedStartDate, setSelectedStartDate] = useState(selectedDate);
  const [selectedEndDate, setSelectedEndDate] = useState(selectedEndPeriodDate);
  const [selectedQuickPeriod, setSelectedQucikPeriod] = useState(null);
  const futureDaysNotAvailable = maxDate.isBefore(dayjs());
  const { position, onMouseDown } = useDraggable(true);
  useCloseOnOutsideClick(calendarRef, onClose);

  const formatDate = (date, format, isEndOfMonth = false) => {
    if (!date) return '';
    const dayjsDate = dayjs(date);
    return isEndOfMonth ? dayjsDate.endOf('month').format(format) : dayjsDate.format(format);
  };

  const onConfirmHandler = () => {
    if (oneDayChoose) {
      onChange({ ...filters, date: formatDate(selectedStartDate, DEFAULT_DATE_FORMAT) });
      return;
    }

    if (monthOnly) {
      const isSinceCreatedPicked = selectedQuickPeriod === 'calendar.lastMonth';
      const formattedSelectedStartDate = formatDate(selectedStartDate, DEFAULT_DATE_FORMAT);
      const formattedCreatedStartDate = formatDate(minDate, DEFAULT_DATE_FORMAT);
      const formattedStartDate = isSinceCreatedPicked ? formattedCreatedStartDate : formattedSelectedStartDate;

      const isCurrentMonth = dayjs(selectedEndDate).isSame(dayjs(), 'month');
      const formattedEndDate = isCurrentMonth
        ? formatDate(selectedEndDate, DEFAULT_DATE_FORMAT)
        : formatDate(selectedEndDate || selectedStartDate, DEFAULT_DATE_FORMAT, true);

      onChange({ ...filters, dateFrom: formattedStartDate, dateTo: formattedEndDate });
      return;
    }

    const formattedStartDate = formatDate(selectedStartDate, DEFAULT_DATE_FORMAT);
    const formattedEndDate = formatDate(selectedEndDate, DEFAULT_DATE_FORMAT);
    const endDate = selectedEndDate ? formattedEndDate : formattedStartDate;

    onChange({ ...filters, dateFrom: formattedStartDate, dateTo: endDate });
  };

  const handleMonthChange = useCallback(
    (direction) => {
      const futureDaysNavigationNotAvailable = (maxDate.isAfter(dayjs()) || maxDate.isSame(dayjs(), 'month'))
        && direction === 'right'
        && currentMonth.isSame(maxDate, 'month');
      const pastDaysNavigationNotAvailable = minDate && direction === 'left' && currentMonth.isSame(minDate, 'month');
      if (futureDaysNavigationNotAvailable || pastDaysNavigationNotAvailable) {
        return;
      }

      setCurrentMonth((current) => (direction === 'left' ? current.subtract(1, 'month') : current.add(1, 'month')));
      setNextMonth((next) => (direction === 'left' ? next.subtract(1, 'month') : next.add(1, 'month')));
    },
    [maxDate, currentMonth, minDate, selectedEndDate, selectedStartDate],
  );

  const handleDayClick = useCallback(
    (date) => {
      if (oneDayChoose) {
        setSelectedStartDate(date);
        setSelectedEndDate(date);
      } else {
        if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
          setSelectedStartDate(date);
          setSelectedEndDate(null);
        }

        if (date.isAfter(selectedStartDate)) {
          setSelectedEndDate(date);
        } else {
          setSelectedStartDate(date);
        }
      }
    },
    [selectedStartDate, selectedEndDate, oneDayChoose],
  );

  const handlePeriodClick = useCallback(
    (period) => {
      if (selectedQuickPeriod === period.id) {
        setSelectedQucikPeriod(null);
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        return;
      }
      setSelectedQucikPeriod(period.id);
      const startDate = getStartDate(period.value, minDate);
      setSelectedStartDate(startDate);
      setSelectedEndDate(dayjs());

      setCurrentMonth(startDate);
      setNextMonth(startDate.add(1, 'month'));
    },
    [selectedQuickPeriod],
  );

  useEffect(() => {
    setNextMonth(currentMonth.add(1, 'month'));
  }, [currentMonth]);

  useEffect(() => {
    if (selectOnDateClick && (selectedStartDate || selectedEndDate) && selectedDate !== selectedStartDate) {
      onConfirmHandler();
    }
  }, [selectedStartDate, selectedEndDate, selectOnDateClick]);

  return (
    <div
      className={clsx(classes.wrapper, className)}
      style={{ left: `${position.x}px`, top: `${position.y}px`, position: 'fixed' }}
      ref={calendarRef}
      onMouseDown={onMouseDown}
    >
      {quickSelect && (
        <div className={classes.selectPeriodWrapper}>
          {availablePeriods(monthOnly).map((period) => (
            <div key={period.id} onClick={() => handlePeriodClick(period)}>
              <Pill
                className={clsx(
                  classes.periodPill,
                  selectedQuickPeriod === period.id && classes.selectedPeriod,
                  admin && classes.adminPeriodPill,
                )}
                label={t(period.label)}
              />
            </div>
          ))}
        </div>
      )}
      {monthOnly ? (
        <MonthList
          futureDaysNotAvailable={futureDaysNotAvailable}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          handleClick={handleDayClick}
          client={!admin}
          minDate={minDate}
        />
      ) : (
        <div className={clsx(classes.monthsWrapper, isSingleMonth && classes.singleMonthWrapper)}>
          <Month
            month={currentMonth}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            handleDayClick={handleDayClick}
            handleMonthChange={handleMonthChange}
            isSingleMonth={isSingleMonth}
            isFirstMonth
            futureDaysNotAvailable={futureDaysNotAvailable}
            admin={admin}
            minDate={minDate}
            maxDate={maxDate}
            messageForDateAfterMaxDate={messageForDateAfterMaxDate}
          />
          {!isSingleMonth && (
            <Month
              month={nextMonth}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              handleDayClick={handleDayClick}
              handleMonthChange={handleMonthChange}
              futureDaysNotAvailable={futureDaysNotAvailable}
              minDate={minDate}
              maxDate={maxDate}
              admin={admin}
            />
          )}
        </div>
      )}

      <div className={classes.actions}>
        <Button className={clsx(classes.btn, admin && classes.adminBtn)} label={t('global.cancel')} onClick={onClose} />
        {!selectOnDateClick && (
          <Button
            className={clsx(classes.btn, admin && classes.adminBtn)}
            label={t(label)}
            onClick={onConfirmHandler}
            readOnly={!selectedStartDate}
          />
        )}
      </div>
    </div>
  );
};

export default Calendar;

import { removeWhitespaceFromString } from '../../helpers/strings';

export const phoneNumberTransformer = (val) => {
  if (!val) {
    return val;
  }
  const str = String(val);
  const digits = str.replace(/\D/g, '');
  return digits
    .match(/[0-9]{0,3}/g)
    .filter(Boolean)
    .slice(0, 3)
    .join(' ');
};

export const hourTransformer = (val) => {
  if (!val || /[a-zA-Z]/.test(val)) {
    return '';
  }
  const str = String(val);
  const digits = str.replace(/\D/g, '');

  const value = digits
    .match(/[0-9]{0,2}/g)
    .filter(Boolean)
    .slice(0, 2);
  const hours = Number(value[0]);
  const parsedHours = hours > 23 ? 23 : value[0];
  if (!value[1] && str[2] !== ':') {
    return parsedHours;
  }

  const number = Number(value[1]);
  const parsedMinutes = number > 59 ? 59 : value[1];
  if (hours === 24 && number === 0) {
    return '23:59';
  }

  return [parsedHours, parsedMinutes].join(':');
};

export const positiveNumber = (val) => {
  if (!val) {
    return val;
  }
  const str = String(val);
  return Number(str.replace(/\D/g, ''));
};

export const postalCodeTransformer = (val) => {
  if (!val) {
    return val;
  }
  const str = String(val);
  const parsed = str.replace(/[^\d]/g, '').slice(0, 5);
  if (parsed.length > 2 || str[2] === '-') {
    const first = parsed.slice(0, 2);
    const second = parsed.slice(2, 5);
    return `${first}-${second || ''}`;
  }
  return parsed;
};

export const priceTransformer = (value) => {
  if (!value) {
    return '';
  }
  const transformedValue = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/, '$1');
  const dotIndex = transformedValue.indexOf('.');
  const shouldLimitDigitsAfterDot = dotIndex !== -1 && transformedValue.length - dotIndex - 1 > 2 && !transformedValue.endsWith('.');

  if (shouldLimitDigitsAfterDot) {
    return `${transformedValue.slice(0, dotIndex + 3)}`;
  }

  return transformedValue;
};

export const removeWithWhiteSpace = (value) => {
  if (!value) {
    return '';
  }
  return removeWhitespaceFromString(value);
};
export const replaceWhitespace = (value) => {
  if (!value) {
    return '';
  }
  return value.replace(/\s+/g, ' ');
};
export const capitalizeFirstLetter = (value) => {
  if (!value) {
    return '';
  }
  const valueAsString = value.toString();
  const valueWithUpperFirst = valueAsString.charAt(0).toUpperCase() + valueAsString.slice(1);
  return replaceWhitespace(valueWithUpperFirst);
};

export const stringCharactersTransformer = (value) => {
  if (!value) {
    return '';
  }
  return capitalizeFirstLetter(value).replace(/[^a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s]/g, '');
};

export const urlTransformer = (value) => {
  const valuesWithNoPrefix = ['http://', 'https://', undefined, null, ''];
  if (valuesWithNoPrefix.includes(value)) {
    return '';
  }

  const newValue = value.trim().toLowerCase();

  if (!newValue) {
    return '';
  }

  if (!newValue.match(/^(https?:\/\/)/)) {
    return `https://${newValue}`;
  }

  return newValue;
};

export const userMailTransformer = (domain) => (value) => {
  if (!value || value === `@${domain}`) {
    return '';
  }

  const newValue = value.trim();

  if (!newValue) {
    return '';
  }
  const valueWithDomain = newValue
    + String(newValue)
      .charAt(newValue.length - 1)
      .concat(`@${domain}`);
  return valueWithDomain;
};

export const noAtCharTransformer = (val) => val.replace('@', '');

export const companyNipTransformer = (val) => {
  if (!val) {
    return '';
  }
  return val.replace(/\D/g, '').slice(0, 10);
};

export const capitalizeWordsAndPreserveDashesTransformer = (val) => {
  if (!val) {
    return '';
  }
  const parsedValue = val
    .replace(/[^a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s-]/g, '')
    .replace(/\s+/g, ' ')
    .split(/\s+|(?=-)/)
    .map((word) => word
      .split('-')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join('-'),)
    .join(' ');

  return parsedValue;
};
export const nameTransformer = (v) => {
  if (!v) {
    return '';
  }
  return v
    .replace(/[^a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s-]/g, '')
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const addressTransformer = (v) => {
  if (!v) {
    return '';
  }
  return v
    .replace(/[^a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9\s/-]/g, '')
    .replace(/\s{2,}/g, ' ')
    .trim()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
